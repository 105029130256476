import React from "react";

// assets
import styles from "./PrimaryButton.module.scss";
import { ReactComponent as ArrowRightSvg } from "../../../assets/images/icons/arrow-right.svg";
// import { useDispatch } from "react-redux";

export const PrimaryButton = ({title, info, white, orderBtn, onClickFunc, checkoutButton, onClick, billButton}) => {
  // const dispatch = useDispatch();
  // const handleSubmit = () => {
  //   console.log(`object`)
  //   dispatch(onClickFunc())
  // }

  if(checkoutButton) {
return (<button type='button' onClick={()=> onClick()} className={`btn-card ${styles.card} ${white ? styles.white : ''}`}>
{info}
<span className={styles.add}>

  {title}
  <div className={styles.right}>
        <ArrowRightSvg />
      </div>
    </span>
</button>)
  } else if(billButton) {
    return(<button type='button' style={{justifyContent: 'center'}} className={`btn-card ${styles.card} ${white ? styles.white : ''}`}>
    {info}
    <span className={styles.add} >
  {title}
    </span>
    </button>)
  } else {
    return (<button type='submit' className={`btn-card ${styles.card} ${white ? styles.white : ''}`}>
    {info}
    <span className={styles.add}>

      {title}
      <div className={styles.right}>
            <ArrowRightSvg />
          </div>
        </span>
  </button>)
  }
  
}