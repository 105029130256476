/* eslint-disable react/prop-types */
import React from "react";
import ListBoundaryError from "./ListBoundaryError";

export class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// Sending data to reducer
		if (this.props.itemDetails) {
			this.props.sendErrorData({
				errorInfo,
				itemDetails: this.props.itemDetails,
			});
		} else {
			this.props.sendErrorData(errorInfo);
		}
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return this.props.fallback || "";
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
