/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
// core
import React from "react";

// components
// import { GridItems } from "../../../common";

// assets
// import chocolate from '../../../../assets/images/chocolate.png';
// import sunset from '../../../../assets/images/sunset.png';
// import banner from '../../../../assets/images/special-banner.png';

import styles from "../Special/Special.module.scss";
import { useSelector } from "react-redux";
import {
	getBrandDetailsSelector,
	getMenu,
} from "../../../../redux/reducer/onboarding/selectors";
import { useEffect } from "react";
import {
	loader,
	submitTableJoinCode,
} from "../../../../redux/reducer/onboarding/actions";
import { useDispatch } from "react-redux";
import { loadMenuData } from "../../../../redux/reducer/main/action";
import { displayNotification, toTitleCase } from "../../../../config/helper";
import { Footer, Header, NoNavigationLayout } from "../../../common";
import GridRestraunt from "../../../common/DriveIn/GridRestraunt";
import Layout from "../../../common/Layout/Layout";
// import { currentMenuTab } from '../../../../redux/reducer/main/selectors';

const DriveInList = () => {
	const data = useSelector(getMenu);

	const dispatch = useDispatch();
	const brandData = useSelector(getBrandDetailsSelector);
	useEffect(() => {
		const joinCode = localStorage.getItem("joinCode");
		// dispatch(loadTableJoinDetails(qrCode))
		console.log(
			"first",
			JSON.parse(localStorage.getItem("type")) === "dine_in"
		);
		console.log("brandData", brandData === []);
		if (brandData && !brandData.length) {
			console.log("first, first sdd");
			if (JSON.parse(localStorage.getItem("type")) === "dine_in") {
				console.log("first, first");
				dispatch(submitTableJoinCode(joinCode));
			} else if (
				JSON.parse(localStorage.getItem("type")) === "pick_up" ||
				JSON.parse(localStorage.getItem("type")) === "pick_up_delivery"
			) {
				dispatch(loader(true));
				dispatch(loadMenuData());
			} else {
				displayNotification("Something went wrong, Please scan the qr again");
				localStorage.clear();
			}
		}
	}, []);
	const menu = useSelector(getMenu);
	const rest = menu && menu.restraunt_details && menu.restraunt_details;
	const type =
		localStorage.getItem("type") && JSON.parse(localStorage.getItem("type"));
	const handleSetType = (data) => {
		if (type !== data) {
			localStorage.setItem("type", JSON.stringify(data));
			window.location.reload();
		}

		// localStorage.setItem('type', JSON.stringify('delivery'));
	};

	const banner = rest && rest.feature_image;
	return (
		<Layout noNav noMenu>
			<main
				className="special"
				style={{ height: "calc(100vh - 222px)", overflow: "auto" }}
			>
				{/* <div className="dropdown">
					<span>
						{type === "pick_up_delivery"
							? "Delivery"
							: type === "dine_in"
							? rest && rest.restraunt_name
							: type && toTitleCase(type.replace(/_/g, " "))}
					</span>
					{type === "dine_in" ? (
						""
					) : (
						<div className="dropdown-content">
							<p onClick={() => handleSetType("pick_up_delivery")}>Delivery</p>
							<p onClick={() => handleSetType("pick_up")}>Pick up</p>
						</div>
					)}
				</div>{" "} */}
				{/* <Header /> */}
				<section>
					<div className={styles.banner}>
						<div className={styles.image}>
							{banner ? <img src={banner} alt="banner" /> : ""}
						</div>
					</div>
				</section>
				<section>
					<GridRestraunt
						headerLine={rest && rest.restraunt_name && rest.restraunt_name}
						toggle
					/>
				</section>
			</main>
			{/* <Footer noMenu /> */}
		</Layout>
	);
};

export default React.memo(DriveInList);
