import React from "react";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";
import { getLoader } from "../../redux/reducer/onboarding/selectors";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
// import { ReactComponent as svgLoader } from "../../assets/images/loader.svg";
const Loader = () => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  const loader = useSelector(getLoader);
  return (
    <React.Fragment>
      <div
        className="loadingDiv"
        style={{ display: loader === true ? "block" : "none" }}
      >
        <center className="center-top">
          {" "}
          <BeatLoader
            css={override}
            size={20}
            color="#4e4848"
            loading={loader}
          />
          {/* <img src={svgLoader} /> */}
          {/* <div className="loader">
            <ul>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <div className="wineglass left">
              <div className="top"></div>
            </div>
            <div className="wineglass right">
              <div className="top"></div>
            </div>
          </div> */}
        </center>
      </div>
    </React.Fragment>
  );
};

export default Loader;
