import React from "react";

// assets
import styles from "./Instruction.module.scss";
import editWhite from "../../../assets/images/icons/edit-white.svg";
import edit from "../../../assets/images/icons/edit.svg";
import minus from "../../../assets/images/icons/minus-circle.svg";
import { getPlaceOrder2Selector, getPlaceOrderSelector } from "../../../redux/reducer/main/selectors";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getRatingData, placeOrderData } from "../../../redux/reducer/main/action";

export const Instruction = ({title, description, black, setShow, setInstructionAdded, ratingPage, setTempData, reviewData, setSelectedReview}) => {
  const selectData2 = useSelector(getPlaceOrder2Selector);
  const selectData = useSelector(getPlaceOrderSelector);
  const extractedData = [];
  const filteredData = selectData2 && selectData2.length && selectData2.filter(el => el.item_customize && el.item_customize !== '');
  filteredData && filteredData.length && filteredData.map((el, index) => el.item_customize && el.item_customize !== '' ? extractedData.push(`${el.item_name}: ${el.item_customize}${index < filteredData.length - 1 ? ', ' : ''}`) : '');
  const dispatch = useDispatch();
  const deleteInstructions = () => {
    let newSelectData = selectData.map(({ item_customize, ...rest }) => rest);
    let newSelectData2 = selectData2.map(({ item_customize, ...rest }) => rest);
    setInstructionAdded(false);
    dispatch(placeOrderData('deleteInstruction', {newSelectData, newSelectData2}));
  }
  // const itemReview = description && description.length && description[0] && description[0].item_review;
  const handleEdit = () => {
    if(ratingPage) {
      setShow(true);
      setSelectedReview(description)
      setTempData(reviewData);
    }
    else {
      setShow(true);
    }
  }
  const handleDelete = () => {
    if(ratingPage) {
      dispatch(getRatingData({data: {item_order_id: reviewData.item_order_id}, status: 'delete'}));
    } else {
      deleteInstructions()
    }
  }
  return (
    <div className={styles.instruction}>
      {<div>
        {title && <h5>{title}</h5>}
        <p>{ratingPage ? description : extractedData}</p>
      </div>}
      <div className={styles.action}>
        {black ? <img src={edit} alt='edit' onClick={()=> handleEdit()} /> : <img src={editWhite} alt='edit' />}
        <img src={minus} onClick={()=> handleDelete()} alt='minus' />
      </div>
    </div>
  )
}