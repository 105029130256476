import { createStore, applyMiddleware } from "redux";

import createSagaMiddleware from "redux-saga";

import { rootReducer } from "./rootReducer";

import rootSaga from "./sagas/Rootsagas";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerReducer, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import history from "../config/history";
const sagaMiddleware = createSagaMiddleware();
// const history = createBrowserHistory();
const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware, routerMiddleware(history))
  )
);

sagaMiddleware.run(rootSaga);

export default store;
