export const routes = Object.freeze({
	register: "/login",
	verify: "/verify",
	getStarted: "/get-started",
	join: "/join",
	main: "/food",
	special: "/special",
	desserts: "/desserts",
	beverages: "/beverages",
	drinks: "/drinks",
	store: "/store",
	search: "/search",
	placeOrder: "/place-order",
	checkout: "/payment",
	coupons: "/coupons",
	orderRating: "/review",
	feedback: "/feedback",
	event: "/event",
	eventCalendar: "/event-calendar",
	tickets: "/tickets",
	bookingConfirmation: "/booking-confirmation",
	orderDetails: "/track",
	thankYouScreen: "/order-success",
	whatsappIntegration: "/checkout",
	bill: "/bill",
	overallReview: "/overall-review",
	driveIn: "/drive-in",
	orderPlaced: "/order-placed",
});
