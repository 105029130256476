// core
import React, { useState } from "react";

// library
import { NavLink } from "react-router-dom";

// components
import { routes } from "../../App/routes";

// assets
import styles from "./Footer.module.scss";
import { ReactComponent as LightningSvg } from "../../../assets/images/icons/lightning.svg";
import { ReactComponent as BookSvg } from "../../../assets/images/icons/book-open.svg";
import { ReactComponent as ShoppingSvg } from "../../../assets/images/icons/shopping-bag.svg";
import { ReactComponent as CloseSvg } from "../../../assets/images/icons/close.svg";
import {
	currentMenuTab,
	getBackButton,
	getContainsEgg,
	getOrderDetailsData,
	getPlaceOrderSelector,
	getSelectVegOnly,
} from "../../../redux/reducer/main/selectors";
import { useSelector } from "react-redux";
import { getMenu } from "../../../redux/reducer/onboarding/selectors";
import {
	backButton,
	containsEgg,
	placeOrder,
	vegOnly,
} from "../../../redux/reducer/main/action";
import { useDispatch } from "react-redux";
import history from "../../../config/history";

export const Footer = ({ noMenu }) => {
	// const [showMenu, setShowMenu] = useState(false);
	const placeOrders = useSelector(getPlaceOrderSelector);

	const menu = useSelector(getMenu);
	const currentTab = useSelector(currentMenuTab);
	const menuData = menu && menu.menu;
	const foodMenu =
		menuData && menuData[currentTab] && Object.values(menuData[currentTab]);
	const foodMenuKey =
		menuData && menuData[currentTab] && Object.keys(menuData[currentTab]);
	const totalItems = foodMenu && foodMenu.length && foodMenu.flat();
	const containsEggState = useSelector(getContainsEgg);
	const onClickScroll = (id) => {
		// setShowMenu(false);
		dispatch(backButton(false));
		document.body.style.overflow = "";
		const element = document.getElementById(id);
		element && element.scrollIntoView();
		// if(document.getElementById(id)) {
		//   const element = document.getElementById(id);
		//   element && element.scrollIntoView();
		//   // const headOffset = 116;
		//   // const elementPosition = element && element.offsetTop;
		//   // window.scrollTo({
		//   //   top:  elementPosition - headOffset,
		//   //   behavior: "smooth"
		//   // });
		// }
	};
	const dispatch = useDispatch();
	const vegOnlyState = useSelector(getSelectVegOnly);
	const backButtonValue = useSelector(getBackButton);
	const handleOpenMenu = () => {
		// setShowMenu(true)
		dispatch(backButton(true));
		document.body.style.overflow = "hidden";
	};
	const handleCloseMenu = () => {
		document.body.style.overflow = "";
		// setShowMenu(false)
		dispatch(backButton(false));
		// handleOpenMenu(false);
	};
	// const [currentPath, setCurrentPath] = useState(window.location.pathname);
	let classActive;
	// useEffect(() => {

	// }, [window.location.pathname]);
	const currentPath = window.location.pathname;
	if (
		currentPath === routes.main ||
		routes.special ||
		routes.desserts ||
		routes.drinks ||
		routes.store ||
		routes.beverages
	) {
		classActive = "active";
	} else {
		classActive = "";
	}
	if (currentPath === routes.event) {
		classActive = "";
	}

	const orderData = useSelector(getOrderDetailsData);
	const orderDetails =
		orderData &&
		orderData.order_details &&
		Object.values(orderData.order_details);
	// let goRoute;
	// if(orderDetails && orderDetails.length > 1 && placeOrders.length === 0) {
	//   goRoute = routes.checkout;
	// }
	const qrFlow = JSON.parse(localStorage.getItem("qrFlow"));
	const handleOnClickCart = () => {
		if (orderDetails && orderDetails.length > 1 && placeOrders.length === 0) {
			history.push(routes.checkout);
		} else if (placeOrders && placeOrders.length > 0 && qrFlow === "prepaid") {
			// console.log("object :>> ");
			// dispatch(placeOrder());
			history.push(routes.placeOrder);
			// history.push(routes.checkout);
		} else {
			// console.log("object :>>asdf");
			history.push(routes.placeOrder);
		}
	};
	const handleOnClickLinkCart = () => {
		if (orderDetails && orderDetails.length > 1 && placeOrders.length === 0) {
			return routes.checkout;
		} else if (placeOrders && placeOrders.length > 0 && qrFlow === "prepaid") {
			// dispatch(placeOrder());
			return "#";
		} else {
			// console.log("object :>>asdf");
			return routes.placeOrder;
		}
	};
	return (
		<>
			<div
				className={`${styles.menu} ${backButtonValue ? styles.open : ""}`}
				style={{ zIndex: 999 }}
			>
				<div className={styles.inner}>
					<h3>
						Menu
						<span className={styles.close} onClick={() => handleCloseMenu()}>
							<CloseSvg />
						</span>
					</h3>
					<hr />
					<ul className={styles.availability}>
						<li>
							Veg Only
							<label className={styles.radio}>
								<input
									type="checkbox"
									hidden
									defaultChecked={vegOnlyState}
									onChange={(e) => dispatch(vegOnly(e.target.checked))}
								/>
								<span />
							</label>
						</li>
						<li>
							Contains Egg
							<label className={styles.radio}>
								<input
									type="checkbox"
									id="checkContainsEgg"
									defaultChecked={containsEggState}
									hidden
									disabled={!vegOnlyState}
									onChange={(e) => dispatch(containsEgg(e.target.checked))}
								/>
								<span />
							</label>
						</li>
					</ul>
					<hr />
					{currentPath !== "/special" ? (
						<ul
							className={styles.availability}
							style={{ height: 200, overflowY: "scroll" }}
						>
							<li>
								All <span>{totalItems && totalItems.length}</span>
							</li>
							{foodMenuKey &&
								foodMenuKey.length &&
								foodMenuKey.map((food, index) => (
									<li
										key={`foodMenuKey${index}`}
										onClick={() => onClickScroll(food)}
									>
										{food}
										<span>{foodMenu[index].length}</span>
									</li>
								))}
						</ul>
					) : (
						""
					)}
				</div>
			</div>
			<footer className={styles.footer}>
				{!noMenu && (
					<div
						className={`${styles.menuBtn} btn`}
						onClick={() => handleOpenMenu()}
					>
						Menu
					</div>
				)}
				<ul className={styles.nav}>
					<li>
						<NavLink to={routes.event}>
							<LightningSvg />
						</NavLink>
						{/* <a href="javascript:void(0);" >
              <LightningSvg />
            </a> */}
					</li>
					<li>
						<NavLink to={routes.special} className={classActive}>
							<BookSvg />
						</NavLink>
					</li>
					<li className={styles.notEmpty}>
						{placeOrders && placeOrders.length > 0 ? (
							<span className={styles.count}>
								{(placeOrders && placeOrders.length) || 0}
							</span>
						) : (
							""
						)}
						{qrFlow === "prepaid" ? (
							<span
								to={handleOnClickLinkCart()}
								// to={handleOnClickLinkCart()}
								onClick={() => handleOnClickCart()}
							>
								<ShoppingSvg />
							</span>
						) : (
							<NavLink
								to={handleOnClickLinkCart()}
								// to={handleOnClickLinkCart()}
								onClick={() => handleOnClickCart()}
							>
								<ShoppingSvg />
							</NavLink>
						)}
					</li>
				</ul>
			</footer>
		</>
	);
};
