import React from "react";

// library
import { useDispatch, useSelector } from "react-redux";

// components

// assets
import styles from "./RepeatItemModal.module.scss";
import { ReactComponent as CloseSvg } from "../../../../assets/images/icons/close.svg";
import { modalsActions } from "../../../../redux/modals/actions";
import { getAddModalDataSelector } from "../../../../redux/reducer/onboarding/selectors";
import { changeQty } from "../../../../redux/reducer/main/action";

export const RepeatItemModal = () => {
  const dispatch = useDispatch();
  const currentItemData = useSelector(getAddModalDataSelector);
  const handleRepeat = () => {
    console.log('currentItemData :>> ', currentItemData);
    const id = currentItemData && currentItemData.item_id;
    dispatch(changeQty({id, symbol: '+', changeLastItem: 'changeLastItem'}));
    dispatch(modalsActions.setShowModal(""));
  }

  return (
    <div className={styles.modal}>
      <div className={styles.inner}>
      
        <h3 className={styles.header}>
          Repeat last used customization?
          <span className={styles.close} onClick={() => dispatch(modalsActions.setShowModal(''))}>
            <CloseSvg />
          </span>
        </h3> 
        <div className={styles.bottom}>
          <div className={styles.panel} style={{justifyContent: "space-around", display: 'flex'}}>
          <button type='button' className='btn btn-second' style={{width: '45%'}} onClick={()=> dispatch(modalsActions.setShowModal("Add Product"))}
                    >Add new
            </button>
            <button type='button' className='btn btn-second' style={{width: '45%'}} onClick={()=> handleRepeat()}
                    >Repeat
            </button> 
          </div>
        </div>
      </div>
    </div>
  )
}