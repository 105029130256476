/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React from "react";

// library
import { useDispatch, useSelector } from "react-redux";

// components
import { PrimaryButton } from "../../../common/PrimaryButton/PrimaryButton";
import { VeganStatus } from "../../../common/VeganStatus/VeganStatus";
import { TopPanel } from "../../../common/TopPanel/TopPanel";
import { Link, withRouter } from "react-router-dom";
import { routes } from "../../../App/routes";
// import { modalsActions } from "../../../../redux/modals/actions";

// assets
import styles from "./Bill.module.scss";
import coupons from "../../../../assets/images/icons/coupons.svg";
// import minus from "../../../../assets/images/icons/minus-circle.svg";
// import donate from "../../../../assets/images/icons/donate-heart.svg";
import { NoNavigationLayout } from "../../../common";
import { useEffect } from "react";
import { loadOrderDetails } from "../../../../redux/reducer/main/action";
import { getOrderDetailsData } from "../../../../redux/reducer/main/selectors";
// import { customerId } from "../../../../config/config";
import { currencySymbol } from "../../../../config/helper";
import {
	// loader,
	// loadGetTableCode,
	storeErrorInfo,
	// submitTableJoinCode,
} from "../../../../redux/reducer/onboarding/actions";
import ErrorBoundary from "../../../common/ErrorBoundarie/ErrorBoundary";
import Tip from "../../../common/Tip/Tip";

const Checkout = () => {
	const dispatch = useDispatch();
	const getErrorDataFromBoundary = (data) => {
		dispatch(storeErrorInfo(data));
	};
	const userData = useSelector(getOrderDetailsData);
	useEffect(() => {
		const token = localStorage.getItem("token");
		const qrCode = localStorage.getItem("qrCode");
		localStorage.removeItem("qrFlow");
		if (token && qrCode) {
			dispatch(loadOrderDetails());
		} else {
			localStorage.clear();
			window.location = routes.register;
		}
	}, [dispatch]);

	const customerId = JSON.parse(localStorage.getItem("customerId"));

	const orderDetails = userData && userData.order_details;

	if (orderDetails) {
		var { order_amount, [customerId]: remove, ...restData } = orderDetails;
	}
	const runningData =
		restData && Object.values(restData).flatMap((data) => data);

	const restrauntDiscount =
		(userData &&
			userData.discount_details &&
			userData.discount_details.restraunt_discount_amount) ||
		0;
	const tipAmount =
		(userData && userData.tip_details && userData.tip_details.tip_amount) || 0;
	const couponDiscount =
		(userData &&
			userData.discount_details &&
			userData.discount_details.coupon_discount) ||
		0;
	const serviceChargeAmt =
		(userData &&
			userData.service_charge_amount &&
			userData.service_charge_amount) ||
		0;

	// razor pay

	// const customerName = customerDetails && customerDetails.customer_name;

	// console.log('otherOrdersKey :>> ', otherOrdersKey);
	// const country = localStorage.getItem("country");

	const approvalOrders =
		userData &&
		userData.approval_orders &&
		userData.approval_orders.map((e) => e.order_details);
	const pendingOrders = approvalOrders && approvalOrders.flat();
	const rejectedOrders =
		userData &&
		userData.rejected_orders &&
		userData.rejected_orders.map((e) => e.order_details);
	const rejectedItems = rejectedOrders && rejectedOrders.flat();

	// const dineInApproval = JSON.parse(localStorage.getItem("dineInApproval"));
	return (
		<NoNavigationLayout>
			<main
				className={`${styles.placeOrder} make-scroll`}
				style={{ paddingTop: 46 }}
			>
				<TopPanel
					title="Order details"
					noBackButton
					noBorder
					route={routes.special}
				/>
				<form className={styles.products}>
					{pendingOrders && pendingOrders.length ? (
						<div className={styles.order}>
							<h5 className={styles.title}>Pending Approval</h5>
							{pendingOrders.map((item, index) => (
								<div key={index} className={styles.item}>
									<h5 className="item-name-width">
										<VeganStatus
											type={item.item_type}
											egg={item.contains_egg}
										/>
										{item.item_name}
									</h5>
									<h5 className={styles.count}>x {item.qty}</h5>
									{/* <h5 style={{ width: "51px" }}>
                    {currencySymbol()}
                    {item.item_total_price}
                  </h5> */}
								</div>
							))}
						</div>
					) : (
						""
					)}
					{rejectedItems && rejectedItems.length ? (
						<div className={styles.order}>
							<h5 className={styles.title}>Rejected Order</h5>
							{rejectedItems.map((item, index) => (
								<div key={index} className={styles.item}>
									<h5 className="item-name-width">
										<VeganStatus
											type={item.item_type}
											egg={item.contains_egg}
										/>
										{item.item_name}
									</h5>
									<h5 className={styles.count}>x {item.qty}</h5>
									{/* <h5 style={{ width: "51px" }}>
                    {currencySymbol()}
                    {item.item_total_price}
                  </h5> */}
								</div>
							))}
						</div>
					) : (
						""
					)}
					{orderDetails &&
					orderDetails[customerId] &&
					orderDetails[customerId].length ? (
						<div className={styles.order}>
							<h5 className={styles.title}>Your Order</h5>
							{orderDetails[customerId].map((item, index) => (
								<div key={index} className={styles.item}>
									<h5 className="item-name-width">
										<VeganStatus
											type={item.item_type}
											egg={item.contains_egg}
										/>
										{item.item_name}
									</h5>
									<h5 className={styles.count}>x {item.qty}</h5>
									<h5 style={{ width: "51px" }}>
										{currencySymbol()}
										{item.item_total_price}
									</h5>
								</div>
							))}
						</div>
					) : (
						""
					)}
					{runningData && runningData.length ? (
						<div className={styles.order}>
							<h5 className={styles.title}>Friend’s Order</h5>
							{runningData.map((item, index) => (
								<div key={index} className={styles.item}>
									<h5 className="item-name-width">
										<VeganStatus
											type={item.item_type}
											egg={item.contains_egg}
										/>
										{item.item_name}
									</h5>
									<h5 className={styles.count}>x {item.qty}</h5>
									<h5 style={{ width: "51px" }}>
										{currencySymbol()}
										{item.item_total_price}
									</h5>
								</div>
							))}
						</div>
					) : (
						""
					)}
					<Link to="#" className={styles.coupons}>
						<img src={coupons} alt="coupons" />
						{userData.discount_details &&
						userData.discount_details.coupon_discount ? (
							<div className={styles.description}>
								<h5>Coupon applied</h5>
								<h6>
									{userData.discount_details &&
										userData.discount_details.coupon_title}
								</h6>
							</div>
						) : (
							<div className={styles.description}>
								<h5>Coupons</h5>
								<h6>No coupons for now</h6>
							</div>
						)}
					</Link>

					<div className={styles.waiter} style={{ pointerEvents: "none" }}>
						<Tip tipAmount={tipAmount} disabled styles={styles} />
					</div>
					{/* this is bill area */}

					<div className={styles.total}>
						<h5>
							Item Total
							<span>
								{currencySymbol()}
								{orderDetails && orderDetails.order_amount}
							</span>
						</h5>
						{couponDiscount > 0 ? (
							<h6>
								Coupon Discount
								<span className={styles.green}>
									{currencySymbol()}
									{couponDiscount}
								</span>
							</h6>
						) : (
							""
						)}
						{restrauntDiscount > 0 ? (
							<h6>
								Restaurant Discount
								<span className={styles.green}>
									{currencySymbol()}
									{restrauntDiscount}
								</span>
							</h6>
						) : (
							""
						)}
						{serviceChargeAmt > 0 ? (
							<h6>
								Service charge
								<span>
									{currencySymbol()}
									{serviceChargeAmt}
								</span>
							</h6>
						) : (
							""
						)}
						{userData &&
							userData.tax_details &&
							userData.tax_details.map((tax, index) =>
								tax.tax_amount > 0 ? (
									<ErrorBoundary
										sendErrorData={getErrorDataFromBoundary}
										itemDetails={tax}
										fallBack={null}
									>
										<h6 key={`tax${index}`}>
											{tax.tax_name}
											<span>
												{currencySymbol()}
												{tax.tax_amount}
											</span>
										</h6>
									</ErrorBoundary>
								) : (
									""
								)
							)}
						{tipAmount > 0 ? (
							<h6>
								Tip
								<span className={styles.green}>
									{currencySymbol()}
									{tipAmount}.00
								</span>
							</h6>
						) : (
							""
						)}
						{userData && userData.delivery_fee > 0 ? (
							<h6>
								Delivery charges
								<span className={styles.green}>
									{currencySymbol()}
									{userData && userData.delivery_fee}
								</span>
							</h6>
						) : (
							""
						)}
						{userData && userData.packing_charges > 0 ? (
							<h6>
								Packing charges
								<span className={styles.green}>
									{currencySymbol()}
									{userData && userData.packing_charges}
								</span>
							</h6>
						) : (
							""
						)}
						<h4>
							Grand Total
							<span>
								{currencySymbol()}
								{userData &&
									userData.payment_details &&
									userData.payment_details.payment_amount}
							</span>
						</h4>
					</div>
					<PrimaryButton
						disabled
						title="Thank you. Your payment is done."
						// info={''}
						billButton
						white
					/>
				</form>
			</main>
		</NoNavigationLayout>
	);
};
export default withRouter(React.memo(Checkout));
