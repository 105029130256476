import React, { useState } from "react";

// assets
import styles from "./CookingInstruction.module.scss";
import { ReactComponent as CloseSvg } from "../../../assets/images/icons/close.svg";
// import coffee from "../../../assets/images/coffee.png";
// import fresh from "../../../assets/images/fresh.png";
// import soup from "../../../assets/images/soup.png";
// import plate from "../../../assets/images/icons/plate.svg";
import { useSelector } from "react-redux";
import { getAllergyDetails } from "../../../redux/reducer/main/selectors";
import { useDispatch } from "react-redux";
import { allergyDetails} from "../../../redux/reducer/main/action";

export const AllergyInstructions = ({showAllergyInstructions, setShowAllergyInstructions, setInstructionAdded}) => {
 
  const dispatch = useDispatch()
  const onSubmit = (e) => {
    e.preventDefault();
    setShowAllergyInstructions(false);
  };
  const selectData = useSelector(getAllergyDetails);
  const handleOnChange = (e) =>{
    dispatch(allergyDetails(e));
  }
  return (
    <div className={`${styles.cooking} ${showAllergyInstructions ? styles.open : ''}`}>
      <div className={styles.inner}>
        <h3>
          {/* Add cooking instructions */}
          Please specify here
          <span className={styles.close} onClick={() => setShowAllergyInstructions(false)}>
            <CloseSvg />
          </span>
        </h3>
        <hr />
        <form onSubmit={(e) => onSubmit(e)}>
          <label>
            <textarea placeholder='Please do not add peanut...' id="cookingTextarea" name='allergies'
            defaultValue={selectData && selectData || ''} onChange={(e)=> handleOnChange(e.target.value)} />
          </label>
          <button type='submit' className='btn btn-primary'>ADD</button>
        </form>
      </div>
    </div>
  )
}