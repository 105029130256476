import React from "react";

// components
import { TopPanel } from "../../../common/TopPanel/TopPanel";
import { NoNavigationLayout } from "../../../common";

// assets
import styles from './Coupons.module.scss';
import { ReactComponent as CheckSvg } from "../../../../assets/images/icons/check.svg";
import { routes } from "../../../App/routes";
import { useDispatch, useSelector } from "react-redux";
import { getSelectCoupons } from "../../../../redux/reducer/main/selectors";
import { applyCoupon } from "../../../../redux/reducer/main/action";

// const coupons = [
//   {
//     title: 'Get flat 20% off',
//     description: 'Prism Hyderabad, Old Mumbai Hwy, Financial District, Gowlidoddy, 500075 Terms & conditions apply.',
//     code: 'super20',
//     applied: true
//   },
//   {
//     title: 'Get flat 20% off',
//     description: 'Use paytm and get a flat upto 399 cashback on your order. Terms & conditions apply.',
//     code: 'super20',
//     applied: false
//   },
//   {
//     title: 'Get flat 20% off',
//     description: 'Use paytm and get a flat upto 399 cashback on your order. Terms & conditions apply.',
//     code: 'super20',
//     applied: false
//   },
// ]

export const Coupons = () => {
  const coupons = useSelector(getSelectCoupons);
  const dispatch = useDispatch()

  return (
    <NoNavigationLayout>
      <main className={styles.coupons}>
        <TopPanel title='Coupons' noBorder noWarning route={routes.checkout} />
        {coupons.map(coupon => (
          <div className={styles.coupon}>
            <h3>{coupon.coupon_title}</h3>
            <p>{coupon.coupon_description}</p>
            <hr />
            <div className={styles.info}>
              {coupon.coupon_code}
              <button type='button' onClick={()=> dispatch(applyCoupon(coupon.coupon_id))} className={`btn btn-primary ${coupon.applied ? '' : styles.applied}`}>
                {coupon.applied ? <>APPLIED <CheckSvg /></> : 'APPLY'}
              </button>
            </div>
          </div>
        ))}
      </main>
    </NoNavigationLayout>
  )
}