import React from "react";

// assets
import styles from "./BookingInfo.module.scss";
import { ReactComponent as CalendarSVG } from "../../../assets/images/icons/calendar-event.svg";
import { ReactComponent as ClockSvg } from "../../../assets/images/icons/clock.svg";
import { ReactComponent as PinSvg } from "../../../assets/images/icons/pin.svg";
import { ReactComponent as UserSvg } from "../../../assets/images/icons/user.svg";
import { ReactComponent as GreyStar } from "../../../assets/images/icons/star-gray.svg";

export const BookingInfo = ({
	mod,
	name,
	date,
	time,
	restName,
	address,
	type,
}) => {
	return (
		<div className={`${styles.info} ${mod ? styles.mod : ""}`}>
			<div className={styles.item}>
				<GreyStar style={{ height: 20, width: 20 }} />
				<h5>{restName}</h5>
			</div>
			{mod && (
				<div className={`${styles.item} ${styles.people}`}>
					<UserSvg />
					<h5>{name}</h5>
				</div>
			)}
			<div className={styles.item}>
				<CalendarSVG />
				<h5>{date}</h5>
			</div>
			<div className={styles.item}>
				<ClockSvg />
				<h5>{time}</h5>
				{/* <h5>8pm onwards</h5> */}
			</div>
			<div className={styles.item}>
				<PinSvg />
				<h5>
					{/* {type === "pick_up" ? restName + "," : ""}  */}
					<span>{address}</span>
				</h5>
			</div>
		</div>
	);
};
