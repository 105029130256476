import React, { useState } from "react";

// assets
import styles from "./CookingInstruction.module.scss";
import { ReactComponent as CloseSvg } from "../../../assets/images/icons/close.svg";
// import coffee from "../../../assets/images/coffee.png";
// import fresh from "../../../assets/images/fresh.png";
// import soup from "../../../assets/images/soup.png";
import plate from "../../../assets/images/icons/plate.svg";
import { useSelector } from "react-redux";
import { getPlaceOrder2Selector } from "../../../redux/reducer/main/selectors";
import { useDispatch } from "react-redux";
import { placeOrderData } from "../../../redux/reducer/main/action";
import { useEffect } from "react";
import { displayNotification } from "../../../config/helper";

export const CookingInstruction = ({show, setShow, setInstructionAdded}) => {
 
  const [activeAvatar, setActiveAvatar] = useState();
  const dispatch = useDispatch()
  const onSubmit = (e) => {
    e.preventDefault();
    if(selectedData)
    {
    setShow(false);
    setInstructionAdded(true)
    }else {
      displayNotification('Please select item first');
    }

  };
  const selectData = useSelector(getPlaceOrder2Selector);
  const [selectedData, setSelectedData] = useState();
  const handleOnChange = (e) =>{
    if(selectedData) {

      dispatch(placeOrderData('cookingInstructions', {...selectedData, item_customize: e, index: activeAvatar}));
    } 
    // else {
    //   displayNotification('Please select item first');
    // }
  }
  useEffect(() => {
    if(document.getElementById('cookingTextarea') && selectedData) {
      document.getElementById('cookingTextarea').value = selectedData.item_customize || '';
    }
  }, [selectedData]);
  
  return (
    <div className={`${styles.cooking} ${show ? styles.open : ''}`}>
      <div className={styles.inner}>
        <h3>
          {/* Add cooking instructions */}
          Select your item
          <span className={styles.close} onClick={() => setShow(false)}>
            <CloseSvg />
          </span>
        </h3>
        <hr />
        <div className={styles.avatars}>
          {selectData && selectData.length && selectData.map((avatar, index) => (
            <div key={index}
                 className={`${styles.avatar} ${activeAvatar === index ? styles.active : ''}`}
                 onClick={() => {setSelectedData(avatar); setActiveAvatar(index)}}>
              {avatar.item_image_url ? <img src={avatar.item_image_url || plate} alt={avatar.item_id} />
                : <span className={avatar.status ? styles.status : ''}>{avatar.item_name && avatar.item_name.slice(0,2)}</span>}
            </div>
          ))}
        </div>
        <form onSubmit={(e) => onSubmit(e)}>
          <label>
            {selectedData && selectedData.item_name}
            <textarea placeholder='I would like my dish less spicy...' id="cookingTextarea" name={activeAvatar} 
            defaultValue={selectedData && selectedData.item_customize && selectedData.item_customize || ''} onChange={(e)=> handleOnChange(e.target.value)} />
          </label>
          <button type='submit' className='btn btn-primary'>ADD</button>
        </form>
      </div>
    </div>
  )
}