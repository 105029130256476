import React, { useState } from "react";

// assets
import styles from "./Emotions.module.scss";
import loved from "../../../assets/images/icons/loved-it.svg";
import satisfied from "../../../assets/images/icons/satisfied.svg";
import confused from "../../../assets/images/icons/confused.svg";
import hate from "../../../assets/images/icons/hate-it.svg";
import disgust from "../../../assets/images/icons/disgust.svg";

const emotions = [
  {title: 'loved', icon: loved, rating: 5},
  {title: 'satisfied', icon: satisfied, rating: 4},
  {title: 'confused', icon: confused, rating: 3},
  {title: 'hate', icon: hate, rating: 2},
  {title: 'disgust', icon: disgust, rating: 1},
];
export const Emotions = ({handleSubmitEmote, product, feedback, reviewData}) => {
  const [activeEmotion, setActiveEmotion] = useState(loved);
  const [showEmotion, setShowEmotion] = useState(false);
  const changeEmotion = (emotion) => {
    setActiveEmotion(emotion.icon);
    handleSubmitEmote({item_rating: emotion.rating, item_id: product.item_id, item_order_id: product.item_order_id, item_review: reviewData && reviewData.length && reviewData[0] && reviewData[0].item_review || '', item_name: product.item_name, item_type: product.item_type, contains_egg: product.contains_egg})
  };

  return (
    <div className={styles.emotions} onClick={() => setShowEmotion(!showEmotion)}>
      <img src={activeEmotion} alt='emotion' />
      {showEmotion && <ul>
        {emotions.map((emotion) => (
          <li>
            <img src={emotion.icon} alt={emotion.title} onClick={() => changeEmotion(emotion)} />
          </li>
        ))}
      </ul>}
    </div>
  )
}