import React from "react";

// library
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// components
import { AddButton } from "../AddButton/AddButton";
import { VeganStatus } from "../VeganStatus/VeganStatus";
import { modalsActions } from "../../../redux/modals/actions";

// assets
import styles from "./GridItems.module.scss";
import { ReactComponent as ArrowSvg } from "../../../assets/images/icons/arrow.svg";
import plate from "../../../assets/images/icons/plate.svg";
import platePng from "../../../assets/images/plate.png";
import {
	getAddModalData,
	storeErrorInfo,
} from "../../../redux/reducer/onboarding/actions";
import { useSelector } from "react-redux";
import {
	getContainsEgg,
	getPlaceOrderSelector,
	getSelectVegOnly,
} from "../../../redux/reducer/main/selectors";
import { placeOrderData } from "../../../redux/reducer/main/action";
import { currencySymbol } from "../../../config/helper";
import ErrorBoundary from "../ErrorBoundarie/ErrorBoundary";

const GridItems = ({ items, toggle, categoryName, headerLine }) => {
	const dispatch = useDispatch();
	const getErrorDataFromBoundary = (data) => {
		dispatch(storeErrorInfo({ data }));
	};

	const showItems = (e) => {
		e.target.parentElement.classList.toggle(styles.hide);
	};

	const addProduct = (data) => {
		dispatch(getAddModalData(data));
		const foundData =
			getPlaceOrderData &&
			getPlaceOrderData.length &&
			getPlaceOrderData.filter((o) => o.item_id === data.item_id);
		if (
			(foundData &&
				foundData.addon_details &&
				foundData.addon_details.length) ||
			(foundData.item_price_details && foundData.item_price_details.length > 1)
		) {
			dispatch(modalsActions.setShowModal("Repeat Item"));
		} else {
			dispatch(modalsActions.setShowModal("Add Product"));
		}
	};
	const getPlaceOrderData = useSelector(getPlaceOrderSelector);
	const handleAddItem = (data) => {
		// add directly
		if (
			(data && data.item_price_details && data.item_price_details.length > 1) ||
			data.addon_details.length
		) {
			addProduct(data);
		} else {
			const itemPrice =
				(data &&
					data.item_price_details &&
					data.item_price_details.length &&
					data.item_price_details[0] &&
					data.item_price_details[0].item_price) ||
				0;
			const itemSize =
				data &&
				data.item_price_details &&
				data.item_price_details.length &&
				data.item_price_details[0] &&
				data.item_price_details[0].item_size;
			dispatch(
				placeOrderData(
					{ addons: [], item_id: data.item_id, item_size: itemSize, qty: 1 },
					{
						addons: [],
						item_name: data.item_name,
						item_id: data.item_id,
						item_size: itemSize,
						qty: 1,
						item_image_url: data ? data.item_image_url : plate,
						item_price: itemPrice,
						item_type: data.item_type,
					}
				)
			);
		}
	};
	const vegOnlyState = useSelector(getSelectVegOnly);
	const containsEggState = useSelector(getContainsEgg);

	return (
		<div className={`${styles.items} `}>
			{toggle ? (
				<h4
					className={`${styles.title} ${styles.toggle}`}
					onClick={(e) => showItems(e)}
				>
					{headerLine || "Today’s Special"}
					<hr />
					<ArrowSvg />
				</h4>
			) : (
				<h4 className={styles.title}>{headerLine || "Today’s Special"}</h4>
			)}
			<div className={styles.panel}>
				{items && items.length
					? items.map(
							(item, k) =>
								item &&
								item.length &&
								item.map((product, index) =>
									vegOnlyState === true ? (
										containsEggState === true ? (
											product.item_type !== "non-veg" && (
												<ErrorBoundary
													sendErrorData={getErrorDataFromBoundary}
													itemDetails={product}
													fallBack={null}
												>
													<Link
														id={product.title}
														to="#"
														className={styles.item}
														key={`${index}grid`}
													>
														<div
															className={styles.image}
															onClick={() => addProduct(product)}
														>
															{product.item_image_url &&
															product.item_image_url !== "" ? (
																<img
																	src={product.item_image_url}
																	alt={product.item_name}
																/>
															) : (
																<img src={platePng} alt={product.item_name} />
															)}
														</div>
														<div className={styles.description}>
															<h4 className={styles.itemTitle}>
																<VeganStatus
																	type={product.item_type}
																	egg={product.contains_egg}
																/>
																{product.item_name}
															</h4>
															<div className={styles.info}>
																<div className={styles.price}>
																	<h4>
																		{currencySymbol()}
																		{product.item_price_details &&
																			product.item_price_details.length &&
																			product.item_price_details[0].item_price}
																	</h4>
																</div>
																{/* <AddButton onClick={() => addProduct(product)} /> */}
																{getPlaceOrderData &&
																getPlaceOrderData.length &&
																getPlaceOrderData.find(
																	(o) => o.item_id === product.item_id
																) ? (
																	<AddButton
																		count={
																			[...getPlaceOrderData]
																				.reverse()
																				.find(
																					(o) => o.item_id === product.item_id
																				).qty
																		}
																		itemData={product}
																		lastItem
																		id={product.item_id}
																	/>
																) : (
																	<AddButton
																		id={product.item_id}
																		onClick={() => handleAddItem(product)}
																	/>
																)}
															</div>
														</div>
													</Link>
												</ErrorBoundary>
											)
										) : (
											product.item_type === "veg" &&
											product.contains_egg === false && (
												<ErrorBoundary
													sendErrorData={getErrorDataFromBoundary}
													itemDetails={product}
													fallBack={null}
												>
													<Link
														id={product.title}
														to="#"
														className={styles.item}
														key={`${index}grid`}
													>
														<div
															className={styles.image}
															onClick={() => addProduct(product)}
														>
															{product.item_image_url &&
															product.item_image_url !== "" ? (
																<img
																	src={product.item_image_url}
																	alt={product.item_name}
																/>
															) : (
																<img src={platePng} alt={product.item_name} />
															)}
														</div>
														<div className={styles.description}>
															<h4 className={styles.itemTitle}>
																<VeganStatus
																	type={product.item_type}
																	egg={product.contains_egg}
																/>
																{product.item_name}
															</h4>
															<div className={styles.info}>
																<div className={styles.price}>
																	<h4>
																		{currencySymbol()}
																		{product.item_price_details &&
																			product.item_price_details.length &&
																			product.item_price_details[0].item_price}
																	</h4>
																</div>
																{/* <AddButton onClick={() => addProduct(product)} /> */}
																{getPlaceOrderData &&
																getPlaceOrderData.length &&
																getPlaceOrderData.find(
																	(o) => o.item_id === product.item_id
																) ? (
																	<AddButton
																		count={
																			[...getPlaceOrderData]
																				.reverse()
																				.find(
																					(o) => o.item_id === product.item_id
																				).qty
																		}
																		lastItem
																		itemData={product}
																		id={product.item_id}
																	/>
																) : (
																	<AddButton
																		id={product.item_id}
																		onClick={() => handleAddItem(product)}
																	/>
																)}
															</div>
														</div>
													</Link>
												</ErrorBoundary>
											)
										)
									) : (
										<ErrorBoundary
											sendErrorData={getErrorDataFromBoundary}
											itemDetails={product}
											fallBack={null}
										>
											<Link
												id={product.title}
												to="#"
												className={styles.item}
												key={`${index}grid`}
											>
												<div
													className={styles.image}
													onClick={() => addProduct(product)}
												>
													{product.item_image_url &&
													product.item_image_url !== "" ? (
														<img
															src={product.item_image_url}
															alt={product.item_name}
														/>
													) : (
														<img src={platePng} alt={product.item_name} />
													)}
												</div>
												<div className={styles.description}>
													<h4 className={styles.itemTitle}>
														<VeganStatus
															type={product.item_type}
															egg={product.contains_egg}
														/>
														{product.item_name}
													</h4>
													<div className={styles.info}>
														<div className={styles.price}>
															<h4>
																{currencySymbol()}
																{product.item_price_details &&
																	product.item_price_details.length &&
																	product.item_price_details[0].item_price}
															</h4>
														</div>
														{/* <AddButton onClick={() => addProduct(product)} /> */}
														{getPlaceOrderData &&
														getPlaceOrderData.length &&
														getPlaceOrderData.find(
															(o) => o.item_id === product.item_id
														) ? (
															<AddButton
																count={
																	[...getPlaceOrderData]
																		.reverse()
																		.find((o) => o.item_id === product.item_id)
																		.qty
																}
																itemData={product}
																lastItem
																id={product.item_id}
															/>
														) : (
															<AddButton
																id={product.item_id}
																onClick={() => handleAddItem(product)}
															/>
														)}
													</div>
												</div>
											</Link>
										</ErrorBoundary>
									)
								)
					  )
					: ""}
			</div>
		</div>
	);
};
export default GridItems;
