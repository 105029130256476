// core
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// library
import { useSelector } from "react-redux";
// import Loader from 'react-spinners/BeatLoader';
import Loader from "../Loading";

// components
import {
	AddProductModal,
	Footer,
	Header,
	RepeatItemModal,
	ShareModal,
} from "../";
import { getShowModal } from "../../../redux/modals/selectors";
import { getOrderDetailsData } from "../../../redux/reducer/main/selectors";
import { routes } from "../../App/routes";

const Layout = ({ children, noNav, noMenu }) => {
	const modal = useSelector(getShowModal);
	// const userData = useSelector(getOrderDetailsData);
	// useEffect(() => {
	// 	if (userData && userData.order_status === "completed") {
	// 		localStorage.clear();
	// 		window.location = routes.register;
	// 	}
	// }, []);

	return (
		<>
			{modal === "Add Product" && <AddProductModal />}
			{modal === "Share" && <ShareModal />}
			{modal === "Repeat Item" && <RepeatItemModal />}
			<Header noNav={noNav} />
			<Loader />
			{children}
			<Footer noMenu={noMenu} />
		</>
	);
};
export default Layout;
