/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
// core
import React from "react";

// library
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import { routes } from "../../App/routes";
import { modalsActions } from "../../../redux/modals/actions";
import { changeMenuTab } from "../../../redux/reducer/main/action";

// assets
import styles from "./Header.module.scss";
import { ReactComponent as SearchSvg } from "../../../assets/images/icons/search.svg";
import { ReactComponent as QRSvg } from "../../../assets/images/icons/qr.svg";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
// import { getRestrauntDetails } from "../../../redux/reducer/main/selectors";
import {
	getMenu,
	getRouting,
} from "../../../redux/reducer/onboarding/selectors";
import { ReactComponent as ArrowSvg } from "../../../assets/images/icons/arrow-down.svg";
import { ReactComponent as BackSvg } from "../../../assets/images/icons/arrow-back.svg";
import { toTitleCase } from "../../../config/helper";
import {
	loader,
	loadGetTableCode,
} from "../../../redux/reducer/onboarding/actions";
import history from "../../../config/history";
// import { getShowModal } from "../../../redux/modals/selectors";

const links = [
	{ name: "Special", url: routes.special, tabName: "special" },
	{ name: "Food", url: routes.main, tabName: "food" },
	{ name: "Beverages", url: routes.beverages, tabName: "beverages" },
	{ name: "Desserts", url: routes.desserts, tabName: "desserts" },
	{ name: "Drinks", url: routes.drinks, tabName: "drinks" },
	{ name: "Store", url: routes.store, tabName: "store" },
];

export const Header = ({ noNav }) => {
	const dispatch = useDispatch();
	const handleChangeTab = (data) => {
		const item = document.getElementById(`${data}Super`);
		if(item){
			item.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center",
			});
		}
		if (data !== "special") {
			dispatch(changeMenuTab(data));
		}
	};
	// let time = 1;
	// const [timer, setTimer] = useState(time);
	// useEffect(() => {
	// 	// timer
	// 	const intervalId = setInterval(() => {
	// 		if (time === -1) {
	// 			clearInterval(intervalId);
	// 		} else {
	// 			setTimer(time--);
	// 		}
	// 	}, 1000);
	// }, []);
	// const superCat = document.getElementById("superCat");
	// const superCatWidth = superCat && superCat.scrollWidth;
	// To Scroll super categories horizontically
	// eslint-disable-next-line no-unused-vars
	// window.addEventListener("loadstart  ", () => {
	// 	// document.getElementById('superCat').scrollLeft += 100;
	// 	if (document.getElementById("superCat")) {
	// 		document.getElementById("superCat").scrollTo({
	// 			top: 0,
	// 			left: 100,
	// 			behavior: "smooth",
	// 		});
	// 		const intervalId = setInterval(() => {
	// 			if (time === 0) {
	// 				clearInterval(intervalId);
	// 			} else {
	// 				handleBackToScroll(time--);
	// 			}
	// 		}, 1000);
	// 	}
	// });
	// const handleBackToScroll = () => {
	// 	if (document.getElementById("superCat")) {
	// 		document.getElementById("superCat").scrollTo({
	// 			top: 0,
	// 			left: 0,
	// 			behavior: "smooth",
	// 		});
	// 	}
	// };
	const menu = useSelector(getMenu);
	const rest = menu && menu.restraunt_details && menu.restraunt_details;
	// const [dropdownOpen, setDropdownOpen] = useState(false);

	// const toggle = () => setDropdownOpen((prevState) => !prevState);

	const type =
		localStorage.getItem("type") && JSON.parse(localStorage.getItem("type"));
	const handleSetType = (data) => {
		if (type !== data) {
			localStorage.setItem("type", JSON.stringify(data));
			window.location.reload();
		}

		// localStorage.setItem('type', JSON.stringify('delivery'));
	};

	const menuData = menu && menu.menu;

	const data = useSelector(getMenu);
	const menuKeys = menuData && Object.keys(menuData);
	let processedData = {};
	menuKeys &&
		menuKeys.length &&
		menuKeys.map((val) => {
			const inside_menu = Object.keys(data.menu[val]);
			inside_menu.map((inside) => {
				let category = data.menu[val][inside].filter((val_data, i) => {
					return val_data.is_special === true;
				});
				if (category.length > 0) {
					processedData = { ...processedData, [inside]: category };
				}
			});
		});

	const superCategories =
		data && data.menu && Object.keys(data && data.menu && data.menu);
	const makeArray = processedData && Object.values(processedData);
	const route = useSelector(getRouting);
	const query = route && route.location && route.location.pathname;
	console.log("query :>> ", query);
	useEffect(() => {
		if (makeArray && makeArray.length) {
			superCategories.push("special");
		}
		// const currentPage =
		// 	superCategories && superCategories.includes(query && query.substr(1));

		// *****************if special super category not found then i will redirect user to the first supercategory found
		// if (currentPage === false && makeArray && makeArray.length === 0) {
		// 	history.push(`/${superCategories && superCategories[0]}`);
		// }
	}, []);

	const handleOpenShareCodeModal = () => {
		const joinCode = localStorage.getItem("joinCode");
		if (joinCode === null || joinCode === "null" || !joinCode) {
			dispatch(loader(true));
			dispatch(loadGetTableCode("showJoinCode"));
		} else {
			dispatch(modalsActions.setShowModal("Share"));
		}
	};

	// useEffect(() => {
	//   const currentPage = superCategories && superCategories.includes(query && query.substr(1));
	//   // if special super category not found then i will redirect user to the first supercategory found
	//   if(currentPage === false) {
	//     history.push(`/${superCategories && superCategories[0]}`)
	//   }
	// }, [query, superCategories]);
	const driveIn = localStorage.getItem("driveIn")
		? JSON.parse(localStorage.getItem("driveIn"))
		: false;
	return (
		<header className={styles.header}>
			<div className={`${noNav ? "pb-20" : ""} ${styles.top}`}>
				<h3>
					<span>
						{driveIn ? (
							<BackSvg
								style={{ position: "relative", top: "7px" }}
								onClick={() => history.push(routes.driveIn)}
							/>
						) : (
							""
						)}
					</span>{" "}
					{rest && rest.restraunt_image ? (
						<img
							src={rest && rest.restraunt_image}
							alt="restraunt"
							width={24}
							height={15}
							className="restRoundImage"
						/>
					) : null}
					<div className="dropdown">
						{/* <div
						className={
							type === "pick_up_delivery" ? "dropdown" : "dropdown no-dropdown"
						}
					> */}
						<span>
							{type === "pick_up_delivery"
								? "Delivery"
								: type === "dine_in"
								? rest && rest.restraunt_name
								: type && toTitleCase(type.replace(/_/g, " "))}
						</span>
						{type === "dine_in" ? (
							""
						) : (
							<div className="dropdown-content">
								<p onClick={() => handleSetType("pick_up_delivery")}>
									Delivery
								</p>
								<p onClick={() => handleSetType("pick_up")}>Pick up</p>
							</div>
						)}
					</div>{" "}
					{JSON.parse(localStorage.getItem("type")) === "dine_in" ? (
						""
					) : (
						<ArrowSvg />
					)}
				</h3>
				<ul
					style={{
						display: "-webkit-box !important",
						overflowX: "scroll !important",
					}}
				>
					{type === "dine_in" ? (
						<li>
							<div
								className={styles.icon}
								onClick={() => handleOpenShareCodeModal()}
							>
								<QRSvg />
							</div>
						</li>
					) : (
						""
					)}
					{window.location.pathname !== "/drive-in" ? (
						<li>
							<Link to={routes.search} className={styles.icon}>
								<SearchSvg />
							</Link>
						</li>
					) : (
						""
					)}
				</ul>
			</div>
			{!noNav ? (
				<nav>
					<ul id="superCat">
						{links.map((link) =>
							(menuData && menuData[link.tabName]) ||
							(link.tabName === "special" && makeArray && makeArray.length) ? (
								<li key={link.name}>
									<NavLink
										key={link.name}
										id={`${link.tabName}Super`}
										onClick={() => handleChangeTab(link.tabName)}
										to={link.url}
									>
										{link.name}
									</NavLink>
								</li>
							) : (
								""
							)
						)}
					</ul>
				</nav>
			) : (
				""
			)}
		</header>
	);
};
