import { routes } from "../components/App/routes";
import { displayNotification } from "./helper";
import history from "./history";

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
	if (response.status === 204 || response.status === 205) {
		return null;
	}
	if (response.status === 500) {
		displayNotification("Oops! Something unexpected happened");
		return { message: "500 Error" };
	}
	return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
	if (response.status >= 200 && response.status < 300) {
		return response;
	}
	if (response.status === 401) {
		// const urlParams = new URLSearchParams(window.location.search);
		// const hasOrderId = urlParams.get("order_id");
		// if (hasOrderId) {
		// 	history.push(`${routes.register}?order_id=${hasOrderId}`);
		// } else {
		// 	localStorage.clear();
		// 	window.location = routes.register;
		// }
		localStorage.clear();
		window.location = routes.register;
		displayNotification("Please scan the qr");
		return response;
	}
	if (response.status > 401 || response.status === 400) {
		// displayNotification(response.message);
		return response;
	}
	if (response.status === 500) {
		return { statusData: "Oops! Something unexpected happened" };
	}

	const error = new Error(response.statusText);
	error.response = response;
	throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
// export default function request(url, options) {
//   const header = {
//     'Content-Type': 'application/json',
//     // 'Content-Type': 'multipart/form-data',
//     accept: 'application/json',
//     authorization: JSON.parse(localStorage.getItem('token')),
//   };
//   // eslint-disable-next-line no-param-reassign
//   options.headers = { ...header, ...options.headers };
//   return fetch(url, options)
//     .then(checkStatus)
//     .then(parseJSON);
// }
export default function request(url, options) {
	const header = {
		"Content-Type": "application/json",
		// 'Content-Type': 'multipart/form-data',
		accept: "application/json",
		"accept-encoding": "gzip",
		authorization: JSON.parse(localStorage.getItem("token")),
	};
	// eslint-disable-next-line no-param-reassign
	options.headers = { ...header, ...options.headers };
	return fetch(url, options)
		.then(checkStatus)
		.then(parseJSON)
		.catch((e) => {
			console.log("request error", e);
		});
}
export function requestNoAuth(url, options) {
	const header = {
		"Content-Type": "application/json",
		// 'Content-Type': 'multipart/form-data',
		accept: "application/json",
	};
	// eslint-disable-next-line no-param-reassign
	options.headers = { ...header, ...options.headers };
	return fetch(url, options)
		.then(checkStatus)
		.then(parseJSON)
		.catch((e) => {
			console.log("request error", e);
		});
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function upload(url, options) {
	const header = {
		authorization: JSON.parse(localStorage.getItem("token")),
	};
	// eslint-disable-next-line no-param-reassign
	options.headers = header;
	return fetch(url, options).then(checkStatus).then(parseJSON);
}
