import React from "react";
import { Link } from "react-router-dom";
import platePng from "../../../assets/images/plate.png";

const GridBox = ({ product, styles, handleOnClick, index }) => {
	return (
		<Link
			id={product.title}
			to="#"
			className={styles.item}
			key={`${index}grid`}
		>
			<div
				className={styles.image}
				onClick={() => handleOnClick(product.brand_id)}
			>
				{product.item_image_url && product.item_image_url !== "" ? (
					<img src={product.item_image_url} alt={product.item_name} />
				) : (
					<img src={platePng} alt={product.item_name} />
				)}
			</div>
			<div
				onClick={() => handleOnClick(product.brand_id)}
				className={styles.description}
			>
				<h4 className={styles.itemTitle}>{product.brand_name}</h4>
				<div className={styles.info}>
					{/* <div className={styles.price}>
									<h4>
										{product.item_price_details &&
											product.item_price_details.length &&
											product.item_price_details[0].item_price}
									</h4>
								</div> */}
					{/* <AddButton onClick={() => addProduct(product)} /> */}
				</div>
			</div>
		</Link>
	);
};

export default GridBox;
