// core
import React from 'react'

// library
import { useSelector } from "react-redux";

// components
import { AddProductModal, ComplaintsModal } from "../";
import { getShowModal } from "../../../redux/modals/selectors";
// import { CookingInstruction } from '../CookingInstruction/CookingInstruction';
import Loader from '../Loading';
// import { DeliveryAddModal } from '../modals/DeliveryAddressModal/DeliveryAddModal';

export const NoNavigationLayout = ({children}) => {
  const modal = useSelector(getShowModal);

  return (
    <>
      {modal === 'Add Product' && <AddProductModal />}
      {modal === 'Complaints' && <ComplaintsModal />}
      <Loader />
      {/* {modal === 'Add Address' && <DeliveryAddModal />} */}
      {children}
    </>
  )
};
