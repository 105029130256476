import React from 'react'
// import styles from "../../pages/main/Checkout/Checkout.module.scss";
import { currencySymbol } from '../../../config/helper';
import minus from "../../../assets/images/icons/minus-circle.svg";
import donate from "../../../assets/images/icons/donate-heart.svg";
import { useDispatch } from 'react-redux';
import { loadTip } from '../../../redux/reducer/main/action';

const Tip = (props) => {
    const {tipAmount, setTip, styles} = props;
    const dispatch = useDispatch();
    const country = localStorage.getItem("country");
    const handleClearTip = () => {
        if (document.getElementById("tipAmount")) {
          document.getElementById("tipAmount").value = "";
        }
        dispatch(loadTip(0));
      };
  return (
    <React.Fragment>
           <h4 className={styles.title}>
              <img src={donate} alt="donate-img" className={styles.donate} />
              Tip your waiter
              <span className={styles.tipped}>
                {tipAmount > 0 ? `${currencySymbol()}${tipAmount} tipped` : ""}
              </span>
              {tipAmount > 0 ? (
                <img
                  src={minus}
                  alt="minus"
                  onClick={() => handleClearTip()}
                  className={styles.minus}
                />
              ) : (
                ""
              )}
            </h4>
            {country && country !== null && country.toLowerCase() == "aus" ? (
              <div className={styles.boxes}>
                <div
                  className={`${styles.box} ${
                    tipAmount === 5 ? styles.active : ""
                  }`}
                  onClick={() => dispatch(loadTip(5))}
                >
                  {currencySymbol()}5
                </div>
                <div
                  className={`${styles.box} ${
                    tipAmount === 10 ? styles.active : ""
                  }`}
                  onClick={() => dispatch(loadTip(10))}
                >
                  {currencySymbol()}10
                  <span>Most Tipped</span>
                </div>
                <div
                  className={`${styles.box} ${
                    tipAmount === 15 ? styles.active : ""
                  }`}
                  onClick={() => dispatch(loadTip(15))}
                >
                  {currencySymbol()}15
                </div>
                <label className={styles.input}>
                  <input
                    type="number"
                    id="tipAmount"
                    placeholder="Enter Amount"
                    onChange={(e) => setTip(parseInt(e.target.value))}
                  />
                </label>
              </div>
            ) : (
              <div className={styles.boxes}>
                <div
                  className={`${styles.box} ${
                    tipAmount === 100 ? styles.active : ""
                  }`}
                  onClick={() => dispatch(loadTip(100))}
                >
                  {currencySymbol()}100
                </div>
                <div
                  className={`${styles.box} ${
                    tipAmount === 200 ? styles.active : ""
                  }`}
                  onClick={() => dispatch(loadTip(200))}
                >
                  {currencySymbol()}200
                  <span>Most Tipped</span>
                </div>
                <div
                  className={`${styles.box} ${
                    tipAmount === 500 ? styles.active : ""
                  }`}
                  onClick={() => dispatch(loadTip(500))}
                >
                  {currencySymbol()}500
                </div>
                <label className={styles.input}>
                  <input
                    type="number"
                    id="tipAmount"
                    placeholder="Enter Amount"
                    onChange={(e) => setTip(parseInt(e.target.value))}
                  />
                </label>
              </div>
            )}
          </React.Fragment>
  )
}

export default Tip