import { types } from "./types";

export function changeMenuTab(data) {
	return {
		type: types.CURRENT_MENU_TAB,
		payload: data,
	};
}
export function placeOrderData(data, data2) {
	return {
		type: types.PLACE_ORDER_DATA,
		payload: data,
		payload2: data2,
	};
}
export function changeQty(data) {
	return {
		type: types.CHANGE_QTY,
		payload: data,
	};
}
export function loadOrderDetails(data, status) {
	return {
		type: types.LOAD_ORDER_DETAILS,
		payload: data,
		status,
	};
}
export function getOrderDetails(data) {
	return {
		type: types.GET_ORDER_DETAILS,
		payload: data,
	};
}
export function placeOrder() {
	return {
		type: types.PLACE_ORDER,
	};
}
export function loadCoupons() {
	return {
		type: types.LOAD_COUPONS,
	};
}
export function getCoupons(data) {
	return {
		type: types.GET_COUPONS,
		payload: data,
	};
}
export function applyCoupon(data) {
	return {
		type: types.APPLY_COUPON,
		payload: data,
	};
}
export function loadTip(data) {
	return {
		type: types.LOAD_TIP,
		payload: data,
	};
}
export function loadCheckout(data) {
	return {
		type: types.LOAD_CHECKOUT,
		payload: data,
	};
}
export function acknowledgePayment(data) {
	return {
		type: types.ACKNOWLEDGE_PAYMENT,
		payload: data,
	};
}
export function getRatingData(data) {
	return {
		type: types.GET_RATING_DATA,
		payload: data,
	};
}
export function submitRatingData(data) {
	return {
		type: types.SUBMIT_RATING_DATA,
		payload: data,
	};
}
export function loadRestrauntDetails(data) {
	return {
		type: types.LOAD_RESTRAUNT_DETAILS,
		payload: data,
	};
}
export function getRestrauntDetails(data) {
	return {
		type: types.GET_RESTRAUNT_DETAILS,
		payload: data,
	};
}
export function submitComplaint(data) {
	return {
		type: types.SUBMIT_COMPLAINT,
		payload: data,
	};
}
export function loadMenuData(data) {
	return {
		type: types.LOAD_MENU_DATA,
		payload: data,
	};
}
export function requestService(data) {
	return {
		type: types.REQUEST_SERVICE,
		payload: data,
	};
}
export function vegOnly(data) {
	return {
		type: types.VEG_ONLY,
		payload: data,
	};
}
export function containsEgg(data) {
	return {
		type: types.CONTAINS_EGG,
		payload: data,
	};
}
export function loadPlacePickupDelivery(data) {
	return {
		type: types.LOAD_PLACE_PICKUP_DELIVERY,
		payload: data,
	};
}
export function toggleAddAddress(data) {
	return {
		type: types.TOGGLE_ADD_ADDRESS,
		payload: data,
	};
}
export function submitAddress(data) {
	return {
		type: types.SUBMIT_ADDRESS,
		payload: data,
	};
}
export function getAddress(data) {
	return {
		type: types.GET_ADDRESS,
		payload: data,
	};
}
export function loadQrDetails(data) {
	return {
		type: types.LOAD_QR_DETAILS,
		payload: data,
	};
}
export function razorPay(data) {
	return {
		type: types.RAZORPAY,
		payload: data,
	};
}
export function allergyDetails(data) {
	return {
		type: types.ALLERGY_DETAILS,
		payload: data,
	};
}
export function backButton(data) {
	return {
		type: types.BACK_BUTTON,
		payload: data,
	};
}
export function getFeedbackData(data) {
	return {
		type: types.GET_FEEDBACK_DATA,
		payload: data,
	};
}
export function loadFeedbackData(data) {
	return {
		type: types.LOAD_FEEDBACK_DATA,
		payload: data,
	};
}
export function submitFeedbackData(data) {
	return {
		type: types.SUBMIT_FEEDBACK_DATA,
		payload: data,
	};
}
export function loadOrderTrackData(data) {
	return {
		type: types.LOAD_ORDER_TRACK_DATA,
		payload: data,
	};
}

export const fetchBrandMenu = (data) => {
	return {
		type: types.FETCH_BRAND_MENU,
		payload: data,
	};
};
export const placeOrderLocalData = (data) => {
	return {
		type: types.PLACE_ORDER_LOCAL_DATA,
		payload: data,
	};
};
export const getOrderDetailsPrepaid = (data) => {
	return {
		type: types.ORDER_DETAILS_PREPAID,
		payload: data,
	};
};
