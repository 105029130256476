import React from "react";

// library
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";

// components
import { WrapperModal } from "../WrapperModal/WrapperModal";
import { routes } from "../../../App/routes";
import { modalsActions } from "../../../../redux/modals/actions";
// import { EventSection } from "../../EventSection/EventSection";

// assets
import styles from "./OrderModal.module.scss";
import { ReactComponent as WarningSvg } from "../../../../assets/images/icons/warning.svg";
import saly7 from "../../../../assets/images/saly-7.png";
// import currency from "../../../../assets/images/icons/currency.svg";
// import prism from "../../../../assets/images/events/prism-1.png";
// import event1 from "../../../../assets/images/events/varnam.png";
// import tot from "../../../../assets/images/events/tot.png";
// import event3 from "../../../../assets/images/events/event-3.png";
import history from "../../../../config/history";
import { useEffect } from "react";
import {
	loadOrderDetails,
	loadRestrauntDetails,
} from "../../../../redux/reducer/main/action";
import { ReactComponent as RightCarret } from "../../../../assets/images/icons/rightCarret.svg";
import { currencySymbol, displayNotification } from "../../../../config/helper";
import { getOrderDetailsData } from "../../../../redux/reducer/main/selectors";
import { loadTableJoinDetails } from "../../../../redux/reducer/onboarding/actions";
import Loader from "../../Loading";

export const OrderModal = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		if (qrFlow !== "prepaid") {
			dispatch(loadOrderDetails());
			dispatch(loadRestrauntDetails());
		}
	}, []);
	const orderDetails = useSelector(getOrderDetailsData);
	// console.log('order :>> ', orderDetails);
	const handleClickPayNow = () => {
		if (orderDetails && orderDetails.order_status === "occupied") {
			displayNotification("Your order is not approved yet.");
		} else {
			console.log(
				"orderDetails && orderDetails.order_status :>> ",
				orderDetails && orderDetails.order_status
			);
			dispatch(modalsActions.setShowModal(""));
			history.push(routes.checkout);
		}
	};
	const qrFlow = JSON.parse(localStorage.getItem("qrFlow"));
	const onHandleOrderMore = () => {
		if (qrFlow === "prepaid") {
			const qrCode = JSON.parse(localStorage.getItem("qrCode"));
			dispatch(loadTableJoinDetails(qrCode));
		} else {
			history.push(routes.special);
			dispatch(modalsActions.setShowModal(""));
		}
	};
	// var { order_amount} = orderDetails;
	// console.log('restData :>> ', restData);
	return (
		<WrapperModal
			blackIcon
			isPrepaid={qrFlow === "prepaid"}
			onHandleOrderMore={onHandleOrderMore}
		>
			<Loader />
			<div className={styles.order}>
				{qrFlow !== "prepaid" && (
					<div className={styles.warning}>
						<WarningSvg
							onClick={() => dispatch(modalsActions.setShowModal("Complaints"))}
						/>
					</div>
				)}
				<div className={styles.card}>
					<div className={styles.item}>
						<div className={styles.description}>
							<h2>
								Order <br />
								successfully <br />
								placed!
							</h2>
							<img src={saly7} alt="saly7" />
						</div>
						<p>Your order will be served soon.</p>
					</div>
					{qrFlow !== "prepaid" ? (
						<div className={styles.buttons}>
							<button
								onClick={() => onHandleOrderMore()}
								type="button"
								className={`btn ${styles.button}`}
							>
								Order more
							</button>
							<button
								type="button"
								className={`btn ${styles.button}`}
								onClick={() => handleClickPayNow()}
							>
								Pay now
								<span className={styles.currency}>
									{/* <img src={currency} alt='currency' /> */}
									{currencySymbol()}
								</span>
							</button>
						</div>
					) : (
						""
					)}
				</div>
				{/* <EventSection items={items} title='Explore Events ' /> */}
			</div>
			{qrFlow === "prepaid" ? (
				<div className={`bottom-button-parent-div ${styles.buttons}`}>
					<button
						onClick={() => onHandleOrderMore()}
						type="button"
						className={`btn ${styles.button}`}
						style={{ boxShadow: "none", width: "80%" }}
					>
						ORDER MORE{"  "}
						<span style={{ marginLeft: 10 }}>
							<RightCarret />
						</span>
					</button>
				</div>
			) : (
				""
			)}
		</WrapperModal>
	);
};
