/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import { toTitleCase } from "../../../config/helper";
// import { useDispatch } from "react-redux";

// components
// import { Link } from "react-router-dom";
// import { Feedback } from "..";
// import { getRatingData } from "../../../redux/reducer/main/action";

// assets
import styles from "./Wrong.module.scss";

const wrong = ["SERVICE", "FOOD TASTE", "CLEANLINESS", "PREPARATION ISSUES"];
const right = ["TASTE", "AMBIANCE", "SERVICE", "PORTION SIZE"];
export const Wrong = ({
	update,
	ratingData,
	setRatingData,
	defaultLabel,
	star,
	itemWiseRating,
	handleSubmitEmote,
	product,
	overallReview,
}) => {
	useEffect(() => {
		if (update) {
			setCurrentWrong(defaultLabel ? defaultLabel.split(",") : []);
			setFeedback(overallReview || "");
		} else {
			setCurrentWrong([]);
		}
		if (itemWiseRating) {
			// * for items rating feed back
			handleSubmitEmote(
				{
					item_rating: star,
					item_id: product.item_id,
					item_order_id: product.item_order_id,
					item_review: "",
					item_name: product.item_name,
					item_type: product.item_type,
					contains_egg: product.contains_egg,
				},
				"feedback"
			);
		} else {
			// * for overall ratings and update overall ratings

			setRatingData({ ...ratingData, overall_review: "" });
		}
	}, [star]);
	// console.log('overallReview :>> ', overallReview);
	const screenWidth = window && window.screen && window.screen.width;
	// const dispatch = useDispatch()
	const [currentWrong, setCurrentWrong] = useState(
		(ratingData && ratingData.overall_review) || []
	);
	// const [showFeedback, setShowFeedback] = useState(false);
	const [feedback, setFeedback] = useState("");
	const handleChangeWrong = (data) => {
		// setCurrentWrong(data);
		if (currentWrong && currentWrong.indexOf(data) === -1) {
			const feedBackWithLabel = `${[...currentWrong, data]}. ${feedback}`;
			setCurrentWrong((prev) => [...prev, data]);
			if (itemWiseRating) {
				// * for items rating feed back
				handleSubmitEmote(
					{
						item_rating: star,
						item_id: product.item_id,
						item_order_id: product.item_order_id,
						item_review: feedBackWithLabel || "",
						item_name: product.item_name,
						item_type: product.item_type,
						contains_egg: product.contains_egg,
					},
					"feedback"
				);
			} else {
				// * for overall ratings and update overall ratings
				setRatingData({
					...ratingData,
					overall_review: feedBackWithLabel || "",
				});
			}
		} else {
			setCurrentWrong(currentWrong.filter((e) => e !== data));
			const removeValue = currentWrong.filter((e) => e !== data);
			const feedbackRemoveLabel = `${removeValue}. ${feedback}`;
			if (itemWiseRating) {
				// * for items rating feed back
				handleSubmitEmote(
					{
						item_rating: star,
						item_id: product.item_id,
						item_order_id: product.item_order_id,
						item_review: feedbackRemoveLabel || "",
						item_name: product.item_name,
						item_type: product.item_type,
						contains_egg: product.contains_egg,
					},
					"feedback"
				);
			} else {
				// * for overall ratings and update overall ratings
				setRatingData({
					...ratingData,
					overall_review: feedbackRemoveLabel || "",
				});
			}
		}
	};

	const handleChangeTextarea = () => {
		const feedBackWithLabel = `${currentWrong}.${feedback}`;
		if (itemWiseRating) {
			handleSubmitEmote(
				{
					item_rating: star,
					item_id: product.item_id,
					item_order_id: product.item_order_id,
					item_review: feedBackWithLabel || "",
					item_name: product.item_name,
					item_type: product.item_type,
					contains_egg: product.contains_egg,
				},
				"feedback"
			);
		} else {
			setRatingData({ ...ratingData, overall_review: feedBackWithLabel || "" });
		}
	};

	const textBoxId = document.getElementById("idTextarea");

	// const [showTextBox, setShowTextBox] = useState(false);
	return (
		<React.Fragment>
			<div className={styles.wrong}>
				{/* <Feedback showFeedback={showFeedback} ratingData={ratingData} overallReview setRatingData={setRatingData} setShowFeedback={setShowFeedback} feedback={feedback} setFeedback={setFeedback} /> */}
				{star > 3 ? <h5> What you loved?</h5> : ""}
				{star < 4 ? <h5>What went wrong</h5> : ""}
				{star < 4
					? wrong.map((item) => (
							<span
								key={item}
								className={
									currentWrong &&
									currentWrong.includes(toTitleCase(item)) === true
										? styles.active
										: ""
								}
								onClick={() => handleChangeWrong(toTitleCase(item))}
							>
								{item}
							</span>
					  ))
					: ""}
				{star > 3
					? right.map((item) => (
							<span
								key={item}
								className={
									currentWrong &&
									currentWrong.includes(toTitleCase(item)) === true
										? styles.active
										: ""
								}
								onClick={() => handleChangeWrong(toTitleCase(item))}
							>
								{item}
							</span>
					  ))
					: ""}
				{/* {showTextBox ? <textarea placeholder='Something else? Mention it here.' onChange={e => handleChangeWrong(e.target.value)} /> : ''} */}
				{/* <Link to={'#'} className={styles.provide}>Provide a Feedback</Link> */}
			</div>
			{itemWiseRating ? (
				<label className={`${styles.input}`} style={{ marginTop: 10 }}>
					<input
						type="text"
						// required={star < 4}
						onChange={(e) => {
							setFeedback(e.target.value);
							handleChangeTextarea();
						}}
						style={{ width: `${screenWidth - 52}px` }}
						value={feedback}
						placeholder={
							star <= 3
								? `Tell us what went wrong ☹️`
								: `Tell us what you loved 🙂`
						}
					/>
				</label>
			) : (
				<label className="mb-0 less-box-shadow">
					<textarea
						name="rating"
						className="less-box-shadow"
						id="idTextarea"
						required={star < 4}
						onFocus={(e) =>
							textBoxId &&
							textBoxId.scrollIntoView({
								behavior: "smooth",
								block: "end",
								inline: "end",
							})
						}
						onChange={(e) => {
							setFeedback(e.target.value);
							handleChangeTextarea();
						}}
						value={feedback}
						placeholder={
							ratingData.overall_ratings <= 3
								? `Tell us what went wrong ☹️`
								: `Tell us what you loved 🙂`
						}
					/>
				</label>
			)}
		</React.Fragment>
	);
};
