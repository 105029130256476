/* eslint-disable eqeqeq */
// core
import React, { useEffect, useReducer, useState } from "react";

// components
// import { routes } from "../../../App/routes";
import { NoNavigationLayout } from "../../../common";
// assets
import styles from "../Onboarding.module.scss";
import saly from "../../../../assets/images/onboarding/saly-1.png";
import numbers from "../../../../assets/images/onboarding/numbers.svg";
import {
	fetchOTP,
	// loader,
	loadTableJoinDetails,
} from "../../../../redux/reducer/onboarding/actions";
// import history from '../../../../config/history';
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRouting } from "../../../../redux/reducer/onboarding/selectors";
import { displayNotification } from "../../../../config/helper";
import history from "../../../../config/history";
import { routes } from "../../../App/routes";
import { loadOrderDetails } from "../../../../redux/reducer/main/action";
// import {
//   loadOrderDetails,
//   loadQrDetails,
// } from "../../../../redux/reducer/main/action";

// const phoneCode = ['91', '61'];
const Register = (props) => {
	const [activeButton, setActiveButton] = useState(false);
	const route = useSelector(getRouting);
	const dispatch = useDispatch();
	const query = route && route.location && route.location.query;
	useEffect(() => {
		// localStorage.removeItem("orderId");
		const type = route && route.location && route.location.type;
		const token = localStorage.getItem("token");
		query.type && localStorage.setItem("type", JSON.stringify(query.type));
		query.qr_code &&
			localStorage.setItem("qrCode", JSON.stringify(query.qr_code));
		localStorage.setItem("driveIn", false);
		query.order_id &&
			localStorage.setItem("orderId", JSON.stringify(query.order_id));
		type && localStorage.setItem("type", JSON.stringify(type));
		query.cn && localStorage.setItem("country", query.cn);
		const qrCode = JSON.parse(localStorage.getItem("qrCode"));
		const orderType = JSON.parse(localStorage.getItem("type"));
		const orderId = localStorage.getItem("orderId");
		const joinCode = localStorage.getItem("joinCode");
		// const customerId = localStorage.getItem("customerId");

		// const getType = localStorage.getItem("type");
		if (token && qrCode) {
			// console.log("query.qr_code !== qrCode :>> ", query.qr_code !== qrCode);

			if (orderType === "dine_in" && orderId && joinCode) {
				// dispatch(loadOrderDetails("orderPay"));
				// dispatch(loadTableJoinDetails(qrCode));
				dispatch(loadOrderDetails("checkOrderStatus"));
			} else if (orderType === "dine_in" && !orderId) {
				// history.push(routes.special);
				console.log("object :>> check asdf");
				dispatch(loadTableJoinDetails(qrCode));
			} else if (orderType !== "dine_in") {
				history.push(routes.special);
			}
		}
	}, []);

	const handleChangePhone = (evt) => {
		if (evt.target.value.length === 11) {
			return;
		}
		const name = evt.target.name;
		const newValue = evt.target.value;
		setFormInput({ [name]: newValue });
		if (evt.target.value.length === 10) {
			setActiveButton(true);
		} else {
			setActiveButton(false);
		}
	};
	const handleChangeCode = (evt) => {
		const name = evt.target.name;
		const newValue = evt.target.value;
		setFormInput({ [name]: newValue });
	};
	// const route = useSelector(getRouting)
	// const queryType = route && route.location && route.location.query.type;
	// const orderId = route && route.location && route.location.query.order_id;
	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(fetchOTP({ phone: formInput.code + formInput.phone }));
		// if (queryType || orderId) {
		//   return dispatch(fetchOTP({ phone: formInput.code + formInput.phone }));
		// } else {
		//   return displayNotification("Please Scan the qr code");
		// }
	};
	const country = localStorage.getItem("country");

	const [formInput, setFormInput] = useReducer(
		(state, newState) => ({ ...state, ...newState }),
		{
			phone: "",
			code:
				country && country !== null && country.toLowerCase() == "aus"
					? "61"
					: "91",
		}
	);
	return (
		<NoNavigationLayout>
			<main className={styles.register}>
				<section>
					<div className={styles.inner}>
						<img className={styles.saly} src={saly} alt="saly" />
						<img className={styles.numbers} src={numbers} alt="numbers" />
						<div className={styles.panel}>
							<h2 style={{ whiteSpace: "nowrap" }}>Register yourself</h2>
							<p>Enter your 10 digit phone number</p>
							<form onSubmit={(e) => handleSubmit(e)}>
								<label className="phone">
									<select name="code" disabled onChange={handleChangeCode}>
										{/* {phoneCode.map((code) => ( */}
										<option key={formInput.code} value={formInput.code}>
											+{formInput.code}
										</option>
										{/* ))} */}
									</select>
									<input
										type="number"
										required
										placeholder="Phone number"
										name="phone"
										value={formInput.phone}
										onChange={handleChangePhone}
									/>
								</label>
								<p className={styles.sent}>
								You will recieve order alerts on your WhatsApp
								</p>
								<button
									type="submit"
									className={`${activeButton ? styles.active : ""} btn`}
								>
									Continue
								</button>
							</form>
						</div>
					</div>
				</section>
			</main>
		</NoNavigationLayout>
	);
};
export default withRouter(React.memo(Register));
