// core
import React, { useState } from "react";

// library
import { Switch, Route, Redirect, Router } from "react-router-dom";

// components
import { routes } from "./routes";
import { Coupons, Event } from "../pages";

// assets
import styles from "./App.module.scss";
import Verify from "../pages/onBoarding/Verify/loadable";
import Register from "../pages/onBoarding/Register/loadable";
import history from "../../config/history";
import { useDispatch, useSelector } from "react-redux";
import { getRouting } from "../../redux/reducer/onboarding/selectors";
import { useEffect } from "react";
import { getShowModal } from "../../redux/modals/selectors";
import { RatingUpdateModal } from "../common//modals/RatingUpdateModal/RatingUpdateModal";
import { RatingModal } from "../common//modals/RatingModal/RatingModal";
import { OrderModal } from "../common/modals/OrderModal/OrderModal";
import Checkout from "../pages/main/Checkout/loadable";
import Special from "../pages/main/Special/loadable";
import OrderRating from "../pages/main/OrderRating/loadable";
import PlaceOrder from "../pages/main/PlaceOrder/loadable";
import GetStarted from "../pages/onBoarding/GetStarted/loadable";
import Search from "../pages/main/Search/loadable";
import Join from "../pages/onBoarding/Join/loadable";
import "../../assets/css/custom.css";
import "../../assets/css/lazyLoad.css";
import "../../assets/css/loading.scss";
import OrderDetails from "../pages/OrderDetails/loadable";
import ThankyouScreen from "../pages/ThanksScreen/loadable";
// import { sendErrorReport } from '../../redux/reducer/onboarding/actions';
import { modalsActions } from "../../redux/modals/actions";
import { backButton } from "../../redux/reducer/main/action";
import { getBackButton } from "../../redux/reducer/main/selectors";
import Main from "../pages/main/Main/Loadable";
import WhatsappIntegration from "../pages/onBoarding/Whatsapp/WhatsappIntegration";
import Bill from "../pages/main/Bill/Bill";
import OverallReview from "../pages/main/OverallReview/OverallReview";
import FeedbackReview from "../pages/main/OrderRating/FeedbackReview";
import DriveInList from "../pages/main/DriveIn/DriveInList";
// import { loader, submitTableJoinCode } from '../../redux/reducer/onboarding/actions';
// import { loadQrDetails } from '../../redux/reducer/main/action';
function App() {
	const route = useSelector(getRouting);
	const query = route && route.location && route.location.query;

	if (
		localStorage.getItem("type") &&
		localStorage.getItem("type") === undefined
	) {
		history.push(
			query.type === undefined
				? routes.register
				: query.cn //If country then url with cn name query otherwise without  it
				? `${routes.register}?type=${query.type}&cn=${query.cn}&qr_code=${query.qr_code}`
				: `${routes.register}?type=${query.type}&qr_code=${query.qr_code}`
		);
	}
	const dispatch = useDispatch();
	const [error, setError] = useState();
	const [errorData, setErrorData] = useState();
	const customerId = localStorage.getItem("customerId");
	useEffect(() => {
		if (error === true) {
			// dispatch(sendErrorReport(errorData));
			setError(false);
		}
	}, [dispatch, error, errorData]);
	window.onerror = function (msg, url, line) {
		setError(true);
		setErrorData({ msg, url, line, customerId });
	};
	const modal = useSelector(getShowModal);

	useEffect(() => {
		if (modal !== "") {
			document.body.classList.add("no-scroll");
		} else {
			document.body.classList.remove("no-scroll");
		}
	}, [modal]);
	const backButtonValue = useSelector(getBackButton);
	window.onpopstate = (e) => {
		e.preventDefault();
		if (backButtonValue === true) {
			history.go(1);
		}
		dispatch(backButton(false));
		dispatch(modalsActions.setShowModal(""));
		// history.push(null, document.title, document.href);
	};
	// history.pushState(null, null, window.location.href);
	// loginRouteValidation();
	return (
		<div className={styles.app} id="slowScroll">
			{modal === "Order" && <OrderModal />}
			{modal === "Rating" && <RatingModal />}
			{modal === "Rating Update" && <RatingUpdateModal />}
			<Router history={history}>
				<Switch>
					<Redirect exact from="/" to={routes.register} />
					<Route path={routes.register} component={Register} />
					<Route path={routes.verify} component={Verify} />
					<Route path={routes.getStarted} component={GetStarted} />
					<Route path={routes.join} component={Join} />
					<Route path={routes.main} component={Main} />
					<Route path={routes.desserts} component={Main} />
					<Route path={routes.drinks} component={Main} />
					<Route path={routes.store} component={Main} />
					<Route path={routes.beverages} component={Main} />
					<Route path={routes.special} component={Special} />
					<Route path={routes.search} component={Search} />
					<Route path={routes.placeOrder} component={PlaceOrder} />
					<Route path={routes.checkout} component={Checkout} />
					<Route path={routes.coupons} component={Coupons} />
					<Route path={`${routes.orderRating}`} component={OrderRating} />
					<Route path={`${routes.feedback}`} component={FeedbackReview} />
					<Route path={routes.event} component={Event} />
					<Route path={routes.orderDetails} component={OrderDetails} />
					<Route path={routes.thankYouScreen} component={ThankyouScreen} />
					<Route path={routes.driveIn} component={DriveInList} />
					<Route
						path={routes.whatsappIntegration}
						component={WhatsappIntegration}
					/>
					<Route path={routes.bill} component={Bill} />
					<Route path={routes.overallReview} component={OverallReview} />
					<Route path={routes.orderPlaced} component={OrderModal} />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
