import { types } from "./types";

export function fetchOTP(data) {
	return {
		type: types.SEND_OTP,
		payload: data,
	};
}

export const fetchOTPSuccess = (user) => {
	return {
		type: types.SEND_OTP_SUCESS,
		payload: user,
	};
};
export const verifyOTP = (data) => {
	return {
		type: types.VERIFY_OTP,
		payload: data,
	};
};
export const getTableJoinDetails = (data) => {
	return {
		type: types.GET_TABLE_JOIN_DETAILS,
		payload: data,
	};
};
export const loadTableJoinDetails = (data) => {
	return {
		type: types.LOAD_TABLE_JOIN_DETAILS,
		payload: data,
	};
};
export const submitTableJoinCode = (data, status) => {
	return {
		type: types.SUBMIT_TABLE_JOIN_CODE,
		payload: data,
		status,
	};
};
export const getTableCode = (data) => {
	return {
		type: types.GET_TABLE_CODE,
		payload: data,
	};
};
export const loadGetTableCode = (data) => {
	return {
		type: types.LOAD_GET_TABLE_CODE,
		payload: data,
	};
};
export const getAddModalData = (data) => {
	return {
		type: types.GET_ADD_MODAL_DATA,
		payload: data,
	};
};
export const changeName = (data) => {
	return {
		type: types.CHANGE_NAME,
		payload: data,
	};
};
export const loader = (data) => {
	return {
		type: types.LOADER,
		payload: data,
	};
};
export const sendErrorReport = (data) => {
	return {
		type: types.SEND_ERROR_REPORT,
		payload: data,
	};
};
export const storeErrorInfo = (data) => {
	return {
		type: types.STORE_ERROR_INFO,
		payload: data,
	};
};
export const sendUserInfo = (data) => {
	return {
		type: types.SEND_USER_INFO,
		payload: data,
	};
};
export const getBrandData = (data) => {
	return {
		type: types.GET_BRAND_DATA,
		payload: data,
	};
};

export const addName = (data) => {
	return {
		type: types.ADD_NAME,
		payload: data,
	};
};
