import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getMenu } from "../../../redux/reducer/onboarding/selectors";

// assets
import styles from "./Card.module.scss";


export const Card = ({title, image, ratingData, setRatingData}) => {
  const stars = [
    {name: 'Great', rate: 5},
    {name: 'Awesome!', rate: 4},
    {name: 'Good', rate: 3},
    {name: 'Bad', rate: 2},
    {name: 'Poor', rate: 1},
  ];
  const filteredData = stars.filter(el => el.rate === ratingData.overall_ratings)
  console.log('filteredData :>> ', filteredData);
  const [activeStar, setActiveStar] = useState(filteredData && filteredData[0].name || 'Awesome!');

  const handleOnSelect = data => {
    setRatingData({...ratingData, overall_ratings: data.rate})
    setActiveStar(data.name)
  }
  const menu = useSelector(getMenu);
  const rest = menu && menu.restraunt_details && menu.restraunt_details;
  console.log('rest :>> ', menu);
  return (
    <div className={styles.card}>
      <div className={styles.description}>
        <div>
          {title && <h2>{title}</h2>}
          <h4>Rate your experience at <br />
            <span>{rest && rest.restraunt_name ? rest.restraunt_name : ''}</span>
          </h4>
        </div>
        <img src={image} alt='image' />
      </div>
      <div className={styles.rate}>
        <div className={styles.stars}>
          {stars.map((star, index) => (
            <span key={index} className={`${styles.star} ${star.name === activeStar && styles.active}`}
                  onClick={() => handleOnSelect(star)} />
          ))}
        </div>
        <p>{activeStar}</p>
      </div>
    </div>
  )
}
