import { call } from "@redux-saga/core/effects";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "./config";
import dayjs from "dayjs";
import request, { requestNoAuth } from "./request";
import notifySvg from "../assets/images/icons/Purple-in-Jacket.svg";

export const callApi = (url, data = null, type = "GET") => {
	let response;
	try {
		const requestformdata = {
			method: type,
			"Accept-Encoding": "gzip",
		};
		if (data) {
			requestformdata.body = JSON.stringify(data);
		}
		let apiUrl = baseUrl + url;
		apiUrl = apiUrl.replace(
			"{customerId}",
			JSON.parse(localStorage.getItem("customerId"))
		);
		apiUrl = apiUrl.replace(
			"{orderId}",
			JSON.parse(localStorage.getItem("orderId"))
		);
		apiUrl = apiUrl.replace(
			"{restrauntId}",
			JSON.parse(localStorage.getItem("restrauntId"))
		);
		response = call(request, apiUrl, requestformdata);
	} catch (error) {
		console.log("Api Error", error);
		return false;
	}
	return response;
};
export const callApiNoAuth = (url, data = null, type = "GET") => {
	let response;
	try {
		const requestformdata = {
			method: type,
		};
		if (data) {
			requestformdata.body = JSON.stringify(data);
		}

		let apiUrl = baseUrl + url;
		// apiUrl = apiUrl.replace(
		// 	"{customerId}",
		// 	JSON.parse(localStorage.getItem("customerId"))
		// );
		response = call(requestNoAuth, apiUrl, requestformdata);
	} catch (error) {
		console.log("Api Error", error);
		return false;
	}
	return response;
};
export const displayNotification = (message, type = "success") => {
	if (message === "500 Error") {
		return;
	}
	toast.configure({
		autoClose: 3000,
		draggable: false,
		hideProgressBar: true,
		newestOnTop: true,
		closeButton: false,
	});

	if (type === "success") {
		const content = (
			<React.Fragment>
				<span className="notification-text">{message}</span>
				<span className="img-toast">
					<img
						src={notifySvg}
						style={{ width: "57px" }}
						className="mr-2"
						alt="img"
					/>
				</span>
				{/* {message} */}
			</React.Fragment>
		);

		toast.success(content, {
			position: toast.POSITION.TOP_CENTER,
		});
	}
};

export const errorNotification = (message) => {
	toast.configure({
		autoClose: 3000,
		draggable: false,
		hideProgressBar: true,
		newestOnTop: true,
		closeButton: false,
	});

	const content = (
		<div>
			<img src={notifySvg} className="mr-2" alt="img" />
			<span className="montserrat-font">{message}</span>
		</div>
	);

	toast.error(content, {
		position: toast.POSITION.TOP_CENTER,
	});
};
export const convertDateTime = (timestamp, format) => {
	let formattedTime = "N/A";
	if (timestamp !== undefined) {
		// console.log("timestamp", timestamp);
		const dateString = dayjs().format(format);
		// const dateString = moment.unix(timestamp).format(format);
		formattedTime = dateString;
	}
	return formattedTime;
};
export const convertToUnix = (timestamp, format) => {
	let formattedTime = "N/A";
	if (timestamp !== undefined) {
		// console.log("timestamp", timestamp);
		const dateString = dayjs().unix(format);
		// const dateString = moment.unix(timestamp).format(format);
		formattedTime = dateString;
	}
	return formattedTime;
};
export const toTitleCase = (str) =>
	str &&
	str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
// const urlParams = new URLSearchParams(window.location.search);
// const phone = urlParams.get("mobile");
// const queryType = urlParams.get("type");
// const queryQR = urlParams.get("qr_code");
// const queryCR = urlParams.get("cr");
// const token = localStorage.getItem("token");
// queryType && localStorage.setItem("type", JSON.stringify(queryType));
// queryQR && localStorage.setItem("qrCode", JSON.stringify(queryQR));
// queryType && localStorage.setItem("type", queryType);
// queryCR && localStorage.setItem("country", queryCR);
// const qrCode = localStorage.getItem("qrCode");
// const customerIds = localStorage.getItem("customerId");
// const joinCode = localStorage.getItem("joinCode");

// const getType = localStorage.getItem("type");

// export const loginRouteValidation = () => {
//   if (token) {
//     if (qrCode) {
//       if (getType === "dine_in") {
//         if (!joinCode || !customerIds || customerIds === undefined) {
//           history.push(routes.join);
//         }
//       }
//     } else {
//       history.push(routes.register);
//     }
//   } else {
//     history.push(
//       queryType === undefined || queryType === null
//         ? routes.register
//         : queryCR //If country then url with cn name query otherwise without  it
//         ? `${routes.register}?type=${queryType}&cn=${queryCR}&qr_code=${queryQR}`
//         : `${routes.register}?type=${queryType}&qr_code=${queryQR}`
//     );
//   }
// };
export const currencySymbol = () => {
	// const country = 'india';
	const country = localStorage.getItem("country");
	if (country && country !== null && country.toLowerCase() == "aus") {
		return "$";
	} else {
		return "₹";
	}
};

export const removeSlash = (theString) => {
	return theString.replace(/^\/|\/$/g, "");
};
export const capitalizeWords = (string) => {
	return (
		string &&
		string.replace(/(?:^|\s)\S/g, function (a) {
			return a.toUpperCase();
		})
	);
};
