import React from "react";

// library
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";

// components
// import { EventSection } from "../../EventSection/EventSection";

// assets
import styles from "../../../common/modals/OrderModal/OrderModal.module.scss";
import { ReactComponent as WarningSvg } from "../../../../assets/images/icons/warning.svg";
import saly8 from "../../../../assets/images/saly-8.png";
import { currencySymbol } from "../../../../config/helper";
import { WrapperModal } from "../../../common/modals/WrapperModal/WrapperModal";
import { modalsActions } from "../../../../redux/modals/actions";
import { getOrderDetailsData, getSelectRatingData } from "../../../../redux/reducer/main/selectors";
import { useState } from "react";
import { getRatingData } from "../../../../redux/reducer/main/action";
import { Card, Wrong } from "../../../common";



const OverallReview = () => {
    const dispatch = useDispatch();
    const ordersData = useSelector(getOrderDetailsData);
    const select = useSelector(getSelectRatingData);
    const [ratingData, setRatingData] = useState({restraunt_id: ordersData.restraunt_id, overall_review: select.overall_review || '', overall_ratings: select.overall_ratings || 4});
    const handleData = () => {
      dispatch(getRatingData({rating_data: {...ratingData, restraunt_id: ordersData.restraunt_id}, status: 'editOverall'}));
      dispatch(modalsActions.setShowModal(''));
    }
    console.log('ratingData', ratingData)
  return (
    <WrapperModal blackIcon>
    <div className={styles.order}>
      
      <div className={styles.card}>
      <Card image={saly8} ratingData={ratingData} setRatingData={setRatingData} />
        {ratingData.overall_ratings ? <Wrong star={ratingData && ratingData.overall_ratings} ratingData={ratingData} setRatingData={setRatingData} /> : ''}
        <form >
          <label className="mb-0 less-box-shadow">
          <textarea name="rating" className="less-box-shadow" id="idTextarea"  placeholder={ratingData.overall_ratings <= 3 ? 
                    `Tell us what went wrong ☹️` :  `Tell us what you loved 🙂`} />
          </label>
          <button type='submit' className='btn btn-black button-bottom'>SUBMIT</button> 
        </form>
      </div>
      {/* <EventSection items={items} title='Explore Events ' /> */}
    </div>
  </WrapperModal>
  )
}

export default OverallReview;