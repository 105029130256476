/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

// library
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// components
import { WrapperModal, Card, Wrong } from "../../";
import { modalsActions } from "../../../../redux/modals/actions";
import { routes } from "../../../App/routes";

// assets
// import styles from "./RatingModal.module.scss";
import styles from "../../../common/modals/OrderModal/OrderModal.module.scss";
import success from "../../../../assets/images/success.png";
import { useState } from "react";
import { getOrderDetailsData } from "../../../../redux/reducer/main/selectors";
import { getRatingData } from "../../../../redux/reducer/main/action";
import history from "../../../../config/history";
import { useHistory } from "react-router";
import { getRouting } from "../../../../redux/reducer/onboarding/selectors";

export const RatingModal = () => {
	const dispatch = useDispatch();
	const ordersData = useSelector(getOrderDetailsData);
	const restrauntId = localStorage.getItem("restrauntId");
	// const [textarea, setTextarea] = useState(false);
	const [ratingData, setRatingData] = useState({
		restraunt_id: ordersData.restraunt_id || restrauntId,
		overall_review: "",
		overall_ratings: 4,
	});
	const hookHistory = useHistory();
	const route = useSelector(getRouting);
	const location = route && route.location && route.location.pathname;
	const pathName = location && location.split("/");
	console.log("pathName", pathName);
	const handleData = () => {
		if (`/${pathName[1]}` === routes.feedback) {
			// history.push(routes.feedback);
			dispatch(
				getRatingData({ rating_data: ratingData, status: "overallFeedback" })
			);
		} else {
			hookHistory.push(routes.orderRating);
			dispatch(getRatingData({ rating_data: ratingData, status: "overall" }));
		}
		// dispatch(getRatingData({rating_data: ratingData, status: 'overall'}));
		dispatch(modalsActions.setShowModal(""));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		handleData();
	};
	return (
		// <WrapperModal blackIcon disableMenu noFullScreen rating>
		//   <div className={styles.rating}>
		//     <Card title='Payment Successful!' image={success} ratingData={ratingData} setRatingData={setRatingData} />
		//     {ratingData.overall_ratings <= 3 ? <Wrong setTextarea={setTextarea} textarea={textarea} ratingData={ratingData} setRatingData={setRatingData} /> : ''}
		//   </div>
		//   <div className={styles.continue}>
		//     <a href="#" type='button' className='btn btn-primary'
		//           onClick={() => handleData()}>
		//       CONTINUE
		//     </a>
		//   </div>
		// </WrapperModal>
		<WrapperModal blackIcon>
			<div
				className={styles.order}
				style={{ height: "70vh", overflow: "auto" }}
			>
				<div className={styles.card}>
					<form onSubmit={handleSubmit}>
						<Card
							title="Payment Successful!"
							image={success}
							ratingData={ratingData}
							setRatingData={setRatingData}
						/>
						{ratingData.overall_ratings ? (
							<Wrong
								star={ratingData && ratingData.overall_ratings}
								ratingData={ratingData}
								setRatingData={setRatingData}
							/>
						) : (
							""
						)}

						<button type="submit" className="btn btn-black button-bottom">
							SUBMIT
						</button>
					</form>
				</div>
				{/* <EventSection items={items} title='Explore Events ' /> */}
			</div>
		</WrapperModal>
	);
};
