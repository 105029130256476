/* eslint-disable no-mixed-operators */
import React, { memo } from "react";

//library
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// components
import { AddButton } from "../AddButton/AddButton";
import { VeganStatus } from "../VeganStatus/VeganStatus";
import { modalsActions } from "../../../redux/modals/actions";

// assets
import styles from "./ListItems.module.scss";
import { ReactComponent as ArrowSvg } from "../../../assets/images/icons/arrow.svg";

// import edit from "../../../assets/images/icons/edit.svg";
// import minus from "../../../assets/images/icons/minus-circle.svg";
import plate from "../../../assets/images/icons/plate.svg";
import {
	getAddModalData,
	storeErrorInfo,
} from "../../../redux/reducer/onboarding/actions";
import { useSelector } from "react-redux";
import {
	getContainsEgg,
	getPlaceOrderSelector,
	getSelectVegOnly,
} from "../../../redux/reducer/main/selectors";
import { placeOrderData } from "../../../redux/reducer/main/action";
import { Instruction } from "../Instruction/Instruction";
// import LazyPlaceholder from "../LazyPlaceholder";
import LazyLoad from "react-lazyload";
import { capitalizeWords, currencySymbol } from "../../../config/helper";
import ErrorBoundary from "../ErrorBoundarie/ErrorBoundary";
import ImgLazyLoad from "../ImgLazyLoad";
// import history from "../../../config/history";

const ListItems = ({
	items,
	mod,
	toggle,
	list,
	instruction,
	setShow,
	categoryName,
	title,
	instructionAdded,
	setInstructionAdded,
	setShowAllergyInstructions,
	allergyShow,
	count,
	hideAddButton,
	subTitle,
	closeByDefault,
}) => {
	const dispatch = useDispatch();
	const getErrorDataFromBoundary = (data) => {
		dispatch(storeErrorInfo(data));
	};
	const getPlaceOrderData = useSelector(getPlaceOrderSelector);
	// const getPlaceOrderData = null;
	const vegOnlyState = useSelector(getSelectVegOnly);
	const containsEggState = useSelector(getContainsEgg);
	// const [instructionAdded, setInstructionAdded] = useState(false);
	const showItems = (e) => {
		e.target.parentElement.classList.toggle(styles.hide);
	};

	const addProduct = (data) => {
		dispatch(getAddModalData(data));
		// checking if item is already added or in the store or cart
		const foundData = getPlaceOrderData.filter(
			(o) => o.item_id === data.item_id
		);
		if (
			(foundData &&
				foundData.addon_details &&
				foundData.addon_details.length) ||
			(foundData.item_price_details && foundData.item_price_details.length > 1)
		) {
			dispatch(modalsActions.setShowModal("Repeat Item"));
		} else {
			dispatch(modalsActions.setShowModal("Add Product"));
		}
	};

	const handleAddItem = (data) => {
		// add directly
		const addonDetails = data && data.addon_details && data.addon_details;
		const pushedData = [];
		addonDetails &&
			addonDetails.length &&
			addonDetails.map((p) => pushedData.push(p.addon_items));
		const flatteredArray = pushedData && pushedData.length && pushedData.flat();
		const priceDetails =
			data && data.item_price_details && data.item_price_details;
		if (
			(priceDetails && priceDetails.length > 1) ||
			(flatteredArray && flatteredArray.length > 0)
		) {
			addProduct(data);
		} else {
			const itemPrice =
				(data &&
					data.item_price_details &&
					data.item_price_details.length &&
					data.item_price_details[0] &&
					data.item_price_details[0].item_price) ||
				0;
			const itemSize =
				data &&
				data.item_price_details &&
				data.item_price_details.length &&
				data.item_price_details[0] &&
				data.item_price_details[0].item_size;
			dispatch(
				placeOrderData(
					{ addons: [], item_id: data.item_id, item_size: itemSize, qty: 1 },
					{
						addons: [],
						item_name: data.item_name,
						item_id: data.item_id,
						item_size: itemSize,
						qty: 1,
						item_image_url: data ? data.item_image_url : plate,
						item_price: itemPrice,
						item_type: data.item_type,
					}
				)
			);
		}
	};

	const getVariant = (variant) => {
		if(!variant || variant === 'Regular'){
		  return ""
		}
		else {
		  return ` (${variant})`
		}
	  } 

	const onClickScroll = (id) => {
		document.body.style.overflow = "";
		if (document.getElementById(id)) {
			const element = document.getElementById(id);
			element && element.scrollIntoView({ behavior: "smooth" });
			// const headOffset = 116;
			// const elementPosition = element && element.offsetTop;
			// window.scrollTo({
			//   top:  elementPosition - headOffset,
			//   behavior: "smooth"
			// });
		}
	};

	return (
		<React.Fragment>
			<React.Fragment>
				<div
					// key={`parentDiv${index}`}
					className={`${styles.items} ${closeByDefault ? styles.hide : ""} ${
						mod ? styles.mod : ""
					}`}
				>
					{!list ? (
						items && items.length ? (
							items.map((item, index) => (
								// <LazyLoad height={71} overflow throttle={10} once>
								<React.Fragment>
									{toggle ? (
										<h4
											style={{ marginTop: "19px", marginBottom: "20px" }}
											className={`${styles.title} ${styles.toggle}`}
											onClick={(e) => {
												showItems(e);
												onClickScroll(
													categoryName &&
														categoryName.length &&
														categoryName[index]
												);
											}}
											id={
												categoryName &&
												categoryName.length &&
												capitalizeWords(categoryName[index])
											}
										>
											{categoryName &&
												categoryName.length &&
												categoryName[index]}
											<hr />
											<ArrowSvg />
										</h4>
									) : (
										<h4
											className={styles.title}
											id={
												categoryName &&
												categoryName.length &&
												capitalizeWords(categoryName[index])
											}
										>
											{categoryName &&
												categoryName.length &&
												categoryName[index]}
										</h4>
									)}
									{/* <LazyLoad height={71} overflow throttle={10} once> */}
									<div className={styles.panel}>
										{item &&
											item.length &&
											item.map((product, index) =>
												vegOnlyState === true ? (
													containsEggState === true ? (
														product.item_type !== "non-veg" && (
															// <LazyLoad height={71} overflow throttle={10} once>
																<ErrorBoundary
																	sendErrorData={getErrorDataFromBoundary}
																	itemDetails={product}
																	fallBack={null}
																>
																	<Link
																		key={index}
																		to={"#"}
																		className={styles.item}
																	>
																		{product.item_image_url && (
																			// <LazyLoad
																			// 	height={71}
																			// 	overflow
																			// 	placeholder={<ImgLazyLoad />}
																			// 	once
																			// >
																				<img
																					// loading="lazy"
																					src={product.item_image_url}
																					onClick={() => addProduct(product)}
																					alt={product.item_name}
																					width={71}
																					height={71}
																				/>
																			// </LazyLoad>
																		)}
																		<div className={styles.description}>
																			<h3 className={product && product.item_description && product.item_description.length ? styles.h3New :''}>{product.item_name}</h3>
																			{
																			product && product.item_description && product.item_description.length
																			&&
																			<p className={ product.item_image_url? styles.reducedWidth: ''}>
																				{product.item_description}
																			</p>
																			}
																			<div className={styles.info}>
																				<div
																					className={styles.price}
																					onClick={() => addProduct(product)}
																				>
																					<VeganStatus
																						type={product.item_type}
																						egg={product.contains_egg}
																					/>
																					<h4>
																						{currencySymbol()}
																						{product.item_price_details &&
																							product.item_price_details
																								.length &&
																							product.item_price_details[0]
																								.item_price}
																					</h4>
																				</div>
																				{getPlaceOrderData &&
																				getPlaceOrderData.length &&
																				getPlaceOrderData.find(
																					(o) => o.item_id === product.item_id
																				) ? (
																					<AddButton
																						count={
																							[...getPlaceOrderData]
																								.reverse()
																								.find(
																									(o) =>
																										o.item_id ===
																										product.item_id
																								).qty
																						}
																						itemData={product}
																						lastItem
																						id={product.item_id}
																					/>
																				) : (
																					<AddButton
																						id={product.item_id}
																						onClick={() =>
																							handleAddItem(product)
																						}
																					/>
																				)}
																			</div>
																		</div>
																	</Link>
																</ErrorBoundary>
															// </LazyLoad>
														)
													) : (
														product.item_type === "veg" &&
														product.contains_egg === false && (
															// <LazyLoad height={71} overflow throttle={10} once>
																<ErrorBoundary
																	sendErrorData={getErrorDataFromBoundary}
																	itemDetails={product}
																	fallBack={null}
																>
																	<Link
																		key={index}
																		to={"#"}
																		className={styles.item}
																	>
																		{product.item_image_url && (
																			// <LazyLoad
																			// 	height={71}
																			// 	overflow
																			// 	placeholder={<ImgLazyLoad />}
																			// 	once
																			// >
																				<img
																					// loading="lazy"
																					onClick={() => addProduct(product)}
																					src={product.item_image_url}
																					alt={product.item_name}
																					width={71}
																					height={71}
																				/>
																			// </LazyLoad>
																		)}
																		<div className={styles.description}>
																		<h3 className={product && product.item_description && product.item_description.length ? styles.h3New :''}>{product.item_name}</h3>
																		{
																		product && product.item_description && product.item_description.length
																		&&
																		<p className={ product.item_image_url? styles.reducedWidth: ''}>
																			{product.item_description}
																		</p>
																		}
																			<div className={styles.info}>
																				<div
																					className={styles.price}
																					onClick={() => addProduct(product)}
																				>
																					<VeganStatus
																						type={product.item_type}
																						egg={product.contains_egg}
																					/>
																					<h4>
																						{currencySymbol()}
																						{product.item_price_details &&
																							product.item_price_details
																								.length &&
																							product.item_price_details[0]
																								.item_price}
																					</h4>
																				</div>
																				{getPlaceOrderData &&
																				getPlaceOrderData.length &&
																				getPlaceOrderData.find(
																					(o) => o.item_id === product.item_id
																				) ? (
																					<AddButton
																						count={
																							[...getPlaceOrderData]
																								.reverse()
																								.find(
																									(o) =>
																										o.item_id ===
																										product.item_id
																								).qty
																						}
																						itemData={product}
																						lastItem
																						id={product.item_id}
																					/>
																				) : (
																					<AddButton
																						id={product.item_id}
																						onClick={() =>
																							handleAddItem(product)
																						}
																					/>
																				)}
																			</div>
																		</div>
																	</Link>
																</ErrorBoundary>
															// </LazyLoad>
														)
													)
												) : (
													// Main page
													// <LazyLoad key={index} >
													// <LazyLoad height={200} overflow placeholder={<LazyPlaceholder />}>
													// <LazyLoad height={71} overflow throttle={10} once>
														<ErrorBoundary
															sendErrorData={getErrorDataFromBoundary}
															itemDetails={product}
															fallBack={null}
														>
															<Link
																key={index}
																to={"#"}
																className={styles.item}
															>
																{product.item_image_url && (
																	// <LazyLoad
																	// 	height={71}
																	// 	overflow
																	// 	placeholder={<ImgLazyLoad />}
																	// 	once
																	// >
																		<img
																			// loading="lazy"
																			src={product.item_image_url}
																			onClick={() => addProduct(product)}
																			alt={product.item_name}
																			width={71}
																			height={71}
																		/>
																	// </LazyLoad>
																)}
																<div className={styles.description}>
																	<h3 onClick={() => addProduct(product)} className={product && product.item_description && product.item_description.length ? styles.h3New :''}>
																		{product.item_name}
																	</h3>
																	{
																	product && product.item_description && product.item_description.length
																	&&
																	<p className={ product.item_image_url? styles.reducedWidth: ''}>
																		{product.item_description}
																	</p>
																	}
																	<div className={styles.info}>
																		<div
																			className={styles.price}
																			onClick={() => addProduct(product)}
																		>
																			<VeganStatus
																				type={product.item_type}
																				egg={product.contains_egg}
																			/>
																			<h4>
																				{currencySymbol()}
																				{product.item_price_details &&
																					product.item_price_details.length &&
																					product.item_price_details[0]
																						.item_price}
																			</h4>
																		</div>
																		{getPlaceOrderData &&
																		getPlaceOrderData.length &&
																		getPlaceOrderData.find(
																			(o) => o.item_id === product.item_id
																		) ? (
																			<AddButton
																				count={
																					[...getPlaceOrderData]
																						.reverse()
																						.find(
																							(o) =>
																								o.item_id === product.item_id
																						).qty
																				}
																				itemData={product}
																				lastItem
																				id={product.item_id}
																			/>
																		) : (
																			<AddButton
																				id={product.item_id}
																				onClick={() => handleAddItem(product)}
																			/>
																		)}
																	</div>
																</div>
															</Link>
														</ErrorBoundary>
													// </LazyLoad>
												)
											)}
									</div>{" "}
									{/* </LazyLoad> */}
								</React.Fragment>

								// </LazyLoad>
							))
						) : (
							""
						)
					) : (
						<React.Fragment>
							{toggle ? (
								<React.Fragment>
									<h4
										className={`${styles.title} ${styles.toggle}`}
										style={{ marginBottom: 7 }}
										onClick={(e) => showItems(e)}
									>
										{title}
										<span className={styles.count}>{count}</span>
										<hr />
										<ArrowSvg />
									</h4>
								</React.Fragment>
							) : toggle ? (
								<h4
									// style={{marginTop: '19px'}}
									className={`${styles.title} ${styles.toggle}`}
									style={{ marginBottom: 7 }}
									onClick={(e) => {
										showItems(e);
									}}
									id={title}
								>
									{title}
									<hr />
									<ArrowSvg />
								</h4>
							) : (
								<h4 className={styles.title} id={title}>
									{title}
								</h4>
							)}
							{/* To show previous order */}
							<p
								className={`${hideAddButton ? styles.hide : ""} ${
									styles.subTitle
								}`}
								style={{ color: "#bbb" }}
							>
								{subTitle}
							</p>

							<div className={`${styles.panel} ${styles.mod}`}>
								{items && items.length
									? items.map((item, index) => (
											<ErrorBoundary
												sendErrorData={getErrorDataFromBoundary}
												itemDetails={item}
												fallBack={null}
											>
												{/* <LazyLoad key={`parentDiv${index}`} height={200}  placeholder={<LazyPlaceholder />}> */}
												<div
													key={`${index}_title`}
													id={item.item_name}
													className={styles.item}
												>
													<div className={styles.description}>
														<div className={styles.list}>
															<VeganStatus
																type={item.item_type}
																egg={item.contains_egg}
															/>
															<div>
																{item.item_price ? (
																	<h4>{item.item_name}</h4>
																) : (
																	<h4 style={{ marginBottom: 0 }}>
																		{item.item_name}
																	</h4>
																)}
																{item.item_price ? (
																	<div className={styles.info}>
																		<div className={styles.price}>
																			<h5>
																				{currencySymbol()}
																				{item.item_price}
																				{getVariant(item.item_size)}
																			</h5>
																		</div>
																	</div>
																) : (
																	""
																)}
															</div>
														</div>
														{!hideAddButton ? (
															getPlaceOrderData &&
															getPlaceOrderData.length &&
															getPlaceOrderData.find(
																(o) => o.item_id === item.item_id
															) ? (
																<AddButton
																	count={
																		getPlaceOrderData[index] &&
																		getPlaceOrderData[index].qty
																	}
																	index={index}
																	id={item.item_id}
																/>
															) : (
																<AddButton
																	id={item.item_id}
																	onClick={() => addProduct(item)}
																/>
															)
														) : (
															<h5 style={{ fontWeight: 500, marginRight: 20 }}>
																x {item.qty}
															</h5>
														)}
													</div>
												</div>
												{/* </LazyLoad> */}
											</ErrorBoundary>
									  ))
									: ""}
								<div>
									{instructionAdded && title !== "Previous order" ? (
										<Instruction
											setInstructionAdded={setInstructionAdded}
											title="Cooking instruction:"
											description=""
											setShow={setShow}
											black
										/>
									) : (
										instruction && (
											<div
												className={styles.link}
												onClick={() => setShow(true)}
											>
												Add cooking instruction
											</div>
										)
									)}
								</div>
							</div>
							{/* </div> */}
						</React.Fragment>
					)}
					{window.location.pathname === "/place-order" ? (
						<div onClick={(e) => showItems(e)}>
							{allergyShow ? (
								<span
									style={{
										display: "flex",
										padding: "4px",
										fontWeight: 600,
										fontSize: "14px",
									}}
								>
									<span style={{ display: "flex", padding: "5px" }}>
										Any allergies?
									</span>
									<div
										className="font-grey-14"
										onClick={() => setShowAllergyInstructions(true)}
									>
										Let us know.
									</div>
								</span>
							) : (
								""
							)}{" "}
						</div>
					) : (
						""
					)}
				</div>
			</React.Fragment>
		</React.Fragment>
	);
};

export default memo(ListItems);
