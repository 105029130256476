/* eslint-disable no-unused-expressions */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React from "react";

// library
import { useDispatch, useSelector } from "react-redux";

// components
import { PrimaryButton } from "../../../common/PrimaryButton/PrimaryButton";
import { VeganStatus } from "../../../common/VeganStatus/VeganStatus";
import { TopPanel } from "../../../common/TopPanel/TopPanel";
import { Link, withRouter } from "react-router-dom";
import { routes } from "../../../App/routes";
// import { modalsActions } from "../../../../redux/modals/actions";

// assets
import styles from "./Checkout.module.scss";
import coupons from "../../../../assets/images/icons/coupons.svg";
// import minus from "../../../../assets/images/icons/minus-circle.svg";
import editSvg from "../../../../assets/images/icons/edit.svg";
// import donate from "../../../../assets/images/icons/donate-heart.svg";
import { NoNavigationLayout } from "../../../common";
import { useEffect } from "react";
import {
	acknowledgePayment,
	loadCheckout,
	loadOrderDetails,
	loadQrDetails,
	loadRestrauntDetails,
	loadTip,
	placeOrder,
	placeOrderLocalData,
	toggleAddAddress,
} from "../../../../redux/reducer/main/action";
import {
	currentMenuTab,
	getOrderDetailsData,
	getPlaceOrderSelector,
	getRestrauntDetails,
	getSelectCoupons,
} from "../../../../redux/reducer/main/selectors";
// import { customerId } from "../../../../config/config";
import { useState } from "react";
import { DeliveryAddModal } from "../../../common/modals/DeliveryAddressModal/DeliveryAddModal";
import { currencySymbol, displayNotification } from "../../../../config/helper";
import { useDebouncedEffect } from "../../../../config/useDebouncedEffect";
import history from "../../../../config/history";
import {
	// loader,
	// loadGetTableCode,
	storeErrorInfo,
	// submitTableJoinCode,
} from "../../../../redux/reducer/onboarding/actions";
import { razorPayKey } from "../../../../config/config";
import ErrorBoundary from "../../../common/ErrorBoundarie/ErrorBoundary";
import Tip from "../../../common/Tip/Tip";

const Checkout = () => {
	const dispatch = useDispatch();
	const getErrorDataFromBoundary = (data) => {
		dispatch(storeErrorInfo(data));
	};
	const qrFlow = JSON.parse(localStorage.getItem("qrFlow"));
	const userData = useSelector(getOrderDetailsData);
	const placeOrders = useSelector(getPlaceOrderSelector);

	const getVariant = (variant) => {
		if(!variant || variant === 'Regular'){
		  return ""
		}
		else {
		  return ` (${variant})`
		}
	  } 
	

	useEffect(() => {
		const token = localStorage.getItem("token");
		const qrCode = localStorage.getItem("qrCode");
		const urlParams = new URLSearchParams(window.location.search);
		const orderId = urlParams.get("order_id");
		if (token && qrCode) {
			if (orderId) {
				localStorage.setItem("orderId", JSON.stringify(orderId));
				localStorage.setItem("whatsAppIntegration", true);
			}
			if (qrFlow === "prepaid") {
				if (placeOrders && placeOrders.length === 0) {
					const orderDetails = JSON.parse(localStorage.getItem("orderDetails"));
					const allergyDetails = JSON.parse(
						localStorage.getItem("allergyDetails")
					);
					dispatch(
						placeOrderLocalData({
							orderDetails: orderDetails || [],
							allergyDetails: allergyDetails || "",
						})
					);
				}

				console.log("userData", userData);
				JSON.stringify(userData) === "{}" ? dispatch(placeOrder()) : "";
				// dispatch(placeOrder());
			} else {
				dispatch(loadOrderDetails());
			}

			if (
				(userData && userData.closed_by_restraunt === true) ||
				(userData && userData.order_status === "completed")
			) {
				history.push(routes.orderRating);
				return displayNotification("Order has already been closed");
			} else {
				dispatch(loadRestrauntDetails());
			}
		} else if (orderId) {
			localStorage.setItem("orderId", JSON.stringify(orderId));
			// localStorage.setItem('whatsAppIntegration', true);
			dispatch(loadQrDetails(true));
		} else {
			if (orderId) {
				history.push(`${routes.register}?order_id=${orderId}`);
			} else {
				localStorage.clear();
				window.location = routes.register;
			}
		}
	}, [dispatch]);

	const [tip, setTip] = useState(0);
	// const handleClearTip = () => {
	//   if (document.getElementById("tipAmount")) {
	//     document.getElementById("tipAmount").value = "";
	//   }
	//   dispatch(loadTip(0));
	// };

	useDebouncedEffect(
		() => (tip > 0 ? dispatch(loadTip(parseInt(tip))) : ""),
		[tip],
		2000
	);
	const customerId = JSON.parse(localStorage.getItem("customerId"));

	const couponsData = useSelector(getSelectCoupons);
	const restrauntDetails = useSelector(getRestrauntDetails);
	const address1 =
		restrauntDetails &&
		restrauntDetails.restraunt_store_details &&
		restrauntDetails.restraunt_store_details &&
		restrauntDetails.restraunt_store_details.add1;
	const address2 =
		restrauntDetails &&
		restrauntDetails.restraunt_store_details &&
		restrauntDetails.restraunt_store_details &&
		restrauntDetails.restraunt_store_details.add2;
	const area =
		restrauntDetails &&
		restrauntDetails.restraunt_store_details &&
		restrauntDetails.restraunt_store_details &&
		restrauntDetails.restraunt_store_details.area;
	const city =
		restrauntDetails &&
		restrauntDetails.restraunt_store_details &&
		restrauntDetails.restraunt_store_details &&
		restrauntDetails.restraunt_store_details.city;
	const pincode =
		restrauntDetails &&
		restrauntDetails.restraunt_store_details &&
		restrauntDetails.restraunt_store_details &&
		restrauntDetails.restraunt_store_details.pincode;
	const address =
		address1 + ", " + address2 + ", " + area + ", " + city + ", " + pincode;

	const orderDetails = userData && userData.order_details;
	// const [description, setDescription] = useState({
	//   order_id: userData.order_id,
	//   payee_id: customerId, //payment_details.payee_customer_id
	//   created_on: userData.created_on,
	// });
	if (orderDetails) {
		var { order_amount, [customerId]: remove, ...restData } = orderDetails;
	}
	const runningData =
		restData && Object.values(restData).flatMap((data) => data);

	const restrauntDiscount =
		(userData &&
			userData.discount_details &&
			userData.discount_details.restraunt_discount_amount) ||
		0;
	const tipAmount =
		(userData && userData.tip_details && userData.tip_details.tip_amount) || 0;
	const couponDiscount =
		(userData &&
			userData.discount_details &&
			userData.discount_details.coupon_discount) ||
		0;
	const serviceChargeAmt =
		(userData &&
			userData.service_charge_amount &&
			userData.service_charge_amount) ||
		0;

	// razor pay
	const country = localStorage.getItem("country");
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://checkout.razorpay.com/v1/checkout.js";
		script.async = true;
		document.body.appendChild(script);
	}, []);
	// console.log('checkout description :>> ', description);
	const orderId = JSON.parse(localStorage.getItem("orderId"));
	const restrauntId = JSON.parse(localStorage.getItem("restrauntId"));
	const description = {
		order_id: orderId,
		payee_id: customerId,
		created_on: userData && userData.created_on && userData.created_on,
		restraunt_id: restrauntId,
	};
	if (qrFlow && qrFlow === "prepaid") {
		description.service = "prepaid";
		description.pre_paid_payment_id =
			userData && userData.pre_paid_payment_id && userData.pre_paid_payment_id;
	}
	let amount =
		userData &&
		userData.payment_details &&
		userData.payment_details.payment_amount; //Razorpay consider the amount in paise
	const options = {
		key: razorPayKey,
		amount: amount * 100, // 2000 paise = INR 20, amount in paisa
		name: "",
		description: JSON.stringify(description),
		order_id: "",
		currency: country && country.length && country == "aus" ? "AUD" : "INR",
		handler: (response) => {
			dispatch(acknowledgePayment(response.razorpay_payment_id));
			localStorage.removeItem("allergyDetails");
			localStorage.removeItem("orderDetails");
		},
		prefill: {
			name: "",
			email: "",
			contact: "",
		},
		notes: {
			address: "",
		},
		theme: {
			color: "#528ff0",
		},
	};

	// console.log("options", options);
	const [showFeedback, setShowFeedback] = useState(false);
	const [feedback, setFeedback] = useState("");
	const [selected] = useState();
	const type = JSON.parse(localStorage.getItem("type"));
	const customerDetails = userData && userData.customer_details;
	// const customerName = customerDetails && customerDetails.customer_name;
	const deliveryAddress = customerDetails && customerDetails.address;
	const openPayModal = () => {
		// dispatch(loadOrderDetails());
		if (!deliveryAddress && type === "pick_up_delivery") {
			displayNotification("Please submit your address");
		} else {
			if (qrFlow === "prepaid") {
				// const options = options;
				const amount =
					userData &&
					userData.payment_details &&
					userData.payment_details.payment_amount
						? userData.payment_details.payment_amount
						: 0;
				var rzp1 = new window.Razorpay({
					...options,
					amount: amount * 100,
				});
				rzp1.open();
			} else {
				dispatch(loadCheckout(options));
			}
		}
	};

	const handleOpenModal = () => {
		const onlinePayment = localStorage.getItem("onlinePayment");
		if (onlinePayment == "false") {
			displayNotification(
				"The restaurant has disabled online payments. Please ask your server for available payment modes."
			);
		} else {
			openPayModal();
		}
	};

	// console.log('otherOrdersKey :>> ', otherOrdersKey);
	// const country = localStorage.getItem("country");

	const approvalOrders =
		userData &&
		userData.approval_orders &&
		userData.approval_orders.map((e) => e.order_details);
	const pendingOrders = approvalOrders && approvalOrders.flat();
	const rejectedOrders =
		userData &&
		userData.rejected_orders &&
		userData.rejected_orders.map((e) => e.order_details);
	const rejectedItems = rejectedOrders && rejectedOrders.flat();
	const selectedMenu = useSelector(currentMenuTab);
	// const dineInApproval = JSON.parse(localStorage.getItem("dineInApproval"));
	return (
		<NoNavigationLayout>
			<main className={`${styles.placeOrder} make-scroll`}>
				<DeliveryAddModal
					showFeedback={showFeedback}
					selected={selected}
					complaint
					setShowFeedback={setShowFeedback}
					feedback={feedback}
					setFeedback={setFeedback}
				/>
				<TopPanel
					title="Checkout"
					noBorder
					route={selectedMenu ? selectedMenu : routes.special}
				/>
				<form className={styles.products}>
					{pendingOrders && pendingOrders.length ? (
						<div className={styles.order}>
							<h5 className={styles.title}>Pending Approval</h5>
							{pendingOrders.map((item, index) => (
								<div key={index} className={styles.item}>
									<h5 className="item-name-width">
										<VeganStatus
											type={item.item_type}
											egg={item.contains_egg}
										/>
										<div style={{display: 'flex', flexDirection: 'column'}}>
										<div>
											{item.item_name}
										</div>
										<div style={{fontWeight: 400}}>
										{getVariant(item.item_size)}
										</div>
										</div>
									</h5>
									<h5 className={styles.count}>x {item.qty}</h5>
									{/* <h5 style={{ width: "51px" }}>
                    {currencySymbol()}
                    {item.item_total_price}
                  </h5> */}
								</div>
							))}
						</div>
					) : (
						""
					)}
					{rejectedItems && rejectedItems.length ? (
						<div className={styles.order}>
							<h5 className={styles.title}>Rejected Order</h5>
							{rejectedItems.map((item, index) => (
								<div key={index} className={styles.item}>
									<h5 className="item-name-width">
										<VeganStatus
											type={item.item_type}
											egg={item.contains_egg}
										/>
										<div style={{display: 'flex', flexDirection: 'column'}}>
										<div>
											{item.item_name}
										</div>
										<div style={{fontWeight: 400}}>
										{getVariant(item.item_size)}
										</div>
										</div>
									</h5>
									<h5 className={styles.count}>x {item.qty}</h5>
									{/* <h5 style={{ width: "51px" }}>
                    {currencySymbol()}
                    {item.item_total_price}
                  </h5> */}
								</div>
							))}
						</div>
					) : (
						""
					)}
					<div className={styles.order}>
						<h5 className={styles.title}>Your Order</h5>
						{orderDetails &&
						orderDetails[customerId] &&
						orderDetails[customerId].length
							? orderDetails[customerId].map((item, index) => (
									<div key={index} className={styles.item}>
										<h5 className="item-name-width">
											<VeganStatus
												type={item.item_type}
												egg={item.contains_egg}
											/>
											<div style={{display: 'flex', flexDirection: 'column'}}>
											<div>
												{item.item_name}
											</div>
											<div style={{fontWeight: 400}}>
											{getVariant(item.item_size)}
											</div>
											</div>
										</h5>
										<h5 className={styles.count}>x {item.qty}</h5>
										<h5 style={{ width: "51px" }}>
											{currencySymbol()}
											{item.item_total_price}
										</h5>
									</div>
							  ))
							: ""}
					</div>
					{runningData && runningData.length ? (
						<div className={styles.order}>
							<h5 className={styles.title}>Friend’s Order</h5>
							{runningData.map((item, index) => (
								<div key={index} className={styles.item}>
									<h5 className="item-name-width">
										<VeganStatus
											type={item.item_type}
											egg={item.contains_egg}
										/>
										<div style={{display: 'flex', flexDirection: 'column'}}>
										<div>
											{item.item_name}
										</div>
										<div style={{fontWeight: 400}}>
										{getVariant(item.item_size)}
										</div>
										</div>
									</h5>
									<h5 className={styles.count}>x {item.qty}</h5>
									<h5 style={{ width: "51px" }}>
										{currencySymbol()}
										{item.item_total_price}
									</h5>
								</div>
							))}
						</div>
					) : (
						""
					)}
					<Link to="#" className={styles.coupons}>
						<img src={coupons} alt="coupons" />
						{userData &&
						userData.discount_details &&
						userData.discount_details.coupon_discount ? (
							<div className={styles.description}>
								<h5>Coupon applied</h5>
								<h6>
									{userData.discount_details &&
										userData.discount_details.coupon_title &&
										userData.discount_details.coupon_title}
								</h6>
							</div>
						) : (
							<div className={styles.description}>
								<h5>Coupons</h5>
								<h6>
									{couponsData && couponsData.length
										? "Click here to apply coupons"
										: "No coupons for now"}
								</h6>
							</div>
						)}
					</Link>
					{type !== "dine_in" && type !== "checkout" ? (
						<div className="address-section">
							<h5 className="address-title">Address</h5>
							<div className="address-displayBody">
								<p className="address-name">
									{type === "pick_up_delivery"
										? "Please enter your address here"
										: "Pick up your order from this address"}
								</p>

								{type === "pick_up_delivery" ? (
									<span>
										<img
											src={editSvg}
											alt="edit"
											onClick={() => {
												dispatch(toggleAddAddress(true));
											}}
											className={styles.minus}
										/>
									</span>
								) : (
									""
								)}
							</div>
							<p className="full-address">
								{type === "pick_up" ? address : deliveryAddress || ""}
							</p>
						</div>
					) : (
						""
					)}
					{type === "dine_in" ? (
						<div className={styles.waiter}>
							<Tip tipAmount={tipAmount} styles={styles} setTip={setTip} />
						</div>
					) : (
						""
					)}
					{/* this is bill area */}

					<div className={styles.total}>
						<h5>
							Item Total
							<span>
								{currencySymbol()}
								{orderDetails && orderDetails.order_amount}
							</span>
						</h5>
						{couponDiscount > 0 ? (
							<h6>
								Coupon Discount
								<span className={styles.green}>
									{currencySymbol()}
									{couponDiscount}
								</span>
							</h6>
						) : (
							""
						)}
						{restrauntDiscount > 0 ? (
							<h6>
								Restaurant Discount
								<span className={styles.green}>
									{currencySymbol()}
									{restrauntDiscount}
								</span>
							</h6>
						) : (
							""
						)}
						{serviceChargeAmt > 0 ? (
							<h6>
								Service charge
								<span>
									{currencySymbol()}
									{serviceChargeAmt}
								</span>
							</h6>
						) : (
							""
						)}
						{userData &&
							userData.tax_details &&
							userData.tax_details.map((tax, index) =>
								tax.tax_amount > 0 ? (
									<ErrorBoundary
										sendErrorData={getErrorDataFromBoundary}
										itemDetails={tax}
										fallBack={null}
									>
										<h6 key={`tax${index}`}>
											{tax.tax_name}
											<span>
												{currencySymbol()}
												{tax.tax_amount}
											</span>
										</h6>
									</ErrorBoundary>
								) : (
									""
								)
							)}
						{tipAmount > 0 ? (
							<h6>
								Tip
								<span className={styles.green}>
									{currencySymbol()}
									{tipAmount}.00
								</span>
							</h6>
						) : (
							""
						)}
						{userData && userData.delivery_fee > 0 ? (
							<h6>
								Delivery charges
								<span className={styles.green}>
									{currencySymbol()}
									{userData && userData.delivery_fee}
								</span>
							</h6>
						) : (
							""
						)}
						{userData && userData.packing_charges > 0 ? (
							<h6>
								Packing charges
								<span className={styles.green}>
									{currencySymbol()}
									{userData && userData.packing_charges}
								</span>
							</h6>
						) : (
							""
						)}
						<h4>
							Grand Total
							<span>
								{currencySymbol()}
								{userData &&
									userData.payment_details &&
									userData.payment_details.payment_amount}
							</span>
						</h4>
					</div>
					<PrimaryButton
						checkoutButton
						disabled
						onClick={() => handleOpenModal()}
						title="MAKE PAYMENT"
						info={`${currencySymbol()}${
							userData &&
							userData.payment_details &&
							userData.payment_details.payment_amount
						}`}
						white
					/>
				</form>
			</main>
		</NoNavigationLayout>
	);
};
export default withRouter(React.memo(Checkout));
