import produce from "immer";
import { types } from "./types";

export const initialState = {
	menu: null,
	getTableCode: null,
	addModalData: {},
	loader: false,
	error: null,
	brandDetails: [],
};

/* eslint-disable default-case, no-param-reassign */
const onboardingReducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.GET_TABLE_JOIN_DETAILS:
				draft.menu = action.payload;
				break;
			case types.GET_TABLE_CODE:
				draft.getTableCode = action.payload;
				break;
			case types.GET_ADD_MODAL_DATA:
				draft.addModalData = action.payload;
				break;
			case types.LOADER:
				draft.loader = action.payload;
				break;
			case types.STORE_ERROR_INFO:
				draft.error = action.payload;
				break;
			case types.GET_BRAND_DATA:
				draft.brandDetails = action.payload;
				break;
		}
	});

export default onboardingReducer;
