/* eslint-disable no-useless-concat */
import { takeEvery, call, put, select } from "redux-saga/effects";
import { types } from "../reducer/main/types";
import { apiUrls } from "../../config/apiUrls";
import {
	callApi,
	callApiNoAuth,
	displayNotification,
} from "../../config/helper";
import {
	getCoupons,
	getOrderDetails,
	getOrderDetailsPrepaid,
	getRestrauntDetails,
	loadOrderDetails,
	loadPlacePickupDelivery,
	placeOrderData,
	placeOrderLocalData,
	toggleAddAddress,
} from "../reducer/main/action";
import {
	currentMenuTab,
	getAllergyDetails,
	getOrderDetailsData,
	getPlaceOrderSelector,
	getSelectRatingData,
} from "../reducer/main/selectors";
import { modalsActions } from "../modals/actions";
import history from "../../config/history";
import { routes } from "../../components/App/routes";
import { upload } from "../../config/request";
import { baseUrl } from "../../config/config";
import {
	getBrandData,
	getTableJoinDetails,
	loader,
	loadGetTableCode,
	loadTableJoinDetails,
	sendUserInfo,
} from "../reducer/onboarding/actions";
import { getRouting } from "../reducer/onboarding/selectors";

function* getOrderDetailsSaga(action) {
	yield put(loader(true));
	if (!JSON.parse(localStorage.getItem("orderId"))) {
		yield put(loader(false));
		return;
	}
	const qrFlow = JSON.parse(localStorage.getItem("qrFlow"));
	try {
		const response = yield callApi(apiUrls.getOrderDetails);
		if (response && response.success) {
			// this if is for checking if order is not closed then redirect him to menu
			if (
				action.payload === "checkAuth" &&
				response.data &&
				response.data.order_status !== "completed"
			) {
				history.push(routes.special);
				yield put(loader(false));
			} else if (action.status === "razorPay") {
				// to open razor pay modal to make payments int his action.payload is coming from checkout api
				const amount =
					response.data &&
					response.data.payment_details &&
					response.data.payment_details.payment_amount;
				const options = action.payload;
				var rzp1 = new window.Razorpay({
					...options,
					amount: amount * 100,
				});
				rzp1.open();
				// yield put(loader(false));
			} else if (action.payload === "orderPay") {
				if (
					response.data &&
					(response.data.order_status === "occupied" ||
						response.data.order_status === null)
				) {
					history.push(routes.special);
				} else if (response.data.order_status === "completed") {
					console.log("object :>> ");
					localStorage.removeItem("joinCode");
					localStorage.removeItem("customerId");
					localStorage.removeItem("qrFlow");
					localStorage.removeItem("orderDetails");
					localStorage.removeItem("driveIn");
					localStorage.removeItem("allergyDetails");
					history.push(routes.register);
				} else {
					history.push(routes.whatsappIntegration);
				}
			} else if (
				action.payload === "checkOrderStatus" &&
				response.data.order_status !== "completed"
			) {
				const qrCode = JSON.parse(localStorage.getItem("qrCode"));
				yield put(loadTableJoinDetails(qrCode));
			} else if (
				action.payload === "checkOrderStatus" &&
				response.data.order_status === "completed"
			) {
			} else {
			}
			if (
				localStorage.getItem("restrauntId") === undefined ||
				JSON.parse(localStorage.getItem("restrauntId") === undefined)
			) {
				localStorage.setItem(
					"restrauntId",
					JSON.stringify(response.data.restraunt_id)
				);
			}
			if (qrFlow === "prepaid") {
				yield put(getOrderDetailsPrepaid(response.data));
			}
			if (qrFlow !== "prepaid") {
				yield put(getOrderDetails(response.data));
			} else if (
				response.data.order_status === "completed" &&
				(routes.special ||
					routes.beverages ||
					routes.checkout ||
					routes.main ||
					routes.desserts ||
					routes.drinks)
			) {
				localStorage.removeItem("joinCode");
				localStorage.removeItem("customerId");
				localStorage.removeItem("qrFlow");
				localStorage.removeItem("orderDetails");
				localStorage.removeItem("driveIn");
				localStorage.removeItem("allergyDetails");
				localStorage.removeItem("token");
				displayNotification("Please scan the qr");
				history.push(routes.register);
			}

			yield put(loader(false));
		} else {
			yield put(loader(false));
		}
	} catch (error) {
		console.log(`error`, error);
		yield put(loader(false));
	}
}
function* placeOrderSaga() {
	yield put(loader(true));
	const orderId = localStorage.getItem("orderId");
	if (
		orderId === "undefined" ||
		orderId === undefined ||
		orderId === "null" ||
		orderId === null
	) {
		return yield put(loadGetTableCode("placeOrderApi"));
	}
	try {
		const order_details = yield select(getPlaceOrderSelector);
		const allergyDetails = yield select(getAllergyDetails);
		const qrCode = JSON.parse(localStorage.getItem("qrCode"));
		const type = JSON.parse(localStorage.getItem("type"));
		let apiUrl = apiUrls.placeOrderDineIn;
		const qrFlow = JSON.parse(localStorage.getItem("qrFlow"));
		if (qrFlow === "prepaid") {
			apiUrl = `/orders/customer/{customerId}/restraunt/{restrauntId}/dine_in/price`;
		} else if (type === "pick_up") {
			apiUrl = apiUrls.placePickup;
		} else if (type === "pick_up_delivery") {
			apiUrl = apiUrls.placeDelivery;
		}
		const payloadData = {
			order_details: order_details,
			qr_code: qrCode,
			customize_item:
				allergyDetails === "" ? "" : `ALLERGIES: ${allergyDetails}`,
		};
		const orderType = JSON.parse(localStorage.getItem("type"));
		const response = yield callApi(apiUrl, payloadData, "POST");
		if (response && response.success) {
			yield put(loader(false));
			const rejectedItems =
				response &&
				response.data &&
				response.data.items &&
				response.data.items.item_not_available &&
				response.data.items.item_not_available;
			// For showing rejected Items
			if (rejectedItems && rejectedItems.length) {
				const allRejectedItems = [];
				rejectedItems.map((el) => allRejectedItems.push(el.item_name));
				displayNotification(`Items Rejected: ${allRejectedItems.join(", ")}`);
			}
			// yield put(getOrderDetails(response.data));
			if (qrFlow === "prepaid") {
				localStorage.setItem("orderDetails", JSON.stringify(order_details));
				localStorage.setItem("allergyDetails", JSON.stringify(allergyDetails));
				yield put(
					placeOrderLocalData({
						orderDetails: [],
						allergyDetails: "",
						type: "empty",
					})
				);
				// to get orders data inside store
				yield put(getOrderDetails(response.data));
				yield put(loadOrderDetails("isCompleted"));

				history.push(routes.checkout);
			} else if (qrFlow !== "prepaid" && orderType === "dine_in") {
				yield put(modalsActions.setShowModal("Order"));
				yield put(placeOrderData("empty"));
				displayNotification(
					`Order Successfully Placed, Your order will be reviewed and served to you shortly.`
				);
			} else {
				history.push(routes.checkout);
			}
		} else {
			yield put(loader(false));
			if (
				response.message ===
				"Sorry, this order is closed. Please scan a QR code and place a new order"
			) {
				history.push(routes.orderRating);
			}
			displayNotification(response.message || response.data.error);
		}
	} catch (error) {
		yield put(loader(false));
		console.log(`error`, error);
	}
}

function* couponsDataSaga() {
	yield put(loader(true));
	try {
		const qrCode = JSON.parse(localStorage.getItem("qrCode"));
		const payloadData = { qr_code: qrCode };
		const response = yield callApi(apiUrls.getCouponsData, payloadData, "POST");
		if (response && response.data && response.data.length) {
			yield put(getCoupons(response.data));
			yield put(loader(false));
			// history.push(routes.coupons);
		} else {
			yield put(loader(false));

			displayNotification("Coupons are not available");
		}
	} catch (error) {
		yield put(loader(false));
		console.log(`error`, error);
	}
}
function* applyCouponSaga(action) {
	yield put(loader(true));
	try {
		const qrCode = JSON.parse(localStorage.getItem("qrCode"));
		const payloadData = { qr_code: qrCode, coupon_id: action.payload };
		const response = yield callApi(apiUrls.applyCouponApi, payloadData, "POST");
		if (response && response.data && response.data.status) {
			yield put(loader(false));
			// yield put(getCoupons(response.data));
			history.push(routes.checkout);
		} else {
			yield put(loader(false));
			displayNotification("Coupons are not available");
		}
	} catch (error) {
		yield put(loader(false));
		console.log(`error`, error);
	}
}
function* tipSaga(action) {
	yield put(loader(true));
	try {
		const payloadData = { tip_amount: action.payload };
		const response = yield callApi(apiUrls.tipApi, payloadData, "POST");
		if (response && response) {
			// yield put(getCoupons(response.data));
			yield put(loader(false));
			yield put(loadOrderDetails());
		}
	} catch (error) {
		yield put(loader(false));
		console.log(`error`, error);
	}
}
function* loadCheckoutSaga(action) {
	yield put(loader(true));

	try {
		const qrCode = JSON.parse(localStorage.getItem("qrCode"));
		const payloadData = { qr_code: qrCode, payment_type: "online" };
		const payloadData2 = { payment_type: "online" };
		// const orderDetails = yield select(getOrderDetailsData);
		const type = JSON.parse(localStorage.getItem("type"));
		let response;
		if (type === "dine_in" || type === "checkout") {
			response = yield callApi(apiUrls.checkoutPayment, payloadData, "POST");
		} else if (type === "pick_up" || type === "pick_up_delivery") {
			response = yield callApi(apiUrls.initiatePayment, payloadData2, "POST");
		}
		if (response && response.data && response.data.status === "success") {
			const qrFlow = JSON.parse(localStorage.getItem("qrFlow"));
			if (qrFlow !== "prepaid") {
				yield put(loadOrderDetails(action.payload, "razorPay"));
			}
			// else {
			// }
		} else {
			history.push(routes.orderRating);
			displayNotification(response.message);
			yield put(loader(false));
		}
	} catch (error) {
		yield put(loader(false));
		console.log(`error`, error);
	}
}
function* acknowledgePaymentSaga(action) {
	yield put(loader(true));
	// const selectedMenu = yield select(currentMenuTab);
	try {
		const payloadData = {
			razorpay_payment_id: action.payload,
			payment_status: true,
		};
		const type = JSON.parse(localStorage.getItem("type"));
		let response;
		if (type === "dine_in") {
			response = yield callApi(apiUrls.acknowledgePayment, payloadData, "POST");
		} else if (type === "pick_up" || type === "pick_up_delivery") {
			yield put(placeOrderData("empty"));
			response = yield callApi(
				apiUrls.acknowledgePayment + "/payment",
				payloadData,
				"POST"
			);
		}

		if (response && response.data && response.data.status === "success") {
			yield put(loader(false));
			if (type !== "dine_in") {
				yield put(loadPlacePickupDelivery());
			} else {
				const qrFlow = JSON.parse(localStorage.getItem("qrFlow"));
				if (qrFlow === "prepaid") {
					yield put(
						placeOrderLocalData({
							allergyDetails: "",
							orderDetails: [],
							type: "empty",
						})
					);
					yield put(getOrderDetails({}));
					localStorage.removeItem("orderDetails");
					localStorage.removeItem("allergyDetails");
					displayNotification("Your order has been placed");
					// yield put(getOrderDetails());
					// if (selectedMenu) {
					// 	// history.push(`/${selectedMenu}`);
					// } else {
					// 	history.push(routes.special);
					// }
					history.push(routes.orderPlaced);
					// yield put(modalsActions.setShowModal("Order"));
				} else {
					localStorage.removeItem("whatsAppIntegration");
					yield put(modalsActions.setShowModal("Rating"));
				}
			}
		}
	} catch (error) {
		yield put(loader(false));
		console.log(`error`, error);
	}
}
function* submitRatingDataSaga(action) {
	try {
		yield put(loader(true));
		const review = yield select(getSelectRatingData);
		const runningOrderData = yield select(getOrderDetailsData);

		const orderDetails = runningOrderData && runningOrderData.order_details;
		if (orderDetails) {
			var { order_amount, ...restData } = orderDetails;
		}
		const runningData =
			restData && Object.values(restData).flatMap((data) => data);
		const arrData = [];

		if (!review.items_reviews) {
			runningData &&
				runningData.length &&
				runningData.map((el) =>
					arrData.push({
						item_rating: null,
						item_id: el.item_id,
						item_order_id: el.item_order_id,
						item_review: "",
						item_name: el.item_name,
						item_type: el.item_type,
						contains_egg: el.contains_egg,
					})
				);
			// review.item_reviews = arrData;
		}
		const newObj = { ...review, items_reviews: arrData };
		if (!review.overall_ratings) {
			yield put(loader(false));
			yield put(modalsActions.setShowModal("Rating Update"));
			return displayNotification("Please rate your experience");
		}
		let form = !review.items_reviews ? { newObj } : { review };
		const formData = new FormData();
		for (const key in form) {
			formData.append("review", JSON.stringify(form[key]));
		}
		// console.log("formData :>> ", form);
		// return;
		const apiUrl =
			baseUrl +
			`/reviews/${JSON.parse(
				localStorage.getItem("customerId")
			)}/orders/${JSON.parse(localStorage.getItem("orderId"))}`;

		// const response = yield callApi(apiUrls.submitRatingData, formData, "POST");
		const response = yield call(upload, apiUrl, {
			method: "POST",
			body: formData,
		});
		const type = JSON.parse(localStorage.getItem("type"));
		if (response && response.data && response.data.status === "SUCCESS") {
			yield put(loader(false));
			if (type !== "dine_in" && type !== "checkout") {
				history.push(routes.orderDetails);
			} else {
				history.push(routes.thankYouScreen);
			}
			// yield put(loadOrderDetails());
		} else {
			yield put(loader(false));
			displayNotification(response.message);
		}
	} catch (error) {
		yield put(loader(false));
		console.log(`error`, error);
	}
}

function* loadRestrauntDetailsSaga(action) {
	try {
		yield put(loader(true));
		const response = yield callApi(apiUrls.restrauntDetails);
		if (response && response.data) {
			if (!action.payload) {
				yield put(loader(false));
			}
			if (
				response &&
				response.data &&
				response.data.restraunt_store_details &&
				response.data.restraunt_store_details.online_payment
			) {
				localStorage.setItem(
					"onlinePayment",
					response.data.restraunt_store_details.online_payment
				);
			}

			yield put(getRestrauntDetails(response.data));
		} else {
			yield put(loader(false));
		}
	} catch (error) {
		yield put(loader(false));
		console.log("error", error);
	}
}
function* submitComplaintSaga(action) {
	try {
		yield put(loader(true));
		const qrCode = JSON.parse(localStorage.getItem("qrCode"));
		const complaints = { complaints: [action.payload], qr_code: qrCode };
		const response = yield callApi(apiUrls.complaints, complaints, "POST");
		if (response && response.data) {
			yield put(loader(false));
			displayNotification("Your complaint is successfully registered");
		} else {
			yield put(loader(false));
			displayNotification(response && response.message);
		}
	} catch (error) {
		yield put(loader(false));
		console.log("error", error);
	}
}
function* loadMenuDataSaga(action) {
	yield put(loader(true));
	try {
		const orderType = JSON.parse(localStorage.getItem("type"));
		const qrCode = JSON.parse(localStorage.getItem("qrCode"));
		const payload = { qr_code: qrCode };
		let apiUrl = apiUrls.pickUpMenuData;
		if (orderType === "pick_up") {
			apiUrl = apiUrls.pickUpMenuData;
		} else if (orderType === "pick_up_delivery") {
			apiUrl = apiUrls.deliveryMenuData;
		}

		const response = yield callApi(apiUrl, payload, "POST");
		if (response && response.data) {
			yield put(loader(false));
			yield put(getTableJoinDetails(response.data.menu));
			console.log("first", response.data);
			localStorage.setItem(
				"driveIn",
				response.data.drive_in ? response.data.drive_in : false
			);
			localStorage.setItem(
				"customerPlaceOrder",
				response.data.customerPlaceOrder
					? response.data.customerPlaceOrder
					: true
			);
			if (response && response.data.drive_in) {
				yield put(getBrandData(response.data.brand_map));
				history.push(routes.driveIn);
			}

			if (response.data.menu && response.data.menu.restraunt_details) {
				localStorage.setItem(
					"restrauntId",
					JSON.stringify(response.data.menu.restraunt_details.restraunt_id)
				);
			}
			localStorage.setItem(
				"orderId",
				JSON.stringify(
					response &&
						response.data &&
						response.data.order_id &&
						response.data.order_id
				)
			);
			localStorage.setItem(
				"schedule",
				JSON.stringify(
					response &&
						response.data &&
						response.data.scheduled_delivery &&
						response.data.scheduled_delivery
				)
			);
			yield put(loadOrderDetails());
		} else if (response.message === "Incorrect padding") {
			localStorage.clear();
			window.location.reload();
			yield put(loader(false));
			displayNotification("response.message");
		}
	} catch (error) {
		yield put(loader(false));
		console.log("error", error);
	}
}

function* requestServiceSaga(action) {
	yield put(loader(true));
	try {
		const payloadData = action.payload;
		const response = yield callApi(apiUrls.customizeOrder, payloadData, "POST");
		if (response && response.data && response.data.status === "success") {
			yield put(loader(false));
			displayNotification("The request has been successfully submitted");
			// yield put(modalsActions(""));
		} else {
			yield put(loader(false));
			displayNotification(response && response.message);
		}
	} catch (error) {
		yield put(loader(false));
		console.log("error", error);
	}
}
function* placePickUpDeliveryOrder(action) {
	try {
		yield put(loader(true));
		// const nullData = null;
		const type = JSON.parse(localStorage.getItem("type"));
		let apiUrl = apiUrls.placePickupDelivery;
		if (type === "pick_up") {
			apiUrl = apiUrls.placePickupDelivery + type + "/place";
		} else {
			apiUrl = apiUrls.placePickupDelivery + "delivery" + "/place";
		}
		const response = yield callApi(apiUrl, {}, "POST");
		if (response && response.data && response.data.status === "success") {
			yield put(loader(false));
			// yield put(modalsActions.setShowModal("Rating"));
			history.push(routes.orderDetails);
		} else {
			yield put(loader(false));
			displayNotification(response && response.message);
		}
	} catch (error) {
		yield put(loader(false));
		console.log("error", error);
	}
}
function* submitAddressSaga(action) {
	try {
		yield put(loader(true));
		const payloadData = {
			address: action.payload,
			lat: "",
			lon: "",
			type: "pick_up_delivery",
		};
		// const type = JSON.parse(localStorage.getItem("type"));
		const response = yield callApi(apiUrls.addAddress, payloadData, "POST");
		if (response && response.data && response.data.status === "success") {
			displayNotification("Success");
			yield put(loader(false));
			yield put(loadOrderDetails());
			yield put(toggleAddAddress(false));
		} else {
			yield put(loader(false));
			displayNotification(response && response.message);
		}
	} catch (error) {
		yield put(loader(false));
		console.log("error", error);
	}
}
function* getQrCodeSaga(action) {
	try {
		const token = localStorage.getItem("token");
		const response = yield callApi(apiUrls.tableDetails);
		const route = yield select(getRouting);
		const query = route && route.location && route.location.query;
		if (response && response.data && response.data.message === "success") {
			yield put(loader(false));
			localStorage.setItem(
				"qrCode",
				JSON.stringify(response.data.data[0].qr_enc)
			);
			localStorage.setItem(
				"restrauntId",
				JSON.stringify(response.data.data[0].restraunt_id)
			);
			localStorage.setItem("onlinePayment", true);

			if (action.payload === "toCheckout" && token) {
				history.push(`${routes.checkout}?order_id=${query.order_id}`);
			}
			// else if (action.payload === "toCheckoutReload") {
			//   window.location.reload();
			// }
		} else {
			yield put(loader(false));

			history.push(routes.register);
			displayNotification(response && response.message);
		}
	} catch (error) {
		yield put(loader(false));
		console.log("error", error);
	}
}
function* getFeedbackData() {
	try {
		const route = yield select(getRouting);
		const location = route && route.location && route.location.pathname;
		const uniqueId = location.substring(
			location.lastIndexOf("/") + 1,
			location.length
		);
		// return;
		const url = `${apiUrls.getFeedbackApi}/${uniqueId}`;
		console.log("uniqueId", url);
		const response = yield callApiNoAuth(url);

		if (response && response.data && response.data.order_id) {
			yield put(getTableJoinDetails({ restraunt_details: response.data }));
			localStorage.setItem(
				"restrauntId",
				JSON.stringify(response.data && response.data.restraunt_id)
			);
			localStorage.setItem(
				"orderId",
				JSON.stringify(response.data && response.data.order_id)
			);
			localStorage.setItem(
				"customerId",
				JSON.stringify(response.data && response.data.customer_id)
			);

			yield put(getOrderDetails(response.data));
			yield put(sendUserInfo("feedback_landing"));
			yield put(loader(false));
		} else {
			yield put(loader(false));
			yield put(modalsActions.setShowModal(""));
			history.push(`${routes.thankYouScreen}?status=feedback`);
		}
	} catch (error) {
		yield put(loader(false));
		console.log("error", error);
	}
}

function* submitFeedbackDataSaga(action) {
	try {
		// yield put(loader(true));
		const review = yield select(getSelectRatingData);
		const ordersData = yield select(getOrderDetailsData);

		const runningOrderData = yield select(getOrderDetailsData);

		const orderDetails =
			runningOrderData &&
			runningOrderData.order_details &&
			runningOrderData.order_details.length
				? runningOrderData.order_details[0].order_details
				: { order_amount: 0, ab: [] };
		if (orderDetails) {
			var { order_amount, ...restData } = orderDetails;
		}
		const runningData =
			restData && Object.values(restData).flatMap((data) => data);
		const arrData = [];

		if (!review.items_reviews) {
			runningData &&
				runningData.length &&
				runningData.map((el) =>
					arrData.push({
						item_rating: null,
						item_id: el.item_id,
						item_order_id: el.item_order_id,
						item_review: "",
						item_name: el.item_name,
						item_type: el.item_type,
						contains_egg: el.contains_egg,
					})
				);
		}
		const feedBackId = ordersData && ordersData.feedback_id;
		const newObj = { ...review, items_reviews: arrData };
		if (!review.overall_ratings) {
			yield put(loader(false));
			yield put(modalsActions.setShowModal("Rating Update"));
			return displayNotification("Please rate your experience");
		}
		let form = !review.items_reviews ? { newObj } : { review };
		// return;
		console.log("form", form);
		const formData = new FormData();
		for (const key in form) {
			formData.append("review", JSON.stringify(form[key]));
		}
		// console.log("formData :>> ", form);
		// return;
		const apiUrl = baseUrl + `/reviews/feedback/${feedBackId}`;

		// const response = yield callApi(apiUrls.submitRatingData, formData, "POST");
		// return;
		const response = yield call(upload, apiUrl, {
			method: "POST",
			body: formData,
		});
		if (response && response.success) {
			yield put(loader(false));
			yield put(sendUserInfo("feedback_given"));
			history.push(routes.thankYouScreen);
			// yield put(loadOrderDetails());
		} else {
			yield put(loader(false));
			displayNotification("Success");
		}
	} catch (error) {
		yield put(loader(false));
		console.log(`error`, error);
	}
}

function* getOrderTrackDetailsSaga(action) {
	yield put(loader(true));
	if (!action.payload) {
		yield put(loader(false));
		return;
	}
	try {
		const url = `/orders/${action.payload}/track`;
		const response = yield callApi(url);
		if (response && response.data && response.data.status !== "failure") {
			// this if is for checking if order is not closed then redirect him to menu

			localStorage.setItem(
				"restrauntId",
				JSON.stringify(response.data.restraunt_id)
			);
			localStorage.setItem("type", JSON.stringify(response.data.order_type));

			yield put(getOrderDetails(response.data));
			// yield put(loadRestrauntDetails());
			yield put(loader(false));
		} else {
			yield put(loader(false));
		}
	} catch (error) {
		console.log(`error`, error);
		yield put(loader(false));
	}
}
function* fetchBrandMenuSaga(action) {
	yield put(loader(true));
	try {
		const orderType = JSON.parse(localStorage.getItem("type"));
		let apiUrl = `${apiUrls.getBrandMenu}/dine_in/drive_in/${action.payload}`;
		if (orderType === "pick_up") {
			apiUrl = `${apiUrls.getBrandMenu}/pick_up/drive_in/${action.payload}`;
		} else if (orderType === "pick_up_delivery") {
			apiUrl = `${apiUrls.getBrandMenu}/delivery/drive_in/${action.payload}`;
		}
		yield put(loader(true));
		const response = yield callApi(apiUrl);
		yield put(loader(true));
		if (response && response.success) {
			yield put(getTableJoinDetails(response.data));
			const menuD = response.data && Object.keys(response.data.menu);
			menuD && menuD.length && history.push(`/${menuD[0]}`);
			yield put(loader(false));
			// history.push(routes.coupons);
		} else {
			yield put(loader(false));
		}
	} catch (error) {
		yield put(loader(false));
		console.log(`error`, error);
	}
}
export function* mainSaga() {
	yield takeEvery(types.LOAD_ORDER_DETAILS, getOrderDetailsSaga);
	yield takeEvery(types.PLACE_ORDER, placeOrderSaga);
	yield takeEvery(types.LOAD_COUPONS, couponsDataSaga);
	yield takeEvery(types.APPLY_COUPON, applyCouponSaga);
	yield takeEvery(types.LOAD_TIP, tipSaga);
	yield takeEvery(types.LOAD_CHECKOUT, loadCheckoutSaga);
	yield takeEvery(types.ACKNOWLEDGE_PAYMENT, acknowledgePaymentSaga);
	yield takeEvery(types.SUBMIT_RATING_DATA, submitRatingDataSaga);
	yield takeEvery(types.LOAD_RESTRAUNT_DETAILS, loadRestrauntDetailsSaga);
	yield takeEvery(types.SUBMIT_COMPLAINT, submitComplaintSaga);
	yield takeEvery(types.LOAD_MENU_DATA, loadMenuDataSaga);
	yield takeEvery(types.REQUEST_SERVICE, requestServiceSaga);
	yield takeEvery(types.LOAD_PLACE_PICKUP_DELIVERY, placePickUpDeliveryOrder);
	yield takeEvery(types.SUBMIT_ADDRESS, submitAddressSaga);
	yield takeEvery(types.LOAD_QR_DETAILS, getQrCodeSaga);
	yield takeEvery(types.LOAD_FEEDBACK_DATA, getFeedbackData);
	yield takeEvery(types.SUBMIT_FEEDBACK_DATA, submitFeedbackDataSaga);
	yield takeEvery(types.LOAD_ORDER_TRACK_DATA, getOrderTrackDetailsSaga);
	yield takeEvery(types.FETCH_BRAND_MENU, fetchBrandMenuSaga);
}
