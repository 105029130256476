/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";

// library
// import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
// import cookingStyle from "../../../common/CookingInstruction/CookingInstruction.module.scss";
// components
import { VeganStatus } from "../../VeganStatus/VeganStatus";
import { modalsActions } from "../../../../redux/modals/actions";
import { PrimaryButton } from "../../PrimaryButton/PrimaryButton";

// assets
import styles from "./AddProductModal.module.scss";
import plate from "../../../../assets/images/icons/plate.svg";
import { ReactComponent as BackSvg } from "../../../../assets/images/icons/arrow-back.svg";
import { useSelector } from "react-redux";
import { getAddModalDataSelector } from "../../../../redux/reducer/onboarding/selectors";
import { useState } from "react";
import { placeOrderData } from "../../../../redux/reducer/main/action";
import { currencySymbol, displayNotification } from "../../../../config/helper";

export const AddProductModal = () => {
	const dispatch = useDispatch();
	const currentItemData = useSelector(getAddModalDataSelector);
	// const onSubmit = (e) => {
	//   e.preventDefault();
	//   console.log('add');
	// };
	const tags = currentItemData && currentItemData.tags;
	const itemPrizeDetails =
		currentItemData && currentItemData.item_price_details;
	const itemSizeFirst =
		(itemPrizeDetails &&
			itemPrizeDetails.length &&
			itemPrizeDetails[0] &&
			itemPrizeDetails[0].item_size) ||
		"";
	// let itemPrice =
	// 	(currentItemData &&
	// 		currentItemData.item_price_details &&
	// 		currentItemData.item_price_details.length &&
	// 		currentItemData.item_price_details[0] &&
	// 		currentItemData.item_price_details[0].item_price) ||
	// 	0;
	const addOnDetails = currentItemData && currentItemData.addon_details;
	const [sumAddon, setSumAddon] = useState(0);
	const [itemSize, setItemSize] = useState(itemSizeFirst);
	const [addons, setAddons] = useState([]);
	const [itemPrice, setItemPrice] = useState(
		(currentItemData &&
			currentItemData.item_price_details &&
			currentItemData.item_price_details.length &&
			currentItemData.item_price_details[0] &&
			currentItemData.item_price_details[0].item_price) ||
			0
	);
	// let checkedAddons = [];
	const handleSelectItemPrize = (varientSize) => {
		const filterData =
			currentItemData &&
			currentItemData.item_price_details &&
			currentItemData.item_price_details.length &&
			currentItemData.item_price_details.filter(
				(e) => e.item_size === varientSize
			);
		setItemPrice(
			(filterData &&
				filterData.length &&
				filterData[0] &&
				filterData[0].item_price) ||
				""
		);
		setItemSize(varientSize);
	};
	const handleCheckedAddons = (data, e, limit) => {
		const lookup = addons.filter((i) => {
			return i.addon_category_id === data.addon_category_id;
		});
		if (e.target.checked) {
			// to check limit
			if (limit <= lookup.length) {
				document.getElementById(data.addon_id).checked = false;
				displayNotification(
					`Maximum of ${limit} ${data.addon_category_name} can be selected`
				);
			} else {
				setAddons([
					...addons,
					{
						addon_category_id: data.addon_category_id,
						addon_id: data.addon_id,
						addon_name: data.addon_name,
						addon_price:
							data.addon_price &&
							data.addon_price[0] &&
							data.addon_price[0].price,
						addon_size:
							data.addon_price &&
							data.addon_price[0] &&
							data.addon_price[0].name,
					},
				]);
				setSumAddon(parseFloat(data.addon_price[0].price) + sumAddon);
			}
		} else if (!e.target.checked) {
			setSumAddon(sumAddon - parseFloat(data.addon_price[0].price));
			addons.filter((e) => e.addon_id !== data.addon_id).length
				? setAddons(addons.filter((e) => e.addon_id !== data.addon_id))
				: setAddons([]);
		}
	};

	const getItemDetails = (e) => {
		e && e.preventDefault();
		let idAlreadyExists = [];
		idAlreadyExists =
			addOnDetails &&
			addOnDetails.length &&
			addOnDetails.filter(
				(val) => val.addon_limit_optional === false && val.addon_items.length
			);
		if (addons && addons.length) {
			addons.map((val, i) => {
				idAlreadyExists &&
					idAlreadyExists.length &&
					idAlreadyExists.map((item, index) => {
						if (item.addon_cateogry_id === val.addon_category_id) {
							idAlreadyExists.splice(index, 1);
						}
					});
			});
		}
		if (
			idAlreadyExists &&
			idAlreadyExists.length &&
			idAlreadyExists.length > 0
		) {
			displayNotification(
				`Please select atleast one ${idAlreadyExists[0].addon_category_name}`
			);
			idAlreadyExists = [];
		} else {
			dispatch(
				placeOrderData(
					{
						addons: addons,
						item_id: currentItemData.item_id,
						item_size: itemSize,
						qty: 1,
					},
					{
						addons: addons,
						item_name: currentItemData.item_name,
						item_id: currentItemData.item_id,
						item_size: itemSize,
						qty: 1,
						item_image_url: currentItemData
							? currentItemData.item_image_url
							: plate,
						item_price: sumAddon + itemPrice,
						item_type: currentItemData.item_type,
					}
				)
			);
			setAddons([]);
			idAlreadyExists = [];
			dispatch(modalsActions.setShowModal(""));
		}
	};
	return (
		<div className={styles.modal}>
			<div
				className={styles.back}
				onClick={() => dispatch(modalsActions.setShowModal(""))}
				style={{ boxShadow: "none" }}
			>
				<BackSvg />
			</div>
			<div className={styles.top}>
				{currentItemData.item_image_url ? (
					<img
						src={currentItemData ? currentItemData.item_image_url : plate}
						alt="add-product-image"
						style={{ width: "100%", opacity: 1 }}
					/>
				) : (
					<img alt="add-product-image" src={plate} />
				)}
			</div>
			<form
				onSubmit={(e) => getItemDetails(e)}
				className={`add-item-form ${styles.bottom}`}
			>
				<div className={styles.form}>
					<h3 className={styles.itemTitle}>
						<VeganStatus
							type={currentItemData.item_type}
							egg={currentItemData.contains_egg}
						/>
						{currentItemData &&
							currentItemData.item_name &&
							currentItemData.item_name}
					</h3>
					{tags && Array.isArray(tags) && tags.length
						? tags.map((tag, indexTag) => (
								<span key={indexTag} className={styles.chip}>
									{tag}
								</span>
						  ))
						: ""}
					<p>
						{currentItemData &&
						currentItemData.item_description &&
						currentItemData.item_description.length
							? `${currentItemData.item_description}.`
							: ""}
					</p>
					<div className={styles.option}>
						<h4>Select Variant</h4>
						{itemPrizeDetails &&
							itemPrizeDetails.length &&
							itemPrizeDetails.map((size, sizeIndex) => (
								<label className="radio">
									<input
										type="radio"
										hidden
										value={size.item_size}
										name="size"
										defaultChecked={sizeIndex === 0}
										onChange={(e) => handleSelectItemPrize(e.target.value)}
									/>
									<span className="icon" />
									{size.item_size}
									<span className={styles.price}>
										{currencySymbol()}
										{size.item_price}
									</span>
								</label>
							))}
					</div>
					<div className={styles.option}>
						{/* {addOnDetails && addOnDetails.length ? <h4>Add toppings <span>(optional)</span></h4> : ''} */}
						{addOnDetails && addOnDetails.length
							? addOnDetails.map((addOn, addOnIndex) => (
									<React.Fragment key={`addonCategory${addOnIndex}`}>
										{addOn && addOn.addon_items && addOn.addon_items.length ? (
											<h4>
												{addOn.addon_category_name}
												{addOn.addon_limit_optional === false ? "*" : ""}{" "}
												<span>(limit {addOn.addon_limit})</span>
											</h4>
										) : (
											""
										)}
										{addOn &&
											addOn.addon_items &&
											addOn.addon_items.map((el) => (
												<label className="checkbox">
													<input
														type="checkbox"
														onChange={(e) =>
															handleCheckedAddons(el, e, addOn.addon_limit)
														}
														id={el.addon_id}
														hidden
														name={el.addon_id}
													/>
													<span className="icon" />
													<span
														style={{ width: "76%", wordBreak: "break-word" }}
													>
														{el.addon_name}
													</span>
													<span className={styles.price}>
														{currencySymbol()}
														{el.addon_price &&
															el.addon_price[0] &&
															el.addon_price[0].price}
													</span>
												</label>
											))}
									</React.Fragment>
							  ))
							: ""}
					</div>
					{/* <Link to='#' className={styles.more}>Show more</Link> */}
				</div>
				<PrimaryButton
					title="ADD ITEM"
					info={`${currencySymbol()}${sumAddon + itemPrice}`}
					onClick={() => getItemDetails()}
				/>
			</form>
		</div>
	);
};
