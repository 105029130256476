import { combineReducers } from "redux";
// import { routerReducer } from "react-router-redux";
import { modalsReducer as modals } from "./modals/reducer";
import onboardingReducer from "./reducer/onboarding/reducer";
import mainReducer from "./reducer/main/reducer";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import history from "../config/history";
export const rootReducer = combineReducers({
  modals,
  onboardingReducer,
  mainReducer,
  routing: connectRouter(history),
});
