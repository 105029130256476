/* eslint-disable no-mixed-operators */
import React, { useState } from "react";

// library
import { useDispatch, useSelector } from "react-redux";

// components
// import { Feedback } from "../../../common/Feedback/Feedback";
import { Dialogs, NoNavigationLayout, Wrong } from "../../../common";
import { VeganStatus } from "../../../common/VeganStatus/VeganStatus";
import { Instruction } from "../../../common/Instruction/Instruction";
import { Link, withRouter } from "react-router-dom";
import { routes } from "../../../App/routes";
import { modalsActions } from "../../../../redux/modals/actions";
import { getShowModal } from "../../../../redux/modals/selectors";

// assets
import styles from "./OrderRating.module.scss";
import { ReactComponent as CloseSvg } from "../../../../assets/images/icons/close.svg";
import { ReactComponent as EditSvg } from "../../../../assets/images/icons/edit-1.svg";
// import soup from '../../../../assets/images/soup.jpg';
// import { Emotions } from "../../../common/Emotions/Emotions";
import {
	getOrderDetailsData,
	getSelectRatingData,
} from "../../../../redux/reducer/main/selectors";
import { useEffect } from "react";
import {
	getRatingData,
	loadFeedbackData,
	// loadOrderDetails,
	// loadRestrauntDetails,
	submitFeedbackData,
	// submitRatingData,
} from "../../../../redux/reducer/main/action";
import ErrorBoundary from "../../../common/ErrorBoundarie/ErrorBoundary";
import { storeErrorInfo } from "../../../../redux/reducer/onboarding/actions";
// import history from "../../../../config/history";
import Stars from "../../../common/Stars/Stars";

const FeedbackReview = () => {
	// console.log('params', useParams())
	const dispatch = useDispatch();
	const getErrorDataFromBoundary = (data) => {
		dispatch(storeErrorInfo(data));
	};
	const rating = useSelector(getSelectRatingData);
	const overallRating = rating && rating.overall_ratings;
	useEffect(() => {
		// dispatch(loadOrderDetails());
		dispatch(loadFeedbackData());
		if (!overallRating) {
			dispatch(modalsActions.setShowModal("Rating"));
		}
	}, [dispatch]);
	const modal = useSelector(getShowModal);

	const [instructionAdded, setInstructionAdded] = useState(false);
	const [showFeedback, setShowFeedback] = useState(false);

	const runningOrderData = useSelector(getOrderDetailsData);
	console.log("runningOrderData", runningOrderData);
	const orderDetails =
		runningOrderData &&
		runningOrderData.order_details &&
		runningOrderData.order_details.order_details;
	if (orderDetails) {
		var { order_amount, ...restData } = orderDetails;
	}
	const runningData =
		restData && Object.values(restData).flatMap((data) => data);

	const handleSubmitEmote = (data, status) => {
		dispatch(getRatingData({ data, status }));
	};
	const [activeStar, setActiveStar] = useState(4);
	const reviews = rating && rating.items_reviews && rating.items_reviews;
	// const [feedback, setFeedback] = useState({});
	const [tempData, setTempData] = useState();
	const [selectedReview, setSelectedReview] = useState("");
	var merged = runningData;
	if (reviews && reviews.length) {
		// eslint-disable-next-line no-redeclare
		var merged = runningData.map((v) => ({
			...v,
			...reviews.find((sp) => sp.item_order_id === v.item_order_id),
		}));
	}
	const getRatingById = (id) => {
		const filteredReview =
			reviews &&
			reviews.length &&
			reviews.filter((el) => el.item_order_id === id);
		return filteredReview && filteredReview[0] && filteredReview[0].item_rating
			? filteredReview[0].item_rating
			: activeStar;
	};

	return (
		<NoNavigationLayout>
			{modal === "Dialogs" && <Dialogs />}

			<main
				className={`${styles.orderRating} make-scroll`}
				style={{ paddingBottom: 43 }}
			>
				<div className={styles.panel}>
					<Link
						to={routes.checkout}
						className={`${styles.icon} ${styles.close}`}
						onClick={(e) => {
							e.preventDefault();
							// dispatch(modalsActions.setShowModal("Dialogs"));
						}}
					>
						<CloseSvg />
					</Link>
					<h3>
						{runningOrderData && runningOrderData.restraunt_name}
						{/* <div className={styles.stars}>
              {stars.map((star, index) => (
                <span key={index} className={`${styles.star} ${star === overallRating && styles.active}`} />
              ))}
            </div> */}
					</h3>
					<div
						className={`${styles.icon} ${styles.edit}`}
						onClick={() =>
							dispatch(modalsActions.setShowModal("Rating Update"))
						}
					>
						<EditSvg />
					</div>
				</div>
				<section className={styles.content}>
					<form action="">
						<h3>Rate your items</h3>
						{merged &&
							merged.length &&
							merged.map((product, index) => (
								<ErrorBoundary
									sendErrorData={getErrorDataFromBoundary}
									itemDetails={product}
									fallBack={null}
								>
									<div key={index} className={styles.item}>
										<div className="d-flex">
											{product.item_image_url && (
												<img
													src={product.item_image_url}
													className={styles.image}
													alt={product.title}
												/>
											)}
											<div
												className={styles.description}
												style={{ paddingTop: 4 }}
											>
												<h4>
													<VeganStatus
														type={product.item_type}
														egg={product.contains_egg}
													/>
													{product.item_name}
												</h4>
												<Stars
													styles={styles}
													product={product}
													reviewData={
														reviews &&
														reviews.length &&
														reviews.filter(
															(el) => el.item_order_id === product.item_order_id
														)
													}
													handleSubmitEmote={handleSubmitEmote}
													setStar={setActiveStar}
												/>

												{/* {!instructionAdded ?
                <Link to={'#'} className={styles.review} onClick={() => {setShowFeedback(true); setTempData(product)}}>
                  Add a review
                </Link> : product.item_review && product.item_review !== '' ? '' : <Link to={'#'} className={styles.review} onClick={() => {setShowFeedback(true); setTempData(product)}}>
                  Add a review
                </Link>} */}
											</div>
										</div>
										<Wrong
											star={getRatingById(product.item_order_id)}
											handleSubmitEmote={handleSubmitEmote}
											itemWiseRating
											product={product}
										/>

										{/* <Emotions product={product} reviewData={reviews && reviews.length && reviews.filter(el => el.item_order_id === product.item_order_id)} handleSubmitEmote={handleSubmitEmote} feedback={feedback} /> */}
										<div className={styles.description}>
											{instructionAdded &&
											product.item_review &&
											product.item_review !== "" ? (
												<Instruction
													black
													setShow={setShowFeedback}
													ratingPage
													setTempData={setTempData}
													setSelectedReview={setSelectedReview}
													reviewData={product}
													description={product.item_review}
												/>
											) : (
												""
											)}
										</div>
									</div>
								</ErrorBoundary>
							))}
						<button
							type="button"
							onClick={() => dispatch(submitFeedbackData())}
							className="btn btn-primary rating-fix-button"
						>
							SUBMIT
						</button>
					</form>
				</section>
			</main>
		</NoNavigationLayout>
	);
};
export default withRouter(FeedbackReview);
