/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
// core
import React, { useEffect, useState } from "react";

// components
// import { routes } from "../../../App/routes";
import { NoNavigationLayout } from "../../../common";

// assets
import styles from "../Onboarding.module.scss";
import checkout from "./Whatsapp.module.scss";
// import checkout from '../../main/Checkout/Checkout.module.scss';
// import styles from './Onboarding.module.scss'
import logo from "../../../../assets/images/onboarding/logo.png";
import { ReactComponent as RightCarret } from "../../../../assets/images/icons/rightCarret.svg";
import { ReactComponent as DownArrow } from "../../../../assets/images/icons/downCarret.svg";
import { currencySymbol, displayNotification } from "../../../../config/helper";

import orderModal from "../../../common/modals/OrderModal/OrderModal.module.scss";
import history from "../../../../config/history";
import { routes } from "../../../App/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  acknowledgePayment,
  loadCheckout,
  loadOrderDetails,
  loadTip,
} from "../../../../redux/reducer/main/action";
import { getOrderDetailsData } from "../../../../redux/reducer/main/selectors";
import Tip from "../../../common/Tip/Tip";
import { useDebouncedEffect } from "../../../../config/useDebouncedEffect";
import { Link } from "react-router-dom";
import { razorPayKey } from "../../../../config/config";
import { sendUserInfo } from "../../../../redux/reducer/onboarding/actions";
// import { NoNavigationLayout } from '../../common';

const WhatsappIntegration = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  const [tip, setTip] = useState(0);
  const dispatch = useDispatch();
  // const menu = useSelector(getMenu);
  const userData = useSelector(getOrderDetailsData);
  const orderDetails = (userData && userData.order_details) || {};
  const { order_amount, ...restData } = orderDetails;
  // console.log("userData :>> ", userData);
  const allItems = userData
    ? restData && Object.values(restData) && Object.values(restData).flat()
    : 0;
  // console.log("restData :>> ", restData && Object.values(restData).flat());
  // if (orderDetails) {
  //   var { order_amount, [customerId]: remove, ...restData } = orderDetails;
  // }
  useEffect(() => {
    const token = localStorage.getItem("token");
    const qrCode = localStorage.getItem("qrCode");
    if (token && qrCode) {    
      dispatch(loadOrderDetails());
      dispatch(sendUserInfo('checkout_landing'));
    } else {
      localStorage.clear();
      window.location = routes.register;
    }
  }, [dispatch]);

  const navigateToMenu = () => {
    history.push(routes.special);
  };

  const restrauntDiscount =
    (userData &&
      userData.discount_details &&
      userData.discount_details.restraunt_discount_amount) ||
    0;
  const tipAmount =
    (userData && userData.tip_details && userData.tip_details.tip_amount) || 0;
  const couponDiscount =
    (userData &&
      userData.discount_details &&
      userData.discount_details.coupon_discount) ||
    0;
  const serviceChargeAmt =
    (userData &&
      userData.service_charge_amount &&
      userData.service_charge_amount) ||
    0;

  useDebouncedEffect(
    () => (tip > 0 ? dispatch(loadTip(parseInt(tip))) : ""),
    [tip],
    2000
  );
  const customerId = JSON.parse(localStorage.getItem("customerId"));
  const country = localStorage.getItem("country");
  let amount =
    userData &&
    userData.payment_details &&
    userData.payment_details.payment_amount; //Razorpay consider the amount in paise
  const options = {
    key: razorPayKey,
    amount: amount * 100, // 2000 paise = INR 20, amount in paisa
    name: "",
    description: JSON.stringify({
      order_id: userData.order_id,
      payee_id: customerId,
      created_on: userData.created_on,
    }),
    order_id: "",
    currency: country && country.length && country == "aus" ? "AUD" : "INR",
    handler: (response) =>
      dispatch(acknowledgePayment(response.razorpay_payment_id)),
    prefill: {
      name: "",
      email: "",
      contact: "",
    },
    notes: {
      address: "",
    },
    theme: {
      color: "#528ff0",
    },
  };
  const customerDetails = userData && userData.customer_details;
  const deliveryAddress = customerDetails && customerDetails.address;
  const type = JSON.parse(localStorage.getItem("type"));
  const openPayModal = () => {
    dispatch(loadOrderDetails());
    if (!deliveryAddress && type === "pick_up_delivery") {
      displayNotification("Please submit your address");
    } else {
      dispatch(loadCheckout(options));
    }
  };
if(userData && userData.order_status === 'completed') {
  localStorage.clear()
  window.location = routes.register
}
  const handleOpenModal = () => {
    const onlinePayment = localStorage.getItem("onlinePayment");
    
    if (onlinePayment == "false") {
      displayNotification(
        "The restaurant has disabled online payments. Please ask your server for available payment modes."
      );
    } else {
      dispatch(sendUserInfo('checkout_payment'));
      openPayModal();
    }
  };

  return (
    <NoNavigationLayout>
      <main className={`${styles.register} ${styles.join}`}>
        <section>
          <div>
            <div>
              <img
                // loading="lazy"
                src={logo}
                alt="logo"
                width={67}
                height={24}
                style={{ marginLeft: 10, marginTop: 10, width: 67, height: 24 }}
              />
              {/* <h2 style={customStyle.headerH2}>Order more?<i className="fa fa-caret-right" aria-hidden="true"></i></h2> */}
              <div style={{padding: 16, }}>
                <span style={customStyle.dsc}>Order more?</span>
                <p style={customStyle.panelDsc}>To order more, click below!</p>
              </div>
              <div style={{padding: 16}}>
              <button
                  type="button"
                  className={`btn ${orderModal.button}`}
                  style={customStyle.orderMore}
                  onClick={() => navigateToMenu()}
                >
                  ORDER MORE{"  "}
                  <span style={{ marginLeft: 10 }}>
                    <RightCarret />
                  </span>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>
                </button>
              </div>
      
            </div>
            
          </div>
          <div
              className={checkout.panel}
              style={{ bottom: 0 }}
            >
              <h2 style={customStyle.panelHeader}>Bill Details</h2>
              <p style={customStyle.panelDsc}>
                There’s a pending bill on this table. You can directly pay the
                bill without joining the table
              </p>
              <div className={checkout.waiter}>
                <Tip tipAmount={tipAmount} styles={checkout} setTip={setTip} />
              </div>
              <div className={checkout.total} style={checkout.billArea}>
                <h5 style={{ ...customStyle.h5b, color: "#5bac81", paddingRight: 10 }}>
                  {allItems && allItems.length} items
                  <span>
                    {currencySymbol()}
                    {orderDetails && orderDetails.order_amount}
                  </span>
                </h5>
                <div style={{...customStyle.charges, paddingRight: 10}} className={checkout.totalBill}>
                  {couponDiscount > 0 ? (
                    <h6>
                      Coupon Discount
                      <span className={styles.green}>
                        {currencySymbol()}
                        {couponDiscount}
                      </span>
                    </h6>
                  ) : (
                    ""
                  )}
                  {restrauntDiscount > 0 ? (
                    <h6>
                      Restaurant Discount
                      <span className={styles.green}>
                        {currencySymbol()}
                        {restrauntDiscount}
                      </span>
                    </h6>
                  ) : (
                    ""
                  )}
                  {serviceChargeAmt > 0 ? (
                    <h6>
                      Service charge
                      <span>
                        {currencySymbol()}
                        {serviceChargeAmt}
                      </span>
                    </h6>
                  ) : (
                    ""
                  )}
                  {userData &&
                    userData.tax_details &&
                    userData.tax_details.map((tax, index) =>
                      tax.tax_amount > 0 ? (
                        // <ErrorBoundary sendErrorData={getErrorDataFromBoundary} itemDetails={tax} fallBack={null}>
                        <h6 key={`tax${index}`}>
                          {tax.tax_name}
                          <span>
                            {currencySymbol()}
                            {tax.tax_amount}
                          </span>
                        </h6>
                      ) : (
                        // </ErrorBoundary>
                        ""
                      )
                    )}
                  {tipAmount > 0 ? (
                    <h6>
                      Tip
                      <span className={styles.green}>
                        {currencySymbol()}
                        {tipAmount}.00
                      </span>
                    </h6>
                  ) : (
                    ""
                  )}
                  {userData && userData.delivery_fee > 0 ? (
                    <h6>
                      Delivery charges
                      <span className={styles.green}>
                        {currencySymbol()}
                        {userData && userData.delivery_fee}
                      </span>
                    </h6>
                  ) : (
                    ""
                  )}
                  {userData && userData.packing_charges > 0 ? (
                    <h6>
                      Packing charges
                      <span className={styles.green}>
                        {currencySymbol()}
                        {userData && userData.packing_charges}
                      </span>
                    </h6>
                  ) : (
                    ""
                  )}
                </div>

                <h4 style={{ marginTop: 14, paddingRight: 10 }}>
                  Grand Total
                  <span>
                    {currencySymbol()}
                    {userData &&
                      userData.payment_details &&
                      userData.payment_details.payment_amount}
                  </span>
                </h4>
              </div>
              <Link to={routes.checkout} style={customStyle.viewDetail}>
                View detailed bill <DownArrow style={{ marginBottom: 2 }} />
              </Link>
              <div className={orderModal.buttons} style={customStyle.btnsDiv}>
                <button
                  type="button"
                  onClick={() => handleOpenModal()}
                  style={customStyle.btns}
                  className={`btn ${orderModal.button} ${checkout.active}`}
                >
                  PAY BILL
                </button>
                {/* <button type='button' className={`btn ${orderModal.button}`} style={{...customStyle.btns, marginLeft: 20}}>Pay now <span className={styles.currency} style={{marginLeft: 10, color: 'white'}}>{currencySymbol()}</span></button> */}
              </div>
            </div>
        </section>
      </main>
    </NoNavigationLayout>
  );
};
const customStyle = {
  headerH2: {
    // left: '6%',
    justifyContent: "start",
    position: "absolute",
    display: "flex",
    left: 10,
    top: "8%",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: 22,
  },
  billArea: {
    background: "#ECDBE9 !important",
    boxShadow:
      "inset -2px 2px 4px rgb(201 186 198 / 20%), inset 2px -2px 4px rgb(201 186 198 / 20%), inset -2px -2px 4px rgb(255 252 255 / 90%), inset 2px 2px 5px rgb(201 186 198 / 90%) !important",
    borderRadius: "10px",
    height: "59% !important",
  },
  viewDetail: {
    position: "relative",
    top: "6%",
    fontWeight: "600",
    textAlign: "center",
    fontSize: 12,
    lineHeight: 1.33,
    marginBottom: 16,
    letterSpacing: 0.4,
    color: "#4a5662",
  },
  btnsDiv: {
    marginTop: "auto",
    bottom: 10,
    textAlign: "center",
    position: "relative",
  },
  btns: { width: "100%", fontSize: 12, background: "black" },
  orderMore: {
    width: "100%",
    // top: '17%',
    // marginTop: 10,
    position: 'relative',
    background: "linear-gradient(90deg, #C84E89 0%, #F15F79 100%)",
    boxShadow:
      "-5px 5px 10px rgba(184, 177, 203, 0.2), 5px -5px 10px rgba(184, 177, 203, 0.2), -5px -5px 10px rgba(250, 239, 255, 0.9), 5px 5px 13px rgba(184, 177, 203, 0.9), inset 1px 1px 2px rgba(250, 239, 255, 0.3), inset -1px -1px 2px rgba(184, 177, 203, 0.5)",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 13,
  },
  orderMoreDiv: {
    textAlign: "center",
    top: "17%",
    position: "absolute",
    width: "100%",
  },
  dsc: {
    // left: 22,
    // right: 10,
    // top: "13%",
    // position: "absolute",
    color: "#4A5662",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 13,
  },
  h5b: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
  charges: {
    overflow: "auto",
    height: "52%",
  },
  panelHeader: {
    fontWeight: 700,
    fontSize: 16,
    fontFamily: "Montserrat",
    color: "#39434E",
    fontStyle: "normal",
  },
  panelDsc: {
    fontFamily: "Montserrat",
    fontSize: 11,
    fontWeight: 400,
  },
};
export default React.memo(WhatsappIntegration);
