import React from 'react'
import { useState } from 'react';

const Stars = ({styles, handleSubmitEmote, product, reviewData, setStar}) => {
    const stars = [5, 4, 3, 2, 1];
    const [activeStar, setActiveStar] = useState(4);
    // const [showStar, setShowStar] = useState(false);
    const changeStar = (star) => {
      setActiveStar(star);
      setStar(star);
      handleSubmitEmote({item_rating: star, item_id: product.item_id, item_order_id: product.item_order_id, item_review: reviewData && reviewData.length && reviewData[0] && reviewData[0].item_review || '', item_name: product.item_name, item_type: product.item_type, contains_egg: product.contains_egg}, 'itemRating')
    };
  return (
    <div className={styles.rate}>
                        <div className={styles.stars}>
                          {stars.map((star, index) => (
                            <span
                              key={index}
                              onClick={() => {changeStar(star)}}
                              className={`${styles.star} ${
                                activeStar === star ? styles.active : ""
                              }`}
                            />
                          ))}
                        </div>
                      </div>
  )
}

export default Stars