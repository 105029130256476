import produce from "immer";
import { routes } from "../../../components/App/routes";
import history from "../../../config/history";
import { types } from "./types";

export const initialState = {
	currentMenuTab: "food",
	placeOrder: [],
	placeOrder2: [],
	orderDetails: {},
	coupons: [],
	ratingData: {},
	restrauntDetails: {},
	vegOnly: false,
	containsEgg: false,
	addAddressModal: false,
	deliveryDetails: {},
	allergyDetails: "",
	backButton: false,
	feedback: {},
	orderDetails2: {},
};

/* eslint-disable default-case, no-param-reassign */
const mainReducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.CURRENT_MENU_TAB:
				draft.currentMenuTab = action.payload;
				break;
			case types.PLACE_ORDER_DATA:
				// draft.placeOrder = [action.payload];
				if (action.payload === "empty") {
					draft.placeOrder = [];
					draft.placeOrder2 = [];
				} else if (action.payload === "cookingInstructions") {
					const data = action.payload2;
					// console.log(`data`, data);
					// const index = state.placeOrder2.findIndex(
					//   (item) => item.item_id === data.item_id
					// );
					const index = action.payload2.index;
					draft.placeOrder[index] = {
						addons: data.addons,
						item_id: data.item_id,
						item_size: data.item_size,
						qty: data.qty,
						item_customize: data.item_customize,
					};
					draft.placeOrder2[index] = data;
				} else if (action.payload === "deleteInstruction") {
					draft.placeOrder = action.payload2.newSelectData;
					draft.placeOrder2 = action.payload2.newSelectData2;
				} else {
					draft.placeOrder.push(action.payload);
					draft.placeOrder2.push(action.payload2);
				}

				break;
			case types.CHANGE_QTY:
				// draft.placeOrder = [action.payload];
				const data = action.payload;
				const items = draft.placeOrder;
				const items2 = draft.placeOrder2;
				let index;
				if (action.payload.index) {
					index = action.payload.index;
				} else if (action.payload.changeLastItem) {
					// index = items.findIndex((x) => x.item_id === data.id);
					index = items.map((el) => el.item_id).lastIndexOf(data.id);
				} else {
					index = items.findIndex((x) => x.item_id === data.id);
				}
				// const index = items.findIndex((x) => x.item_id === data.id);
				if (data.symbol === "+") {
					items[index].qty = items[index].qty + 1;
					items2[index].qty = items2[index].qty + 1;
				} else {
					if (items[index].qty - 1 === 0) {
						items.splice(index, 1);
						items2.splice(index, 1);
					} else {
						items[index].qty = items[index].qty - 1;
						items2[index].qty = items[index].qty - 1;
					}
				}
				draft.placeOrder = items;
				draft.placeOrder2 = items2;
				break;
			case types.GET_ORDER_DETAILS:
				draft.orderDetails = action.payload;
				break;
			case types.GET_COUPONS:
				draft.coupons = action.payload;
				break;
			case types.GET_RATING_DATA:
				// console.log("action.payload.status :>> ", action.payload.status);
				if (action.payload.status === "overall") {
					draft.ratingData = action.payload.rating_data;
					history.push(routes.orderRating);
				} else if (action.payload.status === "overallFeedback") {
					// console.log("object :>> ");
					draft.ratingData = action.payload.rating_data;
					// history.push(routes.orderRating);
				} else if (action.payload.status === "editOverall") {
					const items_reviews = state.ratingData.items_reviews;
					const ratingData = action.payload.rating_data;
					draft.ratingData = { ...ratingData, items_reviews };
				} else if (action.payload.status === "itemRating") {
					const data = action.payload.data;
					const arr = [data];
					if (state.ratingData.items_reviews) {
						const filterData = state.ratingData.items_reviews.filter(
							(el) => el.item_order_id !== data.item_order_id
						);
						draft.ratingData.items_reviews = [...filterData, data];
					} else {
						draft.ratingData = { ...state.ratingData, items_reviews: arr };
					}
				} else if (action.payload.status === "feedback") {
					const id = action.payload.data.item_order_id;
					if (!state.ratingData.items_reviews) {
						draft.ratingData = {
							...state.ratingData,
							items_reviews: [action.payload.data],
						};
					} else {
						const objIndex = state.ratingData.items_reviews.findIndex(
							(obj) => obj.item_order_id === id
						);
						if (objIndex > -1) {
							draft.ratingData.items_reviews[objIndex].item_review =
								action.payload.data.item_review;
						} else {
							draft.ratingData.items_reviews.push(action.payload.data);
						}
					}
				} else if (action.payload.status === "delete") {
					const deleteData = action.payload.data;
					const filterData = state.ratingData.items_reviews.filter(
						(el) => el.item_order_id !== deleteData.item_order_id
					);
					draft.ratingData.items_reviews = [...filterData];
				}
				break;
			case types.GET_RESTRAUNT_DETAILS:
				draft.restrauntDetails = action.payload;
				break;
			case types.VEG_ONLY:
				draft.vegOnly = action.payload;
				if (action.payload === false) {
					draft.containsEgg = action.payload;
					document.getElementById("checkContainsEgg").checked = false;
				}
				break;
			case types.CONTAINS_EGG:
				draft.containsEgg = action.payload;
				break;
			case types.TOGGLE_ADD_ADDRESS:
				draft.addAddressModal = action.payload;
				break;
			case types.GET_ADDRESS:
				draft.deliveryDetails = action.payload;
				break;
			case types.ALLERGY_DETAILS:
				draft.allergyDetails = action.payload;
				break;
			case types.BACK_BUTTON:
				draft.backButton = action.payload;
				break;
			case types.GET_FEEDBACK_DATA:
				draft.feedback = action.payload;
				break;
			case types.PLACE_ORDER_LOCAL_DATA:
				if (action.payload.type === "empty") {
					draft.allergyDetails = action.payload.allergyDetails;
					draft.placeOrder = action.payload.orderDetails;
					draft.placeOrder2 = action.payload.orderDetails;
				} else {
					draft.allergyDetails = action.payload.allergyDetails;
					draft.placeOrder = action.payload.orderDetails;
				}
				break;
			case types.ORDER_DETAILS_PREPAID:
				draft.orderDetails2 = action.payload;
				break;
		}
	});

export default mainReducer;
