/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
// core
import React from "react";

// components
// import { GridItems } from "../../../common";
import Layout from "../../../common/Layout/loadable";

// assets
import styles from "./Special.module.scss";
// import chocolate from '../../../../assets/images/chocolate.png';
// import sunset from '../../../../assets/images/sunset.png';
// import banner from '../../../../assets/images/special-banner.png';
import { useSelector } from "react-redux";
import { getMenu } from "../../../../redux/reducer/onboarding/selectors";
import { useEffect } from "react";
import {
	loader,
	loadGetTableCode,
	submitTableJoinCode,
} from "../../../../redux/reducer/onboarding/actions";
import { useDispatch } from "react-redux";
import {
	fetchBrandMenu,
	loadMenuData,
} from "../../../../redux/reducer/main/action";
import history from "../../../../config/history";
import { displayNotification } from "../../../../config/helper";
import GridItems from "../../../common/GridItems/loadable";
import { routes } from "../../../App/routes";
// import { currentMenuTab } from '../../../../redux/reducer/main/selectors';

const Special = () => {
	const data = useSelector(getMenu);

	const menuData = data && data.menu;
	const menuKeys = menuData && Object.keys(menuData);
	// console.log('menuKeys', menuKeys)
	let processedData = {};
	// const menuArray = menuData && Object.values(menuData).flat()
	menuKeys &&
		menuKeys.length &&
		menuKeys.map((val) => {
			const inside_menu = Object.keys(data.menu[val]);
			inside_menu.map((inside) => {
				let category = data.menu[val][inside].filter((val_data, i) => {
					return val_data.is_special === true;
				});
				if (category.length > 0) {
					processedData = { ...processedData, [inside]: category };
					// processedData.push(category)
				}
			});
		});
	const dispatch = useDispatch();
	const categoryName = processedData && Object.keys(processedData);
	const driveIn = JSON.parse(localStorage.getItem("driveIn"));
	const brandId = JSON.parse(localStorage.getItem("brandId"));
	useEffect(() => {
		const qrCode =
			localStorage.getItem("qrCode") &&
			JSON.parse(localStorage.getItem("qrCode"));
		const joinCode = localStorage.getItem("joinCode");
		// dispatch(loadTableJoinDetails(qrCode))
		if (data === null || !data) {
			if (JSON.parse(localStorage.getItem("type")) === "dine_in") {
				dispatch(loadGetTableCode(qrCode));
				if (driveIn === true) {
					dispatch(fetchBrandMenu(brandId));
				} else {
					dispatch(submitTableJoinCode(joinCode));
				}
			} else if (
				JSON.parse(localStorage.getItem("type")) === "pick_up" ||
				JSON.parse(localStorage.getItem("type")) === "pick_up_delivery"
			) {
				if (driveIn === true) {
					dispatch(fetchBrandMenu(brandId));
				} else {
					dispatch(loader(true));
					dispatch(loadMenuData());
				}
			} else {
				displayNotification("Something went wrong, Please scan the qr again");
				localStorage.clear();
			}
		}
		if (
			categoryName &&
			categoryName.length === 0 &&
			menuKeys &&
			menuKeys.length
		) {
			console.log("menuKeys", menuKeys);
			console.log("first aaa");
			history.push(`${menuKeys[0] ? menuKeys[0] : routes.driveIn}`);
		}
		// dispatch(loadOrderDetails());
	}, [menuKeys]);

	const restDetails = data && data.restraunt_details;
	const banner = restDetails && restDetails.feature_image;

	return (
		<Layout>
			<main
				className="special"
				style={{ height: "calc(100vh - 222px)", overflow: "auto" }}
			>
				<section>
					<div className={styles.banner}>
						<div className={styles.image}>
							{banner ? <img src={banner} alt="banner" /> : ""}
						</div>
					</div>
				</section>
				<section>
					<GridItems
						items={processedData && Object.values(processedData)}
						categoryName={categoryName}
						toggle
					/>
				</section>
			</main>
		</Layout>
	);
};

export default React.memo(Special);
