import React from "react";

// assets
import styles from "./Feedback.module.scss";
import { ReactComponent as CloseSvg } from "../../../assets/images/icons/close.svg";
import {
	getRatingData,
	submitComplaint,
} from "../../../redux/reducer/main/action";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";

export const Feedback = ({
	defaultValue,
	showFeedback,
	setShowFeedback,
	feedback,
	setFeedback,
	ratingPage,
	tempData,
	complaint,
	selected,
	requestModal,
	handleSubmit,
	setInstructionAdded,
	ratingData,
	setRatingData,
	overallReview,
}) => {
	const [state, setstate] = useState(defaultValue);
	useEffect(() => {
		if (ratingPage) {
			document.getElementById("idTextarea").value = defaultValue;
		}
	}, [defaultValue]);

	const dispatch = useDispatch();
	const onSubmit = (e) => {
		e.preventDefault();
		setShowFeedback(false);
		if (complaint) {
			dispatch(
				submitComplaint({
					item_id: selected.item_id,
					item_image_url: selected.item_image_url,
					item_type: selected.item_type,
					contains_egg: selected.contains_egg,
					complaint: feedback,
					item_name: selected.item_name,
				})
			);
		} else if (requestModal) {
			handleSubmit();
		} else if (ratingPage) {
			handleSubmitFeedBack();
			if (document.getElementById("idTextarea")) {
				document.getElementById("idTextarea").value = "";
			}
		} else if (overallReview) {
			setRatingData({ ...ratingData, overall_review: feedback });
		}
	};

	const handleRatingFeedback = (e) => {
		setFeedback({ ...feedback, [tempData.item_order_id]: e.target.value });
	};
	const handleSubmitFeedBack = () => {
		dispatch(
			getRatingData({
				data: {
					item_rating: 5,
					item_id: tempData.item_id,
					item_order_id: tempData.item_order_id,
					item_review: feedback[tempData.item_order_id],
					item_name: tempData.item_name,
					item_type: tempData.item_type,
					contains_egg: tempData.contains_egg,
				},
				status: "feedback",
			})
		);
		setInstructionAdded(true);
		setShowFeedback(false);
	};
	return (
		<div className={`${styles.feedback} ${showFeedback ? styles.open : ""}`}>
			<div className={styles.inner}>
				<h3>
					Provide a feedback
					<span className={styles.close} onClick={() => setShowFeedback(false)}>
						<CloseSvg />
					</span>
				</h3>
				<hr />
				<form onSubmit={(e) => onSubmit(e)}>
					<label>
						{ratingPage ? (
							<textarea
								name="rating"
								id="idTextarea"
								placeholder="Something else? Mention it here."
								onChange={(e) => handleRatingFeedback(e)}
								defaultValue={state}
							/>
						) : (
							<textarea
								defaultValue={state}
								placeholder="Something else? Mention it here."
								onChange={(e) => setFeedback(e.target.value)}
							/>
						)}
					</label>
					{ratingPage ? (
						<button
							type="submit"
							onClick={() => handleSubmitFeedBack()}
							className="btn btn-primary"
						>
							SUBMIT FEEDBACK
						</button>
					) : (
						<button type="submit" className="btn btn-primary">
							SUBMIT FEEDBACK
						</button>
					)}
				</form>
			</div>
		</div>
	);
};
