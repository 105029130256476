import React, { useState } from "react";

// library
import { useDispatch } from "react-redux";

// components
import { VeganStatus } from "../../VeganStatus/VeganStatus";
// import { modalsActions } from "../../../../redux/modals/actions";
import { Feedback } from "../../Feedback/Feedback";

// assets
import styles from "./ComplaintsModal.module.scss";
// import { ReactComponent as BackSvg } from "../../../../assets/images/icons/arrow-back.svg";
import { ReactComponent as ArrowSvg } from "../../../../assets/images/icons/arrow.svg";
// import edit from "../../../../assets/images/icons/edit-white.svg";
// import minus from "../../../../assets/images/icons/minus-circle.svg";
import { useSelector } from "react-redux";
import { getOrderDetailsData } from "../../../../redux/reducer/main/selectors";
import { submitComplaint } from "../../../../redux/reducer/main/action";
// import { Instruction } from "../../Instruction/Instruction";
import { WrapperModal } from "../WrapperModal/WrapperModal";
import { currencySymbol } from "../../../../config/helper";
import ErrorBoundary from "../../ErrorBoundarie/ErrorBoundary";
import { storeErrorInfo } from "../../../../redux/reducer/onboarding/actions";
export const ComplaintsModal = () => {
  const dispatch = useDispatch();
  const getErrorDataFromBoundary = data => {
    dispatch(storeErrorInfo(data))
  }
  // const [instructionAdded, setInstructionAdded] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedback, setFeedback] = useState('');
  const showItems = (e) => {
    e.target.parentElement.classList.toggle(styles.hide);
  };
  const runningOrderData = useSelector(getOrderDetailsData)
  const orderDetails = runningOrderData && runningOrderData.order_details;
  if (orderDetails) {
    var {order_amount, ...restData} = orderDetails;
  }
  const reasons = [{name: 'Order taking too long', id: 1}, {name: 'Order not as mentioned', id: 2},{name: 'Missing items', id: 3}]
  const runningData =
  restData && Object.values(restData).flatMap(data => data);
  // const [complaint, setComplaint] = useState()
  const [selected, setSelected] = useState();
  const handleChange = (e, data) => {
    dispatch(submitComplaint({item_id: data.item_id, item_image_url: data.item_image_url, item_type: data.item_type, contains_egg: data.contains_egg, complaint: e, item_name: data.item_name}));
  }
  return (
    <React.Fragment>
    <WrapperModal title='Complaints'>
      <div className={styles.complaints}>
      <Feedback showFeedback={showFeedback} selected={selected} complaint setShowFeedback={setShowFeedback} feedback={feedback} setFeedback={setFeedback} />
        <div className={styles.bottom} style={{paddingBottom: 16}}>
        {runningData && runningData.length ? runningData.map((item, index) => (
          <div key={index} className={styles.item}>
            <div className={styles.top}>
              <h4 className={styles.title}>
              <VeganStatus type={item.item_type} egg = {item.contains_egg} />
                {item.item_name}
                <span className={styles.price}>{currencySymbol()}{item.item_price}</span>
              </h4>
              <p className={styles.description}>{item.item_description}</p>
              <hr />
            </div>
            <h5 className={styles.subTitle} onClick={(e) => showItems(e)}>
              What was not right?
              <ArrowSvg />
            </h5>
            <div className={styles.panel}>
              {reasons.map((res,index) => <ErrorBoundary sendErrorData={getErrorDataFromBoundary} itemDetails={res} fallBack={null}> <label key={index} className='checkbox'>
                
                <input type='radio' value={res.name} onChange={e => handleChange(e.target.value, item)} hidden name='taking' />
                <span className='icon' />
                {res.name}
              </label>
              </ErrorBoundary>
              )}
              
              <button type='button' className={styles.something} onClick={() => {setSelected(item); setShowFeedback(true)}}>
                Something else?
              </button>
            </div>
          </div>
        )) : 'No items found'}
        </div>
      </div>
    </WrapperModal>
    </React.Fragment>
  )
}