// core
import React from 'react'

// library
import { useSelector } from "react-redux";

// components
// import { BookEventModal } from "../BookEventModal/BookEventModal";
import { Footer } from "../Footer/Footer";
import { getShowModal } from "../../../redux/modals/selectors";
import { BookEventModal } from '../modals/BookEventModal/BookEventModal';

export const EventLayout = ({children}) => {
    const modal = useSelector(getShowModal);

  return (
    <>
      {modal === 'Register Event' && <BookEventModal />}
      {children}
      <Footer noMenu />
    </>
  )
};
