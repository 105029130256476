export const currentMenuTab = (state) => state.mainReducer.currentMenuTab;
export const getPlaceOrderSelector = (state) => state.mainReducer.placeOrder;
export const getPlaceOrder2Selector = (state) => state.mainReducer.placeOrder2;
export const getOrderDetailsData = (state) => state.mainReducer.orderDetails;
export const getSelectCoupons = (state) => state.mainReducer.coupons;
export const getSelectRatingData = (state) => state.mainReducer.ratingData;
export const getRestrauntDetails = (state) =>
	state.mainReducer.restrauntDetails;

export const getSelectVegOnly = (state) => state.mainReducer.vegOnly;
export const getContainsEgg = (state) => state.mainReducer.containsEgg;
export const getShowFeedBack = (state) => state.mainReducer.addAddressModal;
export const getAllergyDetails = (state) => state.mainReducer.allergyDetails;
export const getBackButton = (state) => state.mainReducer.backButton;
export const getOrderDetailsPrepaidSelector = (state) =>
	state.mainReducer.orderDetails2;
