/**
 *
 * Asynchronously loads the component for Orders
 *
 */

import React from "react";
import LazyPlaceholder from "../../../common/LazyPlaceholder";
import loadable from "../../../Loadable";
// import loadable from '../../../common/ListItems/loadable';
//  import loadable from 'utils/loadable';
//  import LoadingIndicator from 'components/LoadingIndicator';

export default loadable(() => import("./Join"), {
	fallback: <LazyPlaceholder />,
});
