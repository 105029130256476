import React, { useState } from "react";

// library
import { useDispatch } from "react-redux";

// components
import { Feedback } from "../../Feedback/Feedback";

// assets
import styles from "./ShareModal.module.scss";
import { ReactComponent as CloseSvg } from "../../../../assets/images/icons/close.svg";
import { modalsActions } from "../../../../redux/modals/actions";
import { requestService } from "../../../../redux/reducer/main/action";

export const ShareModal = () => {
  const dispatch = useDispatch();
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [service, setService] = useState(['']);
  const joinCode = localStorage.getItem('joinCode');

  const handleCheckbox = (e, data) => {
    if (e.target.checked === true) {
      setService([...service, data])
    } else {
      const filter = service.filter(el => el !== data);
      setService(filter)
    }
  }
  const qrCode = localStorage.getItem('qrCode');
  const dataToSent = {item_customize: feedback, essentials_request: service, qr_code: qrCode}
  const handleSubmit = () => {
    dispatch(requestService(dataToSent))
    dispatch(modalsActions.setShowModal(''));
  }
  const type = JSON.parse(localStorage.getItem("type"));
  return (
    <div className={styles.modal}>
      <div className={styles.inner}>
        <Feedback showFeedback={showFeedback} setShowFeedback={setShowFeedback} requestModal handleSubmit={handleSubmit} feedback={feedback} setFeedback={setFeedback} />
       {type === 'dine_in' ? <h3 className={styles.header}>
          Share code with friends
          <span className={styles.close} onClick={() => dispatch(modalsActions.setShowModal(''))}>
            <CloseSvg />
          </span>
        </h3> : ''}
        <div className={styles.bottom}>
          {type === 'dine_in' ?<React.Fragment>
          <p className={styles.description}>
            This code will be required by others to join your table.
          </p>
          <span className={styles.digit}>{joinCode[0]}</span>
          <span className={styles.digit}>{joinCode[1]}</span>
          <span className={styles.digit}>{joinCode[2]}</span>
          <span className={styles.digit}>{joinCode[3]}</span>
          <hr />
          <h3>Request Service</h3>
          </React.Fragment> : <React.Fragment>  <h3 className={styles.header}>
          Request Service
          <span className={styles.close} onClick={() => dispatch(modalsActions.setShowModal(''))}>
            <CloseSvg />
          </span></h3><hr /></React.Fragment> }
          <div className={styles.panel}>
            <label className='checkbox'>
              <input type='checkbox' onChange={(e)=> handleCheckbox(e, 'Water')} hidden name='water' />
              <span className='icon' />
              Water
            </label>
            <label className='checkbox'>
              <input type='checkbox' onChange={(e)=> handleCheckbox(e, 'Salt & Pepper')} hidden name='salt' />
              <span className='icon' />
              Salt & Pepper
            </label>
            <label className='checkbox'>
              <input type='checkbox' onChange={(e)=> handleCheckbox(e, 'Tissue Paper')} hidden name='tissue' />
              <span className='icon' />
              Tissue Paper
            </label>
            <label className='checkbox'>
              <input type='checkbox' onChange={(e)=> handleCheckbox(e, 'Sauces')} hidden name='sauces' />
              <span className='icon' />
              Sauces
            </label>
            <label className='checkbox'>
              <input type='checkbox' onChange={(e)=> handleCheckbox(e, 'Cleaning')} hidden name='cleaning' />
              <span className='icon' />
              Cleaning
            </label>
            <button type='button' className={styles.something} onClick={() => setShowFeedback(true)}>
              Something else?
            </button>
            <button type='button' className='btn btn-second'
                    onClick={() => handleSubmit()}>REQUEST
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}