import React from "react";
import { useDispatch } from "react-redux";
import { changeQty } from "../../../redux/reducer/main/action";

// styles
import { modalsActions } from "../../../redux/modals/actions";
import styles from "./AddButton.module.scss";
import { getAddModalData } from "../../../redux/reducer/onboarding/actions";

export const AddButton = ({count, onClick, id, register, lastItem, index, itemData}) => {
  // const addButton = () => {
  //   console.log('add');
  // }
  const dispatch = useDispatch()
  const changeCount = (symbol) => {
    if((itemData && symbol === '+') && (itemData.addon_details && itemData.addon_details.length || itemData.item_price_details && itemData.item_price_details.length > 1)) {
      dispatch(getAddModalData(itemData));
        dispatch(modalsActions.setShowModal("Repeat Item"));
      }
    else {
      
      if (lastItem) {
        dispatch(changeQty({id, symbol, changeLastItem: 'changeLastItem'}));
      } else if(index) {
        dispatch(changeQty({id, symbol, index}));
      } else {
        dispatch(changeQty({id, symbol}));
      }
    }
  }

  const customerPlaceOrder = localStorage.getItem('customerPlaceOrder');
  if(customerPlaceOrder === false || customerPlaceOrder === 'false') {
    return null;
  }
  else {
  return (
    register
      ? <button className={`${styles.add} ${styles.register}`} type='button'
                onClick={() => dispatch(modalsActions.setShowModal('Register Event'))}>
        <span className={styles.plus} />
        <span>REGISTER</span>
      </button>
      : !count ?
      <button className={styles.add} type='button' onClick={() => onClick()}>
        <span className={styles.plus} />
        ADD
      </button>
      : <button className={`${styles.add} ${styles.mod}`} type='button'>
        <span className={styles.minus} onClick={() => changeCount('-')} />
        {count}
        <span className={styles.plus} onClick={() => changeCount('+')} />
      </button>
  )
}
}