import React, { useState } from "react";

// library
// import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import { WrapperModal, Card, Wrong } from "../../";
import { modalsActions } from "../../../../redux/modals/actions";
// import { routes } from "../../../App/routes";

// assets
// import styles from "./RatingUpdateModal.module.scss";
import styles from "../OrderModal/OrderModal.module.scss";
// import saly8 from "../../../../assets/images/saly-8.png";
import success from "../../../../assets/images/success.png";
import {
	getOrderDetailsData,
	getSelectRatingData,
} from "../../../../redux/reducer/main/selectors";
import { useSelector } from "react-redux";
import { getRatingData } from "../../../../redux/reducer/main/action";

export const RatingUpdateModal = () => {
	const dispatch = useDispatch();
	// useEffect(() => {
	//   dispatch(loadFeedbackData())
	//   console.log('first')
	// }, [])

	const ordersData = useSelector(getOrderDetailsData);
	const select = useSelector(getSelectRatingData);
	const [ratingData, setRatingData] = useState({
		restraunt_id: ordersData.restraunt_id,
		overall_review: select.overall_review || "",
		overall_ratings: select.overall_ratings || 4,
	});
	// const [feedback, setFeedback] = useState('')
	const handleData = () => {
		dispatch(
			getRatingData({
				rating_data: { ...ratingData, restraunt_id: ordersData.restraunt_id },
				status: "editOverall",
			})
		);
		dispatch(modalsActions.setShowModal(""));
	};
	console.log("select :>> ", select);
	const overallReview =
		select && select.overall_review
			? select.overall_review.split(".").splice(1).join(" ")
			: "";
	const label =
		select && select.overall_review
			? select.overall_review.split(".").shift()
			: "";
	// console.log(' :>> ', label.split(','));
	const handleSubmit = (e) => {
		e.preventDefault();
		handleData();
	};
	return (
		// <WrapperModal blackIcon title='Rate your experience' noFullScreen rating>
		//   <div className={styles.ratingUpdate}>
		//     <Card image={saly8} ratingData={ratingData} setRatingData={setRatingData} />
		//     {ratingData.overall_ratings <= 3 ? <Wrong ratingData={ratingData} setRatingData={setRatingData} /> : ''}
		//   </div>
		//   <div className={styles.continue}>
		//     <Link to={'#'} type='button' className='btn btn-black'
		//           onClick={() => handleData()}>
		//       Update
		//     </Link>
		//   </div>
		// </WrapperModal>
		<WrapperModal blackIcon>
			<div
				className={styles.order}
				style={{ height: "70vh", overflow: "auto" }}
			>
				<form onSubmit={handleSubmit}>
					<div className={styles.card}>
						<Card
							image={success}
							ratingData={ratingData}
							setRatingData={setRatingData}
						/>
						{ratingData.overall_ratings ? (
							<Wrong
								star={ratingData && ratingData.overall_ratings}
								update
								overallReview={overallReview}
								defaultLabel={label}
								ratingData={ratingData}
								setRatingData={setRatingData}
							/>
						) : (
							""
						)}

						<button
							type="submit"
							className="btn btn-black button-bottom"
							// onClick={() => }
						>
							SUBMIT
						</button>
					</div>
				</form>
				{/* <EventSection items={items} title='Explore Events ' /> */}
			</div>
		</WrapperModal>
	);
};
