import React from "react";

// library
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import { routes } from "../../App/routes";

// assets
import styles from "./TopPanel.module.scss";
import { ReactComponent as BackSvg } from "../../../assets/images/icons/arrow-back.svg";
import { ReactComponent as WarningSvg } from "../../../assets/images/icons/warning.svg";
import { modalsActions } from "../../../redux/modals/actions";
// import { orderType } from "../../../config/config";

export const TopPanel = ({ title, noBorder, noWarning, route, verified }) => {
	const dispatch = useDispatch();
	const orderType =
		localStorage.getItem("type") !== "undefined"
			? JSON.parse(localStorage.getItem("type"))
			: "";
	return (
		<div className={`${styles.panel} ${noBorder ? styles.noBorder : ""}`}>
			{title !== "Order Placed!" && title !== "Order details" ? (
				<Link to={route ? route : routes.special} className={styles.icon}>
					<BackSvg />
				</Link>
			) : (
				""
			)}
			<h2>
				{title}
				{verified && (
					<div className={styles.verified}>
						<img src={verified} alt="verified" />
					</div>
				)}
				{!noWarning && orderType === "dine_in" && title !== "Order details" ? (
					<div
						className={styles.icon}
						onClick={() => dispatch(modalsActions.setShowModal("Complaints"))}
					>
						<WarningSvg />
					</div>
				) : (
					""
				)}
			</h2>
		</div>
	);
};
