import React from "react";

// assets
import styles from "./DeliveryAddress.module.scss";
import { ReactComponent as CloseSvg } from "../../../../assets/images/icons/close.svg";
import { getRatingData, submitAddress, submitComplaint, toggleAddAddress } from "../../../../redux/reducer/main/action";
import { useDispatch } from "react-redux";
import { getShowFeedBack } from "../../../../redux/reducer/main/selectors";
import { useSelector } from "react-redux";
import { useState } from "react";

export const DeliveryAddModal = ({showFeedback, setShowFeedback,}) => {
const dispatch = useDispatch();
const [address, setAddress] = useState()
  const onSubmit = (e)=> {
    e.preventDefault();
    dispatch(submitAddress(address))    
  }
  const state = useSelector(getShowFeedBack)

    return (
    <div className={`${styles.feedback} ${state ? styles.open : ''}`}>
      <div className={styles.inner}>
        <h3>
          Add delivery address
          <span className={styles.close} onClick={() => dispatch(toggleAddAddress(false))}>
            <CloseSvg />
          </span>
        </h3>
        <hr />
        <form onSubmit={(e)=> onSubmit(e)}>
          {/* <label>
            Name
            <input type="text" name="name" placeholder="Name" />
            </label> */}
          <label>
            Address
            <textarea placeholder='Address' onChange={(e)=> setAddress(e.target.value)} />
          </label>
          <button type='submit' className='btn btn-primary'>Save</button>
        </form>
      </div>
    </div>
  )
}