import React, { useState } from "react";

// library
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import {
	CookingInstruction,
	NoNavigationLayout,
	PrimaryButton,
} from "../../../common";
import ListItems from "../../../common/ListItems/loadable";
import { TopPanel } from "../../../common/TopPanel/TopPanel";
import { routes } from "../../../App/routes";

// assets
import styles from "./PlaceOrder.module.scss";
import saly6 from "../../../../assets/images/saly-6.png";
// import { ReactComponent as BackSvg } from "../../../../assets/images/icons/arrow-back.svg";
// import { ReactComponent as WarningSvg } from "../../../../assets/images/icons/warning.svg";
import {
	getOrderDetailsData,
	getOrderDetailsPrepaidSelector,
	getPlaceOrder2Selector,
} from "../../../../redux/reducer/main/selectors";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
	loadOrderDetails,
	placeOrder,
} from "../../../../redux/reducer/main/action";
import history from "../../../../config/history";
import { displayNotification } from "../../../../config/helper";
import { AllergyInstructions } from "../../../common/AllergyInstructions/AllergyInstructions";
const PlaceOrder = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(loadOrderDetails());
	}, [dispatch]);
	const qrFlow = JSON.parse(localStorage.getItem("qrFlow"));
	const selector = useSelector(getPlaceOrder2Selector);
	const [show, setShow] = useState(false);
	const [showAllergyInstructions, setShowAllergyInstructions] = useState(false);
	const [instructionAdded, setInstructionAdded] = useState(false);
	const runningOrderData = useSelector(getOrderDetailsData);
	const runningPrepaidOrderData = useSelector(getOrderDetailsPrepaidSelector);
	// const orderDetails = runningOrderData && runningOrderData.order_details;
	const prepaidOrderDetails =
		runningPrepaidOrderData && runningPrepaidOrderData.order_details;
	const orderDetails = runningOrderData && runningOrderData.order_details;
	if (orderDetails) {
		var { order_amount, ...restData } = orderDetails;
	}
	if (prepaidOrderDetails) {
		// eslint-disable-next-line no-redeclare
		var { order_amount, ...restPrepaidData } = prepaidOrderDetails;
	}

	const runningData =
		restData && Object.values(restData).flatMap((data) => data);
	const runningPrepaidData =
		restPrepaidData && Object.values(restPrepaidData).flatMap((data) => data);

	if (
		runningData &&
		runningData.length > 1 &&
		selector &&
		selector.length === 0
	) {
		history.push(routes.checkout);
	}

	const startOrdering = (e) => {
		e.preventDefault();
		// history.push(routes.special);
		history.goBack();
	};

	// console.log("runningPrepaidData :>> ", runningPrepaidData);
	const type = JSON.parse(localStorage.getItem("type"));
	const onSubmit = (e) => {
		e.preventDefault();

		const customerPlaceOrder = localStorage.getItem("customerPlaceOrder");
		if (customerPlaceOrder === false || customerPlaceOrder === "false") {
			displayNotification("Placing orders not allowed");
		} else {
			dispatch(placeOrder());
		}
	};

	// accept reject dine in orders
	// console.log("runningOrderData :>> ", runningOrderData);
	const approvalOrders =
		runningOrderData &&
		runningOrderData.approval_orders &&
		runningOrderData.approval_orders.map((e) => e.order_details);
	const pendingOrders = approvalOrders && approvalOrders.flat();
	const rejectedOrders =
		runningOrderData &&
		runningOrderData.rejected_orders &&
		runningOrderData.rejected_orders.map((e) => e.order_details);
	const rejectedItems = rejectedOrders && rejectedOrders.flat();

	const dineInApproval = localStorage.getItem("dineInApproval")
		? JSON.parse(localStorage.getItem("dineInApproval"))
		: "";
	const driveIn = localStorage.getItem("driveIn")
		? JSON.parse(localStorage.getItem("driveIn"))
		: false;
	return (
		<NoNavigationLayout>
			<CookingInstruction
				show={show}
				setShow={setShow}
				setInstructionAdded={setInstructionAdded}
			/>
			<AllergyInstructions
				show={show}
				setShow={setShow}
				setShowAllergyInstructions={setShowAllergyInstructions}
				showAllergyInstructions={showAllergyInstructions}
				setInstructionAdded={setInstructionAdded}
			/>
			<main className={`${styles.placeOrder} make-scroll`}>
				<TopPanel
					title="Place Order"
					route={driveIn ? routes.driveIn : routes.special}
				/>
				{(selector && selector.length) ||
				(runningPrepaidData && runningPrepaidData.length) ? (
					<section onSubmit={(e) => onSubmit(e)} className={styles.products}>
						<form action="">
							{selector && selector.length ? (
								<ListItems
									items={selector}
									count={selector && selector.length}
									setInstructionAdded={setInstructionAdded}
									title="Current Order"
									subTitle="Items getting placed"
									toggle
									list
									instruction
									instructionAdded={instructionAdded}
									setShow={setShow}
									allergyShow
									setShowAllergyInstructions={setShowAllergyInstructions}
								/>
							) : (
								""
							)}
							{type === "dine_in" && runningData && runningData.length ? (
								<ListItems
									setInstructionAdded={setInstructionAdded}
									items={runningData}
									count={runningData && runningData.length}
									title="Previous Order"
									subTitle="Items already placed"
									hideAddButton
									closeByDefault
									toggle
									list
									setShow={setShow}
									setShowAllergyInstructions={setShowAllergyInstructions}
									instructionAdded={instructionAdded}
								/>
							) : (
								""
							)}
							{/* {qrFlow &&
							qrFlow === "prepaid" &&
							type === "dine_in" &&
							runningPrepaidData &&
							runningPrepaidData.length ? (
								<ListItems
									items={runningPrepaidData}
									count={runningPrepaidData && runningPrepaidData.length}
									title="Previous Order"
									subTitle="Items already placed"
									toggle
									hideAddButton
									list
									setShow={setShow}
								/>
							) : (
							
								""
							)} */}

							{dineInApproval ? (
								<React.Fragment>
									{pendingOrders && pendingOrders.length ? (
										<ListItems
											// setInstructionAdded={setInstructionAdded}
											count={pendingOrders && pendingOrders.length}
											items={pendingOrders}
											title="Pending Approval"
											subTitle="Items waiting to be approved from Restaurant"
											toggle
											list
											hideAddButton
											closeByDefault
											setShow={setShow}
											setShowAllergyInstructions={setShowAllergyInstructions}
										/>
									) : (
										""
									)}
									{rejectedItems && rejectedItems.length ? (
										<ListItems
											setInstructionAdded={setInstructionAdded}
											items={rejectedItems}
											count={rejectedItems && rejectedItems.length}
											title="Rejected Order"
											subTitle="Items rejected due to non-availability"
											toggle
											hideAddButton
											closeByDefault
											list
											setShow={setShow}
											setShowAllergyInstructions={setShowAllergyInstructions}
										/>
									) : (
										""
									)}
								</React.Fragment>
							) : (
								""
							)}
							<PrimaryButton
								title="PLACE ORDER"
								info={`${selector && selector.length} Items`}
								white
							/>
						</form>
					</section>
				) : (
					<section className={styles.content}>
						<h3>No orders yet!</h3>
						<p>Add something from the menu.</p>
						<Link
							to={routes.special}
							type="button"
							className={`btn btn-primary ${styles.btn}`}
							onClick={(e) => startOrdering(e)}
						>
							START ORDERING
						</Link>
						<img className={styles.banner} src={saly6} alt="start-ordering" />
					</section>
				)}
			</main>
		</NoNavigationLayout>
	);
};
export default React.memo(PlaceOrder);
