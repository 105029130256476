import React from "react";
import styles from "../GridItems/GridItems.module.scss";
import { ReactComponent as ArrowSvg } from "../../../assets/images/icons/arrow.svg";
import GridBox from "./GridBox";
import { getBrandDetailsSelector } from "../../../redux/reducer/onboarding/selectors";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrandMenu } from "../../../redux/reducer/main/action";
const GridRestraunt = ({ toggle, headerLine }) => {
	const brandData = useSelector(getBrandDetailsSelector);
	const dispatch = useDispatch();
	const handleOnClick = (id) => {
		dispatch(fetchBrandMenu(id));
		localStorage.setItem("brandId", JSON.stringify(id));
	};
	// const demoArr = [
	// 	{
	// 		brand_name: "Play1",
	// 		brand_id: "playPLAYpl",
	// 	},
	// 	{
	// 		brand_name: "Play",
	// 		brand_id: "playPLAYpl",
	// 	},
	// 	{
	// 		brand_name: "Play",
	// 		brand_id: "playPLAYpl",
	// 	},
	// 	{
	// 		brand_name: "Play",
	// 		brand_id: "playPLAYpl",
	// 	},
	// 	{
	// 		brand_name: "Play",
	// 		brand_id: "playPLAYpl",
	// 	},
	// 	{
	// 		brand_name: "Play",
	// 		brand_id: "playPLAYpl",
	// 	},
	// ];
	return (
		<div className={`${styles.items}`}>
			{toggle ? (
				<h4
					className={`${styles.title} ${styles.toggle}`}
					// onClick={(e) => handleOnClick(e)}
				>
					{headerLine || "Today’s Special"}
					<hr />
					<ArrowSvg />
				</h4>
			) : (
				<h4 className={styles.title}>{headerLine || "Today’s Special"}</h4>
			)}
			<div className={styles.panel}>
				{(brandData || []).map((e) => (
					<GridBox
						headerLine={`Drive in's`}
						toggle
						handleOnClick={handleOnClick}
						product={e}
						styles={styles}
					/>
				))}
			</div>
		</div>
	);
};

export default GridRestraunt;
